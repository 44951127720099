var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pw---right-bar"},[_c('SelectedElement'),(_vm.selected && _vm.selected.id !== 'pw---website')?_c('div',{staticClass:"pw---side-scroll"},[(_vm.isSelected)?_c('OnClick'):_vm._e(),(
        _vm.isSelected &&
        (_vm.selectedTagName === 'input' ||
          _vm.selectedTagName === 'select' ||
          _vm.selectedTagName === 'textarea')
      )?_c('OnChange'):_vm._e(),(_vm.isSelected)?_c('Visibility'):_vm._e(),(_vm.isSelected)?_c('Style'):_vm._e(),(_vm.isSelected)?_c('Class'):_vm._e(),(_vm.isSelected && _vm.selectedTagName === 'a')?_c('Parameters'):_vm._e(),(
        _vm.isSelected &&
        (_vm.selectedTagName === 'div' ||
          _vm.selectedTagName === 'label' ||
          _vm.selectedTagName === 'option' ||
          _vm.selectedTagName === 'span' ||
          _vm.selectedTagName === 'strong' ||
          _vm.selectedTagName === 'sub' ||
          _vm.selectedTagName === 'sup' ||
          _vm.selectedTagName === 'u' ||
          _vm.selectedTagName === 'ins' ||
          _vm.selectedTagName === 'b' ||
          _vm.selectedTagName === 'i' ||
          _vm.selectedTagName === 'del' ||
          _vm.selectedTagName === 'rp' ||
          _vm.selectedTagName === 'rt' ||
          _vm.selectedTagName === 'ruby' ||
          _vm.selectedTagName === 'small' ||
          _vm.selectedTagName === 'samp' ||
          _vm.selectedTagName === 'time' ||
          _vm.selectedTagName === 'code' ||
          _vm.selectedTagName === 'li' ||
          _vm.selectedTagName === 'ul' ||
          _vm.selectedTagName === 'a' ||
          _vm.selectedTagName === 'p' ||
          _vm.selectedTagName === 'h1' ||
          _vm.selectedTagName === 'h2' ||
          _vm.selectedTagName === 'h3' ||
          _vm.selectedTagName === 'h4' ||
          _vm.selectedTagName === 'h5' ||
          _vm.selectedTagName === 'h6')
      )?_c('TextBlock'):_vm._e(),(_vm.isSelected)?_c('List'):_vm._e(),(
        _vm.isSelected &&
        (_vm.selectedTagName === 'input' ||
          _vm.selectedTagName === 'select' ||
          _vm.selectedTagName === 'textarea')
      )?_c('Value'):_vm._e(),(_vm.isSelected)?_c('HTMLAttribute'):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }