




































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import InputAll from "@/components/ui/InputAll.vue";
import InputText from "@/components/ui/InputText.vue";
import InputSelect from "@/components/ui/InputSelect.vue";
import InputMultiSelect from "@/components/ui/InputMultiSelect.vue";
import Toggle from "@/components/ui/Toggle.vue";
import Button from "@/components/ui/Button.vue";
import OptionTitle from "@/components/ui/OptionTitle.vue";
import BlockHeading from "@/components/ui/BlockHeading.vue";
import RequestModel from "@/models/Request";
import Request from "@/helpers/request";
import ResourceModel from "@/models/Resource";
import LoadingSpinner from "@/components/misc/LoadingSpinner.vue";
import countries from "@/components/misc/countryList.ts";

@Component({
  components: {
    InputAll,
    InputText,
    InputSelect,
    InputMultiSelect,
    Toggle,
    OptionTitle,
    BlockHeading,
    Button,
    LoadingSpinner,
  },
})
export default class Checkout extends Vue {
  purchaseTypeId = "one-time";
  resourceProducts: Array<{
    id: string;
    name: string;
    prices: Array<{
      id: string;
      price: number;
      type: "recurring" | "one_time";
      currency: string;
    }>;
  }> = [];

  mounted() {
    this.loadAllResourceProducts();
  }

  set(
    value: any,
    attributeName:
      | "name"
      | "resourceId"
      | "type"
      | "executeOnCondition"
      | "executeOnPageLoad"
      | "purchaseType"
  ): void {
    const request = this.request;
    (request[attributeName] as any) = value;
    this.$store.commit("SET_POPUP_REQUESTSETTINGS", {
      request: request,
      isOpen: true,
    });
  }

  setArray(
    value: any,
    attributeName: "priceId" | "quantity" | "productId" | "key" | "value",
    index: number,
    arrayName: "items" | "metadata"
  ): void {
    const request = this.request;
    let array = request[arrayName];
    if (!array) {
      request[arrayName] = [];
      array = [];
    }
    const item = array[index];
    (item as any)[attributeName] = value;
    this.$store.commit("SET_POPUP_REQUESTSETTINGS", {
      request: request,
      isOpen: true,
    });
  }

  addArrayItem(arrayName: "items" | "metadata"): void {
    const request = this.request;
    let array = request[arrayName];
    if (!array) array = [];
    const item =
      arrayName === "items"
        ? { priceId: "", quantity: "1", productId: "", dynamicTaxRates: [] }
        : { key: "", value: "" };
    array.push(item as any);
    (request as any)[arrayName] = array;
    this.$store.commit("SET_POPUP_REQUESTSETTINGS", {
      request: request,
      isOpen: true,
    });
  }

  deleteArrayItem(index: number, arrayName: "items"): void {
    const request = this.request;
    let array = request[arrayName];
    if (!array) return;
    array.splice(index, 1);
    request[arrayName] = array;
    this.$store.commit("SET_POPUP_REQUESTSETTINGS", {
      request: request,
      isOpen: true,
    });
  }

  loadAllResourceProducts(): void {
    Request.loadResourceProducts(
      this.selectedResource.privateKey ? this.selectedResource.privateKey : ""
    );
  }

  prices(productId: string) {
    const product = this.$store.state.popup.requestSettings.resourceProducts.find(
      (product: any) => product.id === productId
    );
    const formattedPrices: Array<{ id: string; name: string }> = [];
    if (product)
      product.prices.forEach((price: any) =>
        formattedPrices.push({
          id: price.id,
          name:
            price.price / 100 +
            " " +
            price.currency +
            " | " +
            (price.type === "recurring" ? "Recurring" : "One time"),
        })
      );
    return [
      {
        name: "Products",
        values: formattedPrices,
      },
    ];
  }

  get products() {
    return [
      {
        name: "Products",
        values: this.$store.state.popup.requestSettings.resourceProducts,
      },
    ];
  }

  get taxes() {
    return [
      {
        name: "Taxes",
        values: this.$store.state.popup.requestSettings.resourceTaxes,
      },
    ];
  }

  get request(): RequestModel {
    return JSON.parse(JSON.stringify(this.$store.state.popup.requestSettings.request));
  }

  get countries() {
    const countriesFormatted: Array<{ id: string; name: string }> = [];
    countries.forEach((country: any) =>
      countriesFormatted.push({ id: country.code, name: country.name })
    );
    return [{ name: "Countries", values: countriesFormatted }];
  }

  get resources(): any {
    const resources: any = [];
    this.$store.state.configuration.resources.forEach((resource: any) => {
      resources.push({ id: resource.id, name: resource.name });
    });
    return [
      {
        name: "Resources",
        values: resources,
      },
    ];
  }

  get selectedResource(): ResourceModel {
    const selectedResourceId = this.request.resourceId;
    const resource = this.$store.state.configuration.resources.find(
      (resource: ResourceModel) => resource.id === this.request.resourceId
    );
    return resource;
  }
}
