import ElementModel from "@/models/Element";
import store from "@/store";
import uniqid from "uniqid";
import element from "./element";

const Id = {
  create: (elements: Array<{ id: string }>): string => {
    const id = uniqid();
    let idExisting = false;
    if (!elements || elements.length !== 0) return id;
    elements.forEach((element: { id: string }) => {
      if (id == element.id) idExisting = true;
    });
    if (idExisting) return Id.create(elements);
    else return id;
  },
};

export default Id;
