
























import { Component, Vue, Watch } from "vue-property-decorator";
import PreviewSettings from "@/components/nav/top/preview/PreviewSettings.vue";
import Save from "@/components/ui/Save.vue";
import Button from "@/components/ui/Button.vue";

@Component({
  components: { PreviewSettings, Save, Button },
})
export default class PreviewBar extends Vue {
  closeFullScreenPreview(): void {
    this.$store.commit("SET_PREVIEW", { isFullScreen: false });
  }
  openPublish(): void {
    this.$store.commit("SET_PREVIEW", { isFullScreen: false });
    this.$store.commit("SET_POPUP_PUBLISH", { isOpen: true });
  }
  get hostname(): string {
    return window.location.hostname;
  }
  get path(): string {
    return (location.pathname + location.search).substr(1);
  }
}
