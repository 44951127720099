








































import { Component, Vue, Watch } from "vue-property-decorator";
import { PageModel } from "@/models/Configuration";
import Button from "@/components/ui/Button.vue";
import InputText from "@/components/ui/InputText.vue";
import OptionTitle from "@/components/ui/OptionTitle.vue";
import PopupHeading from "@/components/ui/PopupHeading.vue";
import Id from "@/helpers/id";
import Page from "@/helpers/page";

@Component({
  components: {
    Button,
    InputText,
    OptionTitle,
    PopupHeading,
  },
})
export default class EditVariable extends Vue {
  createNewVariable = false;

  set(value: string, attributeName: "name" | "id") {
    const settings = this.variableSettings;
    if (attributeName === "name") {
      value = value.replace(/\s+/g, "-").toLowerCase();
    }
    settings[attributeName] = value;
    this.$store.commit("SET_POPUP_VARIABLES", settings);
  }

  saveVariable(): void {
    this.$store.commit("SET_VARIABLE", this.variableSettings);
    this.close();
  }

  deleteVariable(): void {
    if (confirm("Are you sure you want to delete this variable?"))
      this.$store.commit("DELETE_VARIABLE", this.variableSettings.id);
    else return;
    this.close();
  }

  close(): void {
    this.$store.commit("SET_POPUP_VARIABLES", {
      name: "",
      id: "",
      isOpen: false,
    });
  }

  mounted(): void {
    if (!this.variableSettings.name || !this.variableSettings.id) {
      this.createNewVariable = true;
      const id = Id.create(this.variables);
      this.set(id, "id");
    }
  }

  get variables() {
    const page = Page.get();
    if (!page || !page.variables) return [];
    else return page.variables;
  }

  get variableSettings(): { id: string; name: string; isOpen: boolean } {
    return this.$store.state.popup.variableSettings;
  }

  get isValidVariable(): boolean {
    if (this.variableSettings.id && this.variableSettings.name) return true;
    else return false;
  }

  get hasDeleteOption(): boolean {
    if (this.createNewVariable) return false;
    else return true;
  }
}
