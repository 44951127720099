






















import { Component, Vue, Watch } from "vue-property-decorator";
import { PageModel } from "@/models/Configuration";
import Button from "@/components/ui/Button.vue";

const Props = Vue.extend({
  props: {
    resource: {
      type: Object,
      required: true,
      validator: (value) => {
        if (value.id && value.type && value.name) return true;
        return false;
      },
    },
    request: {
      type: Object,
      required: true,
      validator: (value) => {
        if (value.id && value.name) return true;
        return false;
      },
    },
  },
});

@Component({})
export default class Resource extends Props {
  get type(): { name: string; icon: string } {
    if (this.resource.type === "airtable") {
      return { name: "Airtable", icon: "Airtable" };
    } else if (this.resource.type === "stripe") {
      return { name: "Stripe", icon: "Stripe" };
    } else if (this.resource.type === "rest") {
      return { name: "REST API", icon: "API" };
    } else {
      return { name: "File Upload (Beta)", icon: "upload-white" };
    }
  }

  open(): void {
    this.$store.commit("SET_POPUP_REQUESTSETTINGS", {
      request: this.request,
      isOpen: true,
    });
  }
}
