var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    'padding-left': _vm.$store.state.tab.tabName ? '255px' : '35px',
    'padding-top': '35px',
    'z-index': 2147483647,
    height: '100%',
    display: 'flex',
    'flex-direction': 'row',
  })},[(_vm.$store.state.popup.publish.isOpen)?_c('Publish'):_vm._e(),(_vm.$store.state.errors.length > 0)?_c('Errors'):_vm._e(),(_vm.$store.state.popup.dataSelector.isOpen)?_c('DataSelector'):_vm._e(),(_vm.$store.state.popup.pageSettings.isOpen)?_c('EditPage'):_vm._e(),(_vm.$store.state.popup.parameterSettings.isOpen)?_c('EditParameter'):_vm._e(),(_vm.$store.state.popup.variableSettings.isOpen)?_c('EditVariable'):_vm._e(),(_vm.$store.state.popup.cookieSettings.isOpen)?_c('EditCookie'):_vm._e(),(_vm.$store.state.popup.previewSettings.isOpen)?_c('EditPreview'):_vm._e(),(_vm.$store.state.popup.resourceSettings.isOpen)?_c('EditResource'):_vm._e(),(_vm.$store.state.popup.requestSettings.isOpen)?_c('EditRequest'):_vm._e(),(_vm.$store.state.popup.filterSettings.isOpen)?_c('EditFilter'):_vm._e(),(_vm.$store.state.popup.sortSettings.isOpen)?_c('EditSorting'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }