








































import { Component, Vue, Watch } from "vue-property-decorator";
import InputSelect from "@/components/ui/InputSelect.vue";
import OptionTitle from "@/components/ui/OptionTitle.vue";
import BlockHeading from "@/components/ui/BlockHeading.vue";
import Button from "@/components/ui/Button.vue";
import RequestModel from "@/models/Request";

@Component({
  components: {
    InputSelect,
    OptionTitle,
    BlockHeading,
    Button,
  },
})
export default class Sort extends Vue {
  setArray(
    value: string,
    attributeName: "fieldId" | "direction",
    index: number,
    arrayName: "sort"
  ): void {
    const request = this.request;
    let array = request[arrayName];
    if (!array) return;
    const item = array[index];
    item[attributeName] = value;
    this.$store.commit("SET_POPUP_REQUESTSETTINGS", {
      request: request,
      isOpen: true,
    });
  }

  addArrayItem(arrayName: "sort"): void {
    const request = this.request;
    let array = request[arrayName];
    if (!array) array = [];
    const item = { fieldId: "", direction: "" };
    array.push(item);
    request[arrayName] = array;
    this.$store.commit("SET_POPUP_REQUESTSETTINGS", {
      request: request,
      isOpen: true,
    });
  }

  deleteArrayItem(index: number, arrayName: "sort"): void {
    const request = this.request;
    let array = request[arrayName];
    if (!array) return;
    array.splice(index, 1);
    request[arrayName] = array;
    this.$store.commit("SET_POPUP_REQUESTSETTINGS", {
      request: request,
      isOpen: true,
    });
  }

  get directions(): Array<any> {
    return [
      {
        name: "Directions",
        values: [
          { id: "descending", name: "Descending" },
          { id: "ascending", name: "Ascending" },
        ],
      },
    ];
  }

  get request(): RequestModel {
    return JSON.parse(JSON.stringify(this.$store.state.popup.requestSettings.request));
  }

  get fieldsOfSelectedTable(): Array<any> {
    const selectedTableId = this.request.tableId;
    const table = this.tablesOfSelectedBase[0]
      ? this.tablesOfSelectedBase[0].values.find((table: any) => table.id === selectedTableId)
      : {};
    return table && table.fields ? [{ name: "Fields", values: table ? table.fields : [] }] : [];
  }

  get tablesOfSelectedBase(): Array<any> {
    return this.$store.state.popup.requestSettings.airtableBaseStructure.length > 0
      ? [
          {
            name: "Tables",
            values: this.$store.state.popup.requestSettings.airtableBaseStructure,
          },
        ]
      : [];
  }
}
