











import { Component, Vue, Watch } from "vue-property-decorator";
import Button from "@/components/ui/Button.vue";
import Variable from "@/components/ui/Variable.vue";
import Element from "@/helpers/element";
import ElementModel from "@/models/Element";

const Props = Vue.extend({
  props: {
    initialValue: {
      type: String,
      default: "",
    },
  },
});

@Component({
  components: {
    Button,
    Variable,
  },
})
export default class InputElement extends Props {
  value = "";
  active = false;

  mounted() {
    if (this.$props.initialValue) this.value = this.$props.initialValue;
  }

  toggleActive() {
    this.active = !this.active;
  }

  @Watch("active")
  onActive(): void {
    if (this.active && this.$store.state.element.selected && this.$store.state.element.selected.queryString)
      this.change(this.$store.state.element.selected.queryString);
  }

  @Watch("initialValue")
  onPropertyChanged(value: string): void {
    this.value = value;
  }

  @Watch("$store.state.element.selected")
  onSelectedElementChange(value: ElementModel) {
    if (value.queryString && this.active) {
      this.change(value.queryString);
      this.active = false;
    }
  }

  change(value: string): void {
    this.value = value;
    this.$emit("change", this.value);
  }

  get element() {
    if (this.value && document.querySelector(this.value))
      return Element.format(document.querySelector(this.value) as HTMLElement);
    else return {};
  }
}
