import store from "@/store";
import env from "@/envConfig";
import Token from "@/helpers/token";
import Render from "./render";
import { ElementModel } from "@/models/Configuration";
import { reportError } from "@/helpers/error-handler";
import Cookie from "./cookie";

const Configuration = {
  load: async (): Promise<void> => {
    // Check if cookie for unique daily visit is present
    const lastVisit = Cookie.get("w---visit");
    const projectId = store.state.project.id;
    const response = await fetch(
      `${env.serverUrl}v1/project/configuration?projectId=${projectId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "W---Visit": lastVisit,
        },
      }
    ).catch((error) => {
      throw new Error(error.message);
    });
    if (response.status === 200) {
      const responseParsed = await response.json();
      Cookie.set("w---visit", responseParsed["w---visit"], 1);
      store.commit("SET_CONFIGURATION", responseParsed.configuration);
      store.commit("SET_PROJECT", { id: responseParsed.projectId, plan: responseParsed.plan });
    } else {
      throw new Error(
        `Server Error. Code: ${response.status}. Could not load project configuration.`
      );
    }
  },
  loadAdmin: async (): Promise<void> => {
    const projectId = store.state.project.id;
    const token = await Token.getAdminToken();
    const response = await fetch(
      `${env.serverUrl}v1/project/admin/configuration?projectId=${projectId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    ).catch((error) => {
      throw new Error(error.message);
    });
    if (response.status === 200) {
      const responseParsed = await response.json();
      store.commit("SET_CONFIGURATION", responseParsed.configuration);
    } else {
      throw new Error(
        `Server Error. Code: ${response.status}. Could not load project configuration.`
      );
    }
  },
  save: async (): Promise<void> => {
    const configuration = store.state.configuration;
    const projectId = store.state.project.id;
    const token = await Token.getAdminToken();
    const response = await fetch(
      `${env.serverUrl}v1/project/configuration?projectId=${projectId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(configuration),
      }
    ).catch((error) => {
      throw new Error(error.message);
    });
    if (response.status === 200) {
      return;
    } else if (response.status === 403) {
      // Log out user
      store.commit("SET_ADMIN_USER", {});
    } else {
      throw new Error(
        `Server Error. Code: ${response.status}. Could not save project configuration.`
      );
    }
  },
  publish: async (destination: { main: boolean; staging: boolean }): Promise<void> => {
    const projectId = store.state.project.id;
    const token = await Token.getAdminToken();
    const response = await fetch(`${env.serverUrl}v1/publish?projectId=${projectId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ destination }),
    }).catch((error) => {
      throw new Error(error.message);
    });
    if (response.status === 200) {
      return;
    } else if (response.status === 403) {
      // Log out user
      store.commit("SET_ADMIN_USER", {});
    } else {
      throw new Error(`Server Error. Code: ${response.status}. Could not publish project.`);
    }
  },
  loadPreviewUsers: async (): Promise<Array<{ id: string; name: string }>> => {
    const projectId = store.state.project.id;
    const token = await Token.getAdminToken();
    const response = await fetch(
      `${env.serverUrl}v1/project/preview/users?projectId=${projectId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    ).catch((error) => {
      throw new Error(error.message);
    });
    if (response.status === 200) {
      const responseParsed = await response.json();
      return responseParsed.users;
    } else {
      throw new Error(`Server Error. Code: ${response.status}. Could not load preview users.`);
    }
  },
  isElementEmpty: (element: ElementModel): boolean => {
    if (!element.elementQueryString) return true;
    if (
      element.connections.onClick.length === 0 &&
      element.connections.onInput.length === 0 &&
      (element.connections.visibility.condition === "" ||
        element.connections.visibility.condition === "[]") &&
      element.connections.style.length === 0 &&
      element.connections.class.length === 0 &&
      (element.connections.list.value === "" || element.connections.list.value === "[]") &&
      (element.connections.value.value === "" || element.connections.value.value === "[]") &&
      (element.connections.text.value === "" || element.connections.text.value === "[]") &&
      element.connections.text.type === "text" &&
      element.connections.attributes.length === 0 &&
      element.connections.parameters.length === 0
    )
      return true;
    else return false;
  },
};

export default Configuration;
