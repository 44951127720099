import store from "@/store";
import env from "@/envConfig";
import Render from "@/helpers/render";
import Page from "./page";
import emailValidator from "email-validator";
import passwordValidator from "password-validator";
import Cookie from "./cookie";
import Parameter from "./parameter";
import { PageModel } from "@/models/Configuration";
import History from "@/helpers/history";
import { reportError } from "./error-handler";
import API from "@/helpers/api";

const Authentication = {
  login: async (token: string) => {
    const projectId = store.state.project.id;
    const response = await fetch(`${env.serverUrl}v1/project/admin/auth?projectId=${projectId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }).catch((error) => {
      throw new Error(error.message);
    });
    if (response.status === 200) {
      const responseParsed = await response.json();
      store.commit("SET_ADMIN_USER", responseParsed.user);
      store.commit("SET_PROJECT", responseParsed.project);
      Render.init();
    } else {
      if (response.status === 403) {
        store.commit("SET_ADMIN_USER", {});
      }
      throw new Error(
        `Server Error. Code: ${response.status}. Could not load project configuration.`
      );
    }
  },
  loginUser: async (type: "emailPassword" | "magicLink" | "google") => {
    try {
      // Get value of connected login elements (email & password)
      let email = "";
      let password = "";
      store.commit("SET_DATA_TYPE_VALUE", {
        value: {
          isRequesting: true,
          hasRequested: false,
          statusCode: 0,
          errorMessage: "",
          data: undefined,
        },
        type: "login",
      });
      if (type === "emailPassword") {
        email = (document.querySelector(
          Page.get()?.settings.login.emailPasswordEmailQuery || ""
        ) as HTMLInputElement)?.value;
        password = (document.querySelector(
          Page.get()?.settings.login.emailPasswordPasswordQuery || ""
        ) as HTMLInputElement)?.value;
      } else if (type === "magicLink") {
        email = (document.querySelector(
          Page.get()?.settings.login.magicLinkEmailQuery || ""
        ) as HTMLInputElement)?.value;
      }
      if ((type === "emailPassword" || type === "magicLink") && !emailValidator.validate(email)) {
        alert(API.alertText.emailInvalid);
        throw new Error(`Email "${email}" is not valid. Please try again.`);
      }
      const projectId = store.state.project.id;
      History.requestStart("login-" + type);
      const response = await fetch(`${env.serverUrl}v1/project/user/login?projectId=${projectId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: type,
          email: email,
          password: password,
          pageId: Page.get()?.id,
          loginUrl: window.location.href,
        }),
      }).catch((error) => {
        throw new Error(error.message);
      });
      const responseParsed = await response.json();
      store.commit("SET_DATA_TYPE_VALUE", {
        value: {
          isRequesting: false,
          hasRequested: true,
          statusCode: response.status,
          errorMessage: responseParsed.error ? responseParsed.error : "",
          data: response.status === 200 ? responseParsed : undefined,
        },
        type: "login",
      });
      History.requestEnd(response, responseParsed, "login-" + type);
      if (response.status === 200) {
        if (type === "magicLink") {
          alert(API.alertText.loginEmailSent);
          const emailField = document.querySelector(
            Page.get()?.settings.login.magicLinkEmailQuery || ""
          );
          if (emailField) (emailField as HTMLInputElement).value = "";
        } else if (type === "emailPassword") {
          // Save auth token to cookies
          Cookie.set("w---at", responseParsed.accessToken, 30);
          // Redirect to dashboard page if configured. Else reload the page.
          if (
            Page.get()?.settings.login.redirectAfterLogin &&
            Page.get()?.settings.login.redirectPageId
          )
            location.href =
              store.state.configuration.pages.find(
                (page: PageModel) => page.id === Page.get()?.settings.login.redirectPageId
              ).path || "";
          else location.reload();
        }
      } else if (response.status === 403) {
        if (type === "magicLink") alert(API.alertText.userNotFound);
        if (type === "emailPassword") alert(API.alertText.credentialsInvalid);
      } else if (response.status === 400) {
        alert(API.alertText.serverError);
      } else {
        if (responseParsed.message)
          alert(API.alertText.loginError + " Error: " + responseParsed.message);
        reportError(
          "13001",
          `Server Error. Code: ${response.status}. Could not login user.`,
          responseParsed.message
        );
      }
    } catch (error) {
      store.commit("SET_DATA_TYPE_VALUE", {
        value: {
          isRequesting: false,
          hasRequested: false,
          statusCode: null,
          errorMessage: error,
          data: undefined,
        },
        type: "login",
      });
    }
  },
  verifyLoginToken: async (loginToken: string) => {
    // /project/user/verify/login-token
    const projectId = store.state.project.id;
    const response = await fetch(
      `${env.serverUrl}v1/project/user/verify/login-token?projectId=${projectId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          loginToken: loginToken,
        }),
      }
    ).catch((error) => {
      throw new Error(error.message);
    });
    const responseParsed = await response.json();
    if (response.status === 200) {
      // Save access token and user
      Cookie.set("w---at", responseParsed.accessToken, 30);
      Parameter.delete("login-token");
      // Redirect to dashboard page if configured. Else reload the page.
      if (
        Page.get()?.settings.login.redirectAfterLogin &&
        Page.get()?.settings.login.redirectPageId
      )
        location.href = Page.get(Page.get()?.settings.login.redirectPageId)?.path || "";
      else location.reload();
      //store.commit("SET_DATA_TYPE_VALUE", { type: "user", value: responseParsed.user });
    } else {
      responseParsed.message &&
        alert(API.alertText.loginError + " Error: " + responseParsed.message);
      reportError(
        "13003",
        `Server Error. Code: ${response.status}. Could not verify login token for user.`
      );
    }
  },
  loadUser: async (authToken: string) => {
    store.commit("SET_DATA_TYPE_VALUE", {
      value: {
        isRequesting: true,
        hasRequested: false,
        statusCode: 0,
        errorMessage: "",
        data: undefined,
      },
      type: "user",
    });
    History.requestStart("user");
    const response = await fetch(`${env.serverUrl}v1/user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      },
    }).catch((error) => {
      throw new Error(error.message);
    });
    const responseParsed = await response.json();
    store.commit("SET_DATA_TYPE_VALUE", {
      value: {
        isRequesting: false,
        hasRequested: true,
        statusCode: response.status,
        errorMessage: responseParsed.error ? responseParsed.error : "",
        data: response.status === 200 ? responseParsed : undefined,
      },
      type: "user",
    });
    History.requestEnd(response, responseParsed, "user");
    if (response.status === 200) {
      // Page access control
      if (
        Object.keys(responseParsed).length === 0 &&
        Page.get()?.settings.accessControl.accessToThisPageRestricted &&
        Page.get()?.settings.accessControl.authRequired
      ) {
        if (Page.get()?.settings.accessControl.fallbackPageId)
          location.href = Page.get(Page.get()?.settings.accessControl.fallbackPageId)?.path || "";
        else alert(API.alertText.loginErrorTryAgain);
      }
    } else {
      if (responseParsed.message)
        alert(API.alertText.loginError + " Error: " + responseParsed.message);
      reportError(
        "13000",
        `Server Error. Code: ${response.status}. Could not load logged in user.`
      );
    }
  },
  requestPasswordReset: async () => {
    store.commit("SET_DATA_TYPE_VALUE", {
      value: {
        isRequesting: true,
        hasRequested: false,
        statusCode: 0,
        errorMessage: "",
        data: undefined,
      },
      type: "requestPasswordReset",
    });
    History.requestStart("requestPasswordReset");
    const projectId = store.state.project.id;
    const email = (document.querySelector(
      Page.get()?.settings.login.emailPasswordRequestResetEmailQuery || ""
    ) as HTMLInputElement)?.value;
    if (!emailValidator.validate(email)) {
      alert(API.alertText.emailInvalid);
      return;
    }
    const response = await fetch(
      `${env.serverUrl}v1/project/user/request-password-reset?projectId=${projectId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          pageId: Page.get()?.settings.login.resetPasswordPageId,
        }),
      }
    ).catch((error) => {
      throw new Error(error.message);
    });
    const responseParsed = await response.json();
    store.commit("SET_DATA_TYPE_VALUE", {
      value: {
        isRequesting: false,
        hasRequested: true,
        statusCode: response.status,
        errorMessage: responseParsed.error ? responseParsed.error : "",
        data: response.status === 200 ? responseParsed : undefined,
      },
      type: "requestPasswordReset",
    });
    History.requestEnd(response, responseParsed, "requestPasswordReset");
    if (response.status === 200) {
      alert(API.alertText.passwordResetEmailSent);
      const emailField = document.querySelector(
        Page.get()?.settings.login.emailPasswordRequestResetEmailQuery || ""
      );
      if (emailField) (emailField as HTMLInputElement).value = "";
    } else {
      if (responseParsed.message)
        alert(API.alertText.passwordResetEmailError + " Error: " + responseParsed.message);
      reportError(
        "13002",
        `Server Error. Code: ${response.status}. Could not send password reset link.`
      );
    }
  },
  resetPassword: async () => {
    store.commit("SET_DATA_TYPE_VALUE", {
      value: {
        isRequesting: true,
        hasRequested: false,
        statusCode: 0,
        errorMessage: "",
        data: undefined,
      },
      type: "resetPassword",
    });
    History.requestStart("resetPassword");
    const projectId = store.state.project.id;
    const resetToken = Parameter.get("reset-token");
    const password = (document.querySelector(
      Page.get()?.settings.login.emailPasswordResetPasswordQuery || ""
    ) as HTMLInputElement)?.value;
    const passwordValdidation = (document.querySelector(
      Page.get()?.settings.login.emailPasswordResetPasswordValidationQuery || ""
    ) as HTMLInputElement)?.value;
    const schema = new passwordValidator();
    schema.is().min(6).is().max(100).has().uppercase().has().lowercase();
    if (!schema.validate(password)) {
      alert(API.alertText.passwordInsecure);
      return;
    }
    if (password !== passwordValdidation) {
      alert(API.alertText.passwordMismatch);
      return;
    }
    const response = await fetch(
      `${env.serverUrl}v1/project/user/reset-password?projectId=${projectId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ password: password, resetToken: resetToken }),
      }
    ).catch((error) => {
      throw new Error(error.message);
    });
    const responseParsed = await response.json();
    store.commit("SET_DATA_TYPE_VALUE", {
      value: {
        isRequesting: false,
        hasRequested: true,
        statusCode: response.status,
        errorMessage: responseParsed.error ? responseParsed.error : "",
        data: response.status === 200 ? responseParsed : undefined,
      },
      type: "resetPassword",
    });
    History.requestEnd(response, responseParsed, "resetPassword");
    if (response.status === 200) {
      alert(API.alertText.passwordResetSuccess);
      store.commit("SET_DATA_TYPE_VALUE", { type: "user", value: responseParsed.user });
      Cookie.set("w---at", responseParsed.accessToken, 30);
      Parameter.delete("reset-token");
      if (
        Page.get()?.settings.login.redirectAfterPasswordReset &&
        Page.get()?.settings.login.redirectPageIdAfterPasswordReset
      ) {
        // Redirect to page
        location.href =
          store.state.configuration.pages.find(
            (page: PageModel) =>
              page.id === Page.get()?.settings.login.redirectPageIdAfterPasswordReset
          ).path || "";
      } else {
        location.reload();
      }
    } else {
      if (responseParsed.message)
        alert(API.alertText.passwordResetEmailError + " Error: " + responseParsed.message);
      reportError(
        "13004",
        `Server Error. Code: ${response.status}. Could not send password reset link.`
      );
    }
  },
  logout: (noRefresh?: boolean): void => {
    // Delete Cookie
    Cookie.set("w---at", "", 0);
    console.log("Logged out");
    // Reaload page to force re-auth
    if (!noRefresh) location.reload();
  },
};

export default Authentication;
