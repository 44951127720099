
























import { Component, Vue, Watch } from "vue-property-decorator";
import BlockHeading from "@/components/ui/BlockHeading.vue";
import Page from "@/components/tab/pages/Page.vue";
import Folder from "@/components/tab/pages/Folder.vue";
import { PageModel, PageMenuModel } from "@/models/Configuration";
import LoadingSpinner from "@/components/misc/LoadingSpinner.vue";
import Id from "@/helpers/id";
import PageHelpers from "@/helpers/page";

@Component({
  components: {
    BlockHeading,
    Page,
    LoadingSpinner,
    Folder,
  },
})
export default class Pages extends Vue {
  folder: Array<PageMenuModel> = [];

  get pageMenu(): Array<PageMenuModel> {
    let pageMenu: Array<PageMenuModel> = [...this.pages, ...this.folder];
    pageMenu = pageMenu.sort((a, b) => {
      if (a.type === "folder") return -1;
      if (a.type === "page") return 1;
      return 0;
    });
    pageMenu = pageMenu.sort((a, b) => {
      if (a.path < b.path) return -1;
      if (a.path > b.path) return 1;
      return 0;
    });
    pageMenu = pageMenu.filter((p) => {
      let closed = false;
      this.folder.forEach((folder) => {
        if (p.path.includes(folder.path) && p.path !== folder.path && !folder.open) closed = true;
      });
      if (closed) return false;
      else return true;
    });
    return pageMenu;
  }

  get pages(): Array<PageMenuModel> {
    let pagesRaw: Array<PageModel> = this.$store.state.configuration.pages;
    this.folder = [];
    pagesRaw = pagesRaw.sort((a, b) => {
      if (a.path < b.path) return -1;
      if (a.path > b.path) return 1;
      return 0;
    });
    let pages: Array<PageMenuModel> = [];
    pagesRaw.forEach((page) => {
      if (page.id)
        pages.push({
          path: page.path,
          open: false,
          type: "page",
          id: page.id,
          numberOfElementConnections: page.elements?.length | 0,
          numberOfPagesInFolder: 0,
        });
    });

    // Find & write folders
    pages.forEach((page, pageIndex) => {
      const pathParts = page?.path?.split("/")?.filter((p) => p);
      const pathPartsNext = pages[pageIndex + 1]?.path?.split("/")?.filter((p) => p);
      let similarityCounter = 0;
      pathParts.forEach((part, partIndex) => {
        if (pathPartsNext && pathPartsNext[partIndex] === part)
          similarityCounter = similarityCounter + 1;
      });
      if (similarityCounter > 0 && similarityCounter + 1 === pathParts.length) {
        // Check if folder is already there
        if (
          !this.folder.find(
            (folder) => page.path.split("/").slice(0, -1).join("/") + "/" === folder.path
          )
        ) {
          let folder = {
            path: page.path.split("/").slice(0, -1).join("/") + "/",
            open: true,
            type: "folder" as "folder" | "page",
            id: "",
            numberOfElementConnections: 0,
            numberOfPagesInFolder: 0,
          };
          let numberOfPagesInFolder = 0;
          const pathShort = folder.path.split("/").slice(0, -1).join("/");
          pages.forEach((p: PageMenuModel) => {
            if (p.path.split("/").slice(0, -1).join("/") === pathShort)
              numberOfPagesInFolder = numberOfPagesInFolder + 1;
          });
          folder.numberOfPagesInFolder = numberOfPagesInFolder;
          // Add folder
          this.folder.push(folder);
        }
      }
    });
    return pages;
  }

  mounted() {
    PageHelpers.checkAndUpdatePageList();
  }

  newPage(): void {
    this.$store.commit("SET_POPUP_PAGESETTINGS", {
      page: {},
      isOpen: true,
    });
  }

  toggleFolder(folder: PageMenuModel): void {
    let folderIndex = this.folder.findIndex((f) => f.path === folder.path);
    this.folder[folderIndex].open = !folder.open;
  }
}
