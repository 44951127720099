







import { Component, Vue, Watch } from "vue-property-decorator";
import Button from "@/components/ui/Button.vue";

const Props = Vue.extend({
  props: {
    initialValue: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: true,
    },
  },
});

@Component({
  components: {
    Button,
  },
})
export default class Toggle extends Props {
  value = false;

  mounted() {
    this.value = this.$props.initialValue;
  }

  @Watch("initialValue")
  onPropertyChanged(value: boolean): void {
    this.value = value;
  }

  select(value: boolean): void {
    if (this.active) this.value = value;
    this.$emit("change", this.value);
  }
}
