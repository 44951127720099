








































































import { Component, Vue, Watch } from "vue-property-decorator";
import Variable from "@/components/ui/Variable.vue";
import Value from "@/helpers/value";
import draggable from "vuedraggable";

const Props = Vue.extend({
  props: {
    reqData: {
      type: [Array, Object],
    },
    requestId: {
      type: String,
    },
    hasDragNDrop: {
      type: Boolean,
      default: false,
    },
  },
});

@Component({
  components: { Variable, draggable },
})
export default class RequestData extends Props {
  closedData: {
    type: "array" | "object" | "string" | "number" | "boolean";
    path: string;
    value: any;
    depth: number;
  }[] = [];

  variablesClosed = false;

  clone(attributes: {
    name: string;
    type: string;
    path: string;
    context: string;
  }): { name: string; type: string; path: string; context: string } {
    return {
      name: attributes.name,
      type: attributes.type,
      path: attributes.path,
      context: attributes.context,
    };
  }

  checkMove(event: any) {
    if (event.from === event.to) return false;
    else true;
  }

  setIsDragging(value: boolean): void {
    this.$store.commit("SET_HELPER_ISDRAGGINGVARIABLE", value);
  }

  get isEmpty(): boolean {
    if (this.formattedData === []) return true;
    else return false;
  }

  get formattedAndFilteredData(): Array<{
    type: "array" | "object" | "string" | "number" | "boolean";
    path: string;
    value: any;
    depth: number;
  }> {
    let result: Array<{
      type: "array" | "object" | "string" | "number" | "boolean";
      path: string;
      value: any;
      depth: number;
    }> = [];
    this.formattedData.forEach((data) => {
      let found = false;
      this.closedData.forEach((closedData) => {
        if (closedData.path === "[array]" && data.path !== closedData.path) found = true;
        if (data.path.includes(closedData.path) && data.path !== closedData.path) found = true;
      });
      if (!found) result.push(data);
    });
    return result;
  }

  toggleVisibility(data: {
    type: "array" | "object" | "string" | "number" | "boolean";
    path: string;
    value: any;
    depth: number;
  }) {
    if (data.type !== "array" && data.type !== "object") return;
    const isClosed = Boolean(this.closedData.find((dataClosed) => dataClosed.path === data.path));
    if (isClosed) {
      const index = this.closedData.findIndex((dataClosed) => dataClosed.path === data.path);
      this.closedData.splice(index, 1);
    } else this.closedData.push(data);
  }

  get formattedData(): Array<{
    type: "array" | "object" | "string" | "number" | "boolean";
    path: string;
    value: any;
    depth: number;
  }> {
    if (!this.reqData) {
      return [];
    }
    let result: {
      type: "array" | "object" | "string" | "number" | "boolean";
      path: string;
      value: any;
      depth: number;
    }[] = [];
    if (typeof this.reqData === "object" || Array.isArray(this.reqData)) {
      // Flatten Object
      const flattendedData = Value.flattenObject(this.reqData);
      for (const [key, value] of Object.entries(flattendedData as { [index: string]: any })) {
        let type: "array" | "object" | "string" | "number" | "boolean" = typeof value as
          | "array"
          | "object"
          | "string"
          | "number"
          | "boolean";
        if (type === "object" && Array.isArray(value)) type = "array";
        result.push({
          type: type,
          path: key,
          value: value,
          depth: key.split(".").length - 1,
        });
      }
    } else if (typeof this.reqData === "string") {
      result.push({ type: "string", path: "body", value: this.reqData, depth: 0 });
    } else if (typeof this.reqData === "number") {
      result.push({ type: "number", path: "body", value: this.reqData, depth: 0 });
    } else if (typeof this.reqData === "boolean") {
      result.push({
        type: "boolean",
        path: "body",
        value: this.reqData,
        depth: 0,
      });
    }
    result = result.sort((a, b) => {
      return a.path.localeCompare(b.path, "en", { sensitivity: "base" });
    });
    if (Array.isArray(this.reqData))
      result.unshift({ type: "array", path: "[array]", value: this.reqData, depth: 0 });
    return result;
  }

  mounted() {
    if (this.variablesClosed) {
      // Default for array and objects is closed
      this.formattedData.forEach((data) => {
        if (data.type === "array" || data.type === "object") this.toggleVisibility(data);
      });
    }
  }

  depthArray(dataF: any) {
    return new Array(
      dataF.path.split(".").length - 1 === 0 && !(dataF.type === "array" || dataF.type === "object")
        ? 1
        : dataF.depth
    );
  }

  hasVerticalSeperator(dataF: any, index: number) {
    return dataF.path.split(".").length - 1 === 0 ? false : true;
  }
}

// Variable Name: requestId.variable-path]
