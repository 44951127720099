import Parameter from "@/helpers/parameter";

let mode = process.env.NODE_ENV; // 'development', 'test', 'production'

if (Parameter.get("w-development") === "1") mode = "development";
if (Parameter.get("w-staging") === "1") mode = "staging";

const env = {
  mode: mode,
  serverUrl: "",
  dashboardUrl: "",
  configuratorUrl: "",
};

if (mode === "development") {
  env.serverUrl = "http://localhost:3000/";
  env.dashboardUrl = "http://localhost:8080/";
  env.configuratorUrl = "http://localhost:8081/";
}
if (mode === "staging") {
  env.serverUrl = "https://wized-staging-server.ey.r.appspot.com/";
  env.dashboardUrl = "https://app.wized.io/";
  env.configuratorUrl = "https://embed.wized.io/";
} else if (mode === "production") {
  env.serverUrl = "https://api.wized.io/";
  env.dashboardUrl = "https://app.wized.io/";
  env.configuratorUrl = "https://embed.wized.io/";
}

export default env;
