















import { Component, Vue, Watch } from "vue-property-decorator";
import Button from "@/components/ui/Button.vue";
import env from "@/envConfig";
import Cookie from "@/helpers/cookie";

@Component({
  components: {
    Button,
  },
})
export default class AuthenticationInvalid extends Vue {
  startLogin(): void {
    const projectId = this.$store.state.project.id;
    window.location.href =
      env.dashboardUrl + "?refresh=1&projectId=" + projectId + "&url=" + window.location.href;
  }
  logOut(): void {
    Cookie.set("pw-t", "", 0);
    location.reload();
  }
}
