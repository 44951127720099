















import { Component, Vue, Watch } from "vue-property-decorator";
import LoadingSpinner from "@/components/misc/LoadingSpinner.vue";
import { ConfigurationModel } from "@/models/Configuration";
import { reportError } from "@/helpers/error-handler";

@Component({
  components: { LoadingSpinner },
})
export default class Save extends Vue {
  isSaving = false;

  get configuration(): ConfigurationModel {
    return this.$store.state.configuration;
  }

  @Watch("configuration", { deep: true })
  onValueChanged(newValue: ConfigurationModel, oldValue: ConfigurationModel): void {
    // Prevent saving an initial load
    if (oldValue.pages.length !== 0) {
      this.save();
    }
  }

  async save(): Promise<void> {
    try {
      this.isSaving = true;
      await this.$store.dispatch("saveConfiguration");
      this.isSaving = false;
    } catch (error) {
      this.isSaving = false;
      reportError("14000", "Error while auto-saving configuration.", error);
    }
  }
}
