



















































































import { Component, Vue, Watch } from "vue-property-decorator";
import Button from "@/components/ui/Button.vue";
import Variable from "@/components/ui/Variable.vue";
import ResourceModel from "@/models/Resource";
import ResponseModel from "@/models/Response";
import Request from "@/helpers/request";
import LoadingSpinner from "@/components/misc/LoadingSpinner.vue";
import RequestData from "@/components/tab/data/RequestData.vue";
import draggable from "vuedraggable";

const Props = Vue.extend({
  props: {
    request: {
      type: Object,
    },
    hasDragNDrop: {
      type: Boolean,
      default: false,
    },
  },
});

@Component({
  components: { Button, Variable, LoadingSpinner, RequestData, draggable },
})
export default class RequestRuntime extends Props {
  requestData: ResponseModel = {
    isRequesting: false,
    hasRequested: false,
    statusCode: 0,
    errorMessage: "",
    data: {},
  };

  runRequest(): void {
    Request.perform(this.request.id);
  }

  resourceType(resource: ResourceModel): { name: string; icon: string } {
    if (!resource || !resource.type) {
      return { name: "File Upload", icon: "upload-white" };
    }
    if (resource.type === "airtable") {
      return { name: "Airtable", icon: "Airtable" };
    } else if (resource.type === "stripe") {
      return { name: "Stripe", icon: "Stripe" };
    } else {
      return { name: "REST API", icon: "API" };
    }
  }

  clone(attributes: {
    name: string;
    type: string;
    path: string;
  }): { name: string; type: string; path: string } {
    return {
      name: attributes.name,
      type: attributes.type,
      path: attributes.path,
    };
  }

  checkMove(event: any) {
    if (event.from === event.to) return false;
    else true;
  }

  setIsDragging(value: boolean): void {
    this.$store.commit("SET_HELPER_ISDRAGGINGVARIABLE", value);
  }

  get resource(): ResourceModel {
    return this.$store.state.configuration.resources.find(
      (resource: ResourceModel) => resource.id === this.request.resourceId
    );
  }

  get requestType(): string | undefined {
    if (this.request.resourceId === "file-upload") return "File Upload (Beta)";
    const types = [
      { name: "Get item list", id: "list" },
      { name: "Get item", id: "get" },
      { name: "Create item", id: "create" },
      { name: "Update item", id: "update" },
      { name: "Delete item", id: "delete" },
      { name: "Open checkout", id: "checkout" },
      { name: "Open customer portal", id: "customer-portal" },
      { name: "GET", id: "get" },
      { name: "POST", id: "post" },
      { name: "PUT", id: "put" },
      { name: "PATCH", id: "patch" },
      { name: "DELETE", id: "delete" },
    ];
    return types.find((type) => type.id === this.request.type)?.name;
  }

  get reqData(): { id: string; value: ResponseModel } | undefined {
    const allData = this.$store.state.data.requests;
    const request = allData.find(
      (data: { id: string; value: ResponseModel }) => data.id === this.request.id
    );
    return request && request.value ? request.value : {};
  }

  get state(): Array<{
    id: string;
    name: string;
    type: string;
    path: string;
  }> {
    return [
      {
        name: "isRequesting",
        id: "isRequesting",
        path: this.request.id + ".state." + "isRequesting",
        type: "state",
      },
      {
        name: "hasRequested",
        id: "hasRequested",
        path: this.request.id + ".state." + "hasRequested",
        type: "state",
      },
      {
        name: "statusCode",
        id: "statusCode",
        path: this.request.id + ".state." + "statusCode",
        type: "state",
      },
    ];
  }
}
