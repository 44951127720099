























































import { Component, Vue, Watch } from "vue-property-decorator";
import { PageModel } from "@/models/Configuration";
import Button from "@/components/ui/Button.vue";

const Props = Vue.extend({
  props: {
    page: {
      type: Object,
      required: true,
      validator: (value) => {
        if (value.id && value.path) return true;
        return false;
      },
    },
  },
});

@Component({
  components: {
    Button,
  },
})
export default class Page extends Props {
  get path(): string {
    return window.location.pathname;
  }

  openPage(): void {
    if (this.path !== this.page.path) window.location.href = this.page.path;
  }

  openPageSettings(): void {
    this.$store.commit("SET_POPUP_PAGESETTINGS", {
      page: this.page,
      isOpen: true,
    });
  }
}
