
























































import { Component, Vue, Watch } from "vue-property-decorator";
import LoadingSpinner from "@/components/misc/LoadingSpinner.vue";
import { ConfigurationModel } from "@/models/Configuration";
import RequestModel from "@/models/Request";
import Page from "@/helpers/page";
import Value from "@/helpers/value";
import ResourceModel from "@/models/Resource";

const Props = Vue.extend({
  props: {
    value: {
      type: String,
      default: "undefined",
    },
    type: {
      type: String,
      default: "undefined",
    },
    name: {
      type: String,
      default: "undefined",
    },
    path: {
      type: String,
      default: "",
    },
  },
});

@Component({
  components: { LoadingSpinner },
})
export default class VariableTooltip extends Props {
  height = 0;
  width = 0;
  offsetLeft = 0;
  offsetTop = 0;

  mounted(): void {
    const distanceToWindowTop = window.pageYOffset + this.$el.getBoundingClientRect().top;
    if (distanceToWindowTop < 100) {
      (this.$el as HTMLElement).style.bottom = "auto";
      (this.$el as HTMLElement).style.top = "23px";
    }
    this.height = (this.$el as HTMLElement).offsetHeight;
    this.width = (this.$el as HTMLElement).offsetWidth;
    (this.$el as HTMLElement).style.left = "140px";
    this.offsetLeft = this.$el.getBoundingClientRect().left;
    this.offsetTop = this.$el.getBoundingClientRect().top;
  }

  getTypeOf(value: any): string {
    return Value.getTypeOf(value);
  }

  get resources(): Array<ResourceModel> {
    return this.$store.state.configuration.resources;
  }

  get resource(): ResourceModel | undefined {
    if (
      this.request?.id === "user" ||
      this.request?.id === "login" ||
      this.request?.id === "requestPasswordReset" ||
      this.request?.id === "resetPassword"
    )
      return { type: "airtable", id: "auth", name: "Authentication" };
    else return this.resources.find((resource) => resource.id === this.request?.resourceId);
  }

  get request(): RequestModel | undefined {
    if (
      this.type === "object" ||
      this.type === "attribute" ||
      this.type === "auth" ||
      this.type === "state"
    ) {
      const requestId = this.path.split(".")[0];
      if (
        requestId === "user" ||
        requestId === "login" ||
        requestId === "requestPasswordReset" ||
        requestId === "resetPassword"
      ) {
        const request = {
          id: "user",
          resourceId: "auth",
          type: "",
          arrayFilters: [],
          arraySortings: [],
          name: "",
        };
        if (requestId === "user") request.name = "Load user";
        else if (requestId === "login") request.name = "Login user";
        else if (requestId === "requestPasswordReset") request.name = "Request password reset";
        else if (requestId === "resetPassword") request.name = "Reset password";
        return request;
      } else return this.requests.find((request: RequestModel) => request.id === requestId);
    } else return undefined;
  }

  get requests(): Array<RequestModel> {
    const page = Page.get();
    if (page) return page.requests;
    else return [];
  }

  get backgroundColor(): string {
    let color = "";
    switch (this.type) {
      case "auth":
        color += "#e18e41";
        break;
      case "object":
        color += "#33adf2";
        break;
      case "attribute":
        color += "#0062ff";
        break;
      case "custom":
        color += "#6220f7";
        break;
      case "input":
        color += "#524a51";
        break;
      case "function":
        color += "#319986";
        break;
      case "keyword":
        color += "#d67373";
        break;
      case "operator":
        color += "#484047";
        break;
      case "variable":
        color += "#990744";
        break;
      default:
        color += "#484047";
        break;
    }
    return color;
  }

  get computedValue(): any {
    return this.path && Value.getValue(this.path);
  }
}
