



































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import ResourceModel from "@/models/Resource";
import Button from "@/components/ui/Button.vue";
import InputText from "@/components/ui/InputText.vue";
import InputSelect from "@/components/ui/InputSelect.vue";
import OptionTitle from "@/components/ui/OptionTitle.vue";
import Toggle from "@/components/ui/Toggle.vue";
import PopupHeading from "@/components/ui/PopupHeading.vue";
import Id from "@/helpers/id";
import Page from "@/helpers/page";
import Validate from "@/helpers/validate";
import { reportError } from "@/helpers/error-handler";
import LoadingSpinner from "@/components/misc/LoadingSpinner.vue";

@Component({
  components: {
    Button,
    InputText,
    InputSelect,
    OptionTitle,
    Toggle,
    PopupHeading,
    LoadingSpinner,
  },
})
export default class EditResource extends Vue {
  createNewResource = false;
  isValidatingResource = false;
  triedValidatingResource = false;
  resourceValidated = false;
  editedResource: ResourceModel = {
    id: "",
    name: "",
    type: "",
    apiKey: "",
    url: "",
    privateKey: "",
    publicKey: "",
  };
  types = [
    {
      name: "Resource types",
      values: [
        { id: "airtable", name: "Airtable" },
        { id: "stripe", name: "Stripe" },
        { id: "rest", name: "REST Api" },
      ],
    },
  ];

  set(
    value: string,
    attributeName: "name" | "type" | "apiKey" | "publicKey" | "privateKey" | "url"
  ): void {
    if (attributeName === "type") {
      this.editedResource = {
        id: this.editedResource.id,
        type: value,
        name: this.editedResource.name,
        apiKey: "",
        url: "",
        privateKey: "",
        publicKey: "",
      };
    } else this.editedResource[attributeName] = value;
  }

  saveResource(): void {
    const resource = this.editedResource;
    if (this.editedResource.type === "airtable") {
      delete resource["url"];
      delete resource["privateKey"];
      delete resource["publicKey"];
    } else if (this.editedResource.type === "stripe") {
      delete resource["url"];
      delete resource["apiKey"];
    } else if (this.editedResource.type === "rest") {
      delete resource["apiKey"];
      delete resource["privateKey"];
      delete resource["publicKey"];
    }
    this.$store.commit("SET_RESOURCE", resource);
    this.close();
  }

  deleteResource(): void {
    // TODO: Check if there are no more connections or requests on the page
    if (confirm("Are you sure you want to delete this resource?"))
      this.$store.commit("DELETE_RESOURCE", this.editedResource.id);
    else return;
    this.close();
  }

  async testResource(): Promise<void> {
    this.isValidatingResource = true;
    try {
      const resourceIsValid = await Validate.resource(this.editedResource);
      this.resourceValidated = resourceIsValid;
    } catch (error) {
      reportError(
        "10000",
        "Error during request. Resource could not be validated. Please try again.",
        error
      );
    }
    this.triedValidatingResource = true;
    this.isValidatingResource = false;
  }

  close(): void {
    this.$store.commit("SET_POPUP_RESOURCESETTINGS", {
      resource: {},
      isOpen: false,
    });
  }

  mounted(): void {
    if (Object.keys(this.resource).length === 0) {
      this.createNewResource = true;
      this.editedResource.id = Id.create(this.$store.state.configuration.resources);
    } else this.editedResource = { ...this.editedResource, ...this.resource };
  }

  get resource(): ResourceModel {
    return this.$store.state.popup.resourceSettings.resource;
  }

  get isValidResource(): boolean {
    if (this.editedResource.type === "airtable") return this.isValidAirtableResource;
    else if (this.editedResource.type === "stripe") return this.isValidStripeResource;
    else if (this.editedResource.type === "rest") return this.isValidRestResource;
    else return false;
  }

  get isValidAirtableResource(): boolean {
    if (
      this.editedResource.id &&
      this.editedResource.type &&
      this.editedResource.name &&
      this.editedResource.apiKey &&
      this.resourceValidated
    )
      return true;
    else return false;
  }

  get isValidStripeResource(): boolean {
    if (
      this.editedResource.id &&
      this.editedResource.type &&
      this.editedResource.name &&
      this.editedResource.privateKey &&
      this.editedResource.publicKey &&
      this.resourceValidated
    )
      return true;
    else return false;
  }

  get isValidRestResource(): boolean {
    if (!this.editedResource.url) return false;
    try {
      let isUrl = false;
      const url = new URL(this.editedResource.url);
      if (url) this.set(this.editedResource.url, "url");
      isUrl = Boolean(url);
      if (
        this.editedResource.id &&
        this.editedResource.type &&
        this.editedResource.name &&
        this.editedResource.url &&
        isUrl &&
        url.origin !== null
      )
        return true;
      else return false;
    } catch (e) {
      return false;
    }
  }

  get hasDeleteOption(): boolean {
    return !this.createNewResource;
  }
}
