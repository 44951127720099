




import { Component, Vue, Watch } from "vue-property-decorator";
import ResizeObserver from "resize-observer-polyfill";
import env from "@/envConfig";
import ElementToolkit from "@/helpers/element";

@Component({
  components: {},
})
export default class Website extends Vue {
  mounted(): void {
    this.toggleWebflowBadge(false);
    this.moveAllElementsInWebsiteDiv();
    this.resizeObserver();
    this.addWebsiteDivClickHandler();
    this.addWebsiteHoverHandler();
  }

  @Watch("isFullScreenPreview")
  onPreviewChanged(value: boolean): void {
    this.resize();
    this.toggleWebflowBadge(value);
  }

  @Watch("leftTabOpen")
  onLeftTabChanged(): void {
    this.resize();
  }

  resize(): void {
    this.scaleWebsiteDiv();
    this.refreshSelectedAndHoveredElements();
  }

  resizeObserver(): void {
    const resizeObserver = new ResizeObserver((entries: any) => {
      this.resize();
    });
    resizeObserver.observe(document.body);
  }

  moveAllElementsInWebsiteDiv(): void {
    const firstLevelElements = document.querySelectorAll("body > *");
    firstLevelElements.forEach((element: any) => {
      if (element && element.id !== "wized") {
        const node = document.getElementById("pw---website");
        if (node) node.appendChild(element);
      }
    });
  }

  scaleWebsiteDiv(): void {
    const node = document.getElementById("pw---website");
    const leftBar = this.isFullScreenPreview ? 0 : this.leftTabOpen ? 255 : 35;
    const rightBar = this.isFullScreenPreview ? 0 : 220;
    const topBar = 35;
    const documentWidth = window.innerWidth;
    const documentHeight = window.innerHeight;
    const websiteDivWidth = documentWidth - (rightBar + leftBar);
    const scale = websiteDivWidth / documentWidth;
    if (node) {
      node.setAttribute("style", "transform: scale(" + scale + ");");
      const nodeWidth = documentWidth * scale;
      const nodeHeight = documentHeight * scale;
      const nodeHeightAdjustedForScale = (documentHeight - topBar) / scale;
      const marginLeft = leftBar - (documentWidth - nodeWidth) / 2;
      const marginTop = ((documentHeight - nodeHeight) / 2) * -1;
      node.setAttribute("style", "transform: scale(" + scale + ");");
      node.style.marginLeft = marginLeft + "px";
      node.style.height = nodeHeightAdjustedForScale + "px ";
      const nodeRect = document.querySelector("#pw---website")?.getBoundingClientRect();
      if (nodeRect) node.style.marginTop = -1 * (nodeRect.top - topBar) + "px";
    }
  }

  toggleWebflowBadge(badgeIsShown: boolean): void {
    setTimeout(() => {
      const badge = document.querySelectorAll<HTMLElement>(".w-webflow-badge")[0];
      if (badge && badgeIsShown) {
        badge.setAttribute("style", "display:block !important");
      } else if (badge && !badgeIsShown) {
        badge.setAttribute("style", "display:none !important");
      }
    }, 500);
  }

  refreshSelectedAndHoveredElements(): void {
    const selected = this.$store.state.element.selected;
    const hovered = this.$store.state.element.hovered;
    this.$store.commit("SET_SELECTED_ELEMENT", {});
    this.$store.commit("SET_HOVERED_ELEMENT", {});
    this.$store.commit("SET_SELECTED_ELEMENT", selected);
    this.$store.commit("SET_HOVERED_ELEMENT", hovered);
  }

  addWebsiteDivClickHandler(): void {
    const website = document.getElementById("pw---website");
    if (!website) {
      return;
    }
    website.addEventListener("click", (event) => this.selectElementOnClick(event), true);
  }

  addWebsiteHoverHandler(): void {
    const website = document.getElementById("pw---website");
    if (!website) {
      return;
    }
    website.addEventListener(
      "mouseover",
      (event) =>
        this.$store.commit(
          "SET_HOVERED_ELEMENT",
          ElementToolkit.format(event.target as HTMLElement)
        ),
      true
    );
    website.addEventListener(
      "mouseleave",
      (event) => this.$store.commit("SET_HOVERED_ELEMENT", {}),
      true
    );
  }

  selectElementOnClick(event: MouseEvent): void {
    if (!this.$store.state.configuration.preview.isFullScreen) {
      event.preventDefault();
      event.stopPropagation();
      if (event.target) {
        const selectedElement = ElementToolkit.format(event.target as HTMLElement);
        this.$store.commit("SET_SELECTED_ELEMENT", selectedElement);
      }
    }
  }

  get leftTabOpen(): boolean {
    if (this.$store.state.tab.tabName) return true;
    else return false;
  }

  get isFullScreenPreview(): boolean {
    return this.$store.state.configuration.preview.isFullScreen;
  }
}
