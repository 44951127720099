import ErrorModel from "@/models/Error";
import store from "@/store";
import Id from "./id";

const reportError = (errorCode: string, errorDescription: string, providedError?: any) => {
  const error = new Error(errorCode + " | " + errorDescription + " | " + providedError);
  console.error(error);
  const errors = store.state.errors;
  const id = Id.create(store.state.errors);
  errors.push({
    title: errorDescription,
    error: providedError.toString(),
    id: id,
  });
  store.commit("SET_ERRORS", errors);
  hideErrorAfterTimeout(id, 20);
};

const hideErrorAfterTimeout = (id: string, timeOutInSeconds: number) => {
  setTimeout(() => {
    const errors = JSON.parse(JSON.stringify(store.state.errors));
    const index = errors.findIndex((err: ErrorModel) => err.id === id);
    if (index !== undefined && index !== -1) errors.splice(index, 1);
    store.commit("SET_ERRORS", errors);
  }, timeOutInSeconds * 1000);
};

export { reportError };
