
























































































import { Component, Vue, Watch } from "vue-property-decorator";
import BlockHeading from "@/components/ui/BlockHeading.vue";
import Resource from "@/components/tab/requests/Resource.vue";
import Request from "@/components/tab/requests/Request.vue";
import EmptyAdd from "@/components/ui/EmptyAdd.vue";
import EmptyTutorial from "@/components/ui/EmptyTutorial.vue";
import ResourceModel from "@/models/Resource";
import RequestModel from "@/models/Request";
import { ConfigurationModel, PageModel } from "@/models/Configuration";
import Page from "@/helpers/page";
import UpgradeNotice from "@/components/tab/UpgradeNotice.vue";

@Component({
  components: {
    BlockHeading,
    Resource,
    Request,
    EmptyAdd,
    EmptyTutorial,
    UpgradeNotice,
  },
})
export default class Requests extends Vue {
  newResource(): void {
    this.$store.commit("SET_POPUP_RESOURCESETTINGS", {
      resource: {},
      isOpen: true,
    });
  }

  newRequest(): void {
    const emptyRequest: RequestModel = {
      id: "",
      resourceId: "",
      name: "",
      type: "",
      executeOnPageLoad: false,
      executeOnCondition: "",
      executeAfterRequests: [],
      redirectAfterCreateAccount: false,
      redirectPageIdAfterCreateAccount: "",
      actions: [],

      // Type Airtable
      baseId: "",
      tableId: "",
      recordId: "",
      viewId: "",
      filter: [],
      sort: [],
      fields: [],
      typecast: true,
      limitToFields: [],
      maxRecords: 99,
      isCreateUser: false,

      // Type Stripe: Single Payment
      purchaseType: "one-time",
      items: [],
      customerEmail: "",
      billingAddressCollection: false,
      allowedCountries: [],
      submitType: "auto", // auto, book, donate, pay
      successUrl: "",
      cancelUrl: "",
      paymentMethods: [],
      // Type Stripe: Customer portal
      customerId: "",
      returnUrl: "",
      metadata: [],

      // Type REST
      endpoint: "",
      headers: [],
      params: [],
      contentType: "none", // none, application/json, multipart/form-data
      bodyForm: [],
      body: "",

      arrayFilters: [],
      arraySortings: [],
    };
    this.$store.commit("SET_POPUP_REQUESTSETTINGS", {
      request: emptyRequest,
      isOpen: true,
    });
  }

  get resources(): Array<ResourceModel> {
    return this.$store.state.configuration.resources
      ? this.$store.state.configuration.resources
      : [];
  }

  get requests(): Array<RequestModel> {
    const page = Page.get();
    if (page) return page.requests;
    else return [];
  }

  get isOnFreePlan(): boolean {
    return (
      this.$store.state.project.plan !== "launch" && this.$store.state.project.plan !== "business"
    );
  }
}
