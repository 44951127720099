
























































import { Component, Vue, Watch } from "vue-property-decorator";
import Variable from "@/components/ui/Variable.vue";
import draggable from "vuedraggable";
import Value from "@/helpers/value";

const Props = Vue.extend({
  props: {
    initialValue: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 120,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});

@Component({
  components: { Variable, draggable },
})
export default class InputAll extends Props {
  isDragging = false;
  value: Array<{
    name: string;
    type: string;
    path?: string;
    context?: string;
    value?: string | number;
  }> = [];
  evaluatedValue: any = null;

  correctFunction(event: any) {
    const name = event && event.added && event.added.element && event.added.element.name;
    const index = event && event.added && event.added.newIndex;
    if (
      name === "if" ||
      name === "includes" ||
      name === "()" ||
      name === "toString" ||
      name === "toNumber" ||
      name === "toBoolean" ||
      name === "parseDate" ||
      name === "formatDate" ||
      name === "invertBoolean" ||
      name === "addString" ||
      name === "removeString"
    ) {
      if (name === "if") {
        Vue.set(this.value, index, {
          name: name + "(",
          type: "function",
        });
        this.value.splice(index + 1, 0, {
          name: ";",
          type: "operator",
        });
        this.value.splice(index + 2, 0, {
          name: ";",
          type: "operator",
        });
        this.value.splice(index + 3, 0, {
          name: ")",
          type: "function",
        });
      }
      if (name === "includes") {
        Vue.set(this.value, index, {
          name: name + "(",
          type: "function",
        });
        this.value.splice(index + 1, 0, {
          name: ";",
          type: "operator",
        });
        this.value.splice(index + 2, 0, {
          name: ")",
          type: "function",
        });
      }
      if (name === "()") {
        Vue.set(this.value, index, {
          name: "(",
          type: "function",
        });
        this.value.splice(index + 1, 0, {
          name: ")",
          type: "function",
        });
      }
      if (name === "toString") {
        Vue.set(this.value, index, {
          name: name + "(",
          type: "function",
        });
        this.value.splice(index + 1, 0, {
          name: ")",
          type: "function",
        });
      }
      if (name === "toNumber") {
        Vue.set(this.value, index, {
          name: name + "(",
          type: "function",
        });
        this.value.splice(index + 1, 0, {
          name: ")",
          type: "function",
        });
      }
      if (name === "toBoolean") {
        Vue.set(this.value, index, {
          name: name + "(",
          type: "function",
        });
        this.value.splice(index + 1, 0, {
          name: ")",
          type: "function",
        });
      }
      if (name === "parseDate") {
        Vue.set(this.value, index, {
          name: name + "(",
          type: "function",
        });
        this.value.splice(index + 1, 0, {
          name: ")",
          type: "function",
        });
      }
      if (name === "formatDate" || name === "addString" || name === "removeString") {
        Vue.set(this.value, index, {
          name: name + "(",
          type: "function",
        });
        this.value.splice(index + 1, 0, {
          name: ";",
          type: "operator",
        });
        this.value.splice(index + 2, 0, {
          name: ")",
          type: "function",
        });
      }
      if (name === "invertBoolean") {
        Vue.set(this.value, index, {
          name: name + "(",
          type: "function",
        });
        this.value.splice(index + 1, 0, {
          name: ")",
          type: "function",
        });
      }
    }
    this.change();
  }

  checkMove(event: any): boolean {
    if (event.from === event.to) return true;
    else return true;
  }

  deleteVariable(index: number) {
    this.value.splice(index, 1);
    this.change();
    if (this.value.length === 0 || !this.value) this.evaluatedValue = null;
    else this.evaluatedValue = Value.evaluateInputAll(this.value);
  }

  @Watch("initialValue", { immediate: true })
  onInitialValueChanged(value: string): void {
    if (this.disabled) return;
    this.value = value ? JSON.parse(value) : [];
    this.updateEvaluatedValue(value);
  }

  @Watch("value", { deep: true })
  updateEvaluatedValue(value: string): void {
    if (this.disabled) return;
    if (value === "[]" || !value) this.evaluatedValue = null;
    else this.evaluatedValue = Value.evaluateInputAll(this.value);
  }

  change(): void {
    if (!this.disabled) this.$emit("change", JSON.stringify(this.value));
  }

  openDataSelector(): void {
    if (!this.disabled) this.$store.commit("SET_POPUP_DATASELECTOR", true);
  }

  handleChange(value: string | number, index: number): void {
    if (this.disabled) return;
    const variable = this.value[index];
    variable.value = value;
    this.value[index] = variable;
    this.change();
  }

  setIsDragging(value: boolean): void {
    this.$store.commit("SET_HELPER_ISDRAGGINGVARIABLE", value);
  }

  get evaluatedValueType(): string {
    return Value.getTypeOf(this.evaluatedValue);
  }
}
