




































import { Component, Vue, Watch } from "vue-property-decorator";
import { PageModel } from "@/models/Configuration";
import Button from "@/components/ui/Button.vue";
import InputText from "@/components/ui/InputText.vue";
import OptionTitle from "@/components/ui/OptionTitle.vue";
import Toggle from "@/components/ui/Toggle.vue";
import PopupHeading from "@/components/ui/PopupHeading.vue";
import Id from "@/helpers/id";

@Component({
  components: {
    Button,
    InputText,
    OptionTitle,
    Toggle,
    PopupHeading,
  },
})
export default class EditPage extends Vue {
  createNewPage = false;
  editedPage: PageModel = {
    path: "",
    id: "",
    requests: [],
    parameters: [],
    variables: [],
    responses: [],
    settings: {
      accessControl: {
        accessToThisPageRestricted: false,
        authRequired: false,
        authFallbackPageId: "",
        customAccessRule: false,
        allowIf: [],
        fallbackPageId: "",
      },
      login: {
        onThisPage: false,
        magicLinkEnabled: false,
        magicLinkEmailQuery: "",
        magicLinkButtonQuery: "",
        googleLoginEnabled: false,
        googleLoginButtonQuery: "",
        emailPasswordLoginEnabled: false,
        emailPasswordEmailQuery: "",
        emailPasswordPasswordQuery: "",
        emailPasswordButtonQuery: "",
        redirectAfterLogin: false,
        redirectPageId: "",
        emailPasswordRequestReset: false,
        emailPasswordRequestResetEmailQuery: "",
        emailPasswordRequestResetButtonQuery: "",
        resetPasswordPageId: "",
        emailPasswordReset: false,
        emailPasswordResetPasswordQuery: "",
        emailPasswordResetPasswordValidationQuery: "",
        emailPasswordResetButtonQuery: "",
        redirectAfterPasswordReset: false,
        redirectPageIdAfterPasswordReset: "",
        logoutButton: "",
      },
    },
    elements: [],
  };

  mounted(): void {
    if (Object.keys(this.page).length === 0) {
      this.createNewPage = true;
      this.editedPage.id = Id.create(this.$store.state.configuration.pages);
    } else this.editedPage = { ...this.editedPage, ...this.page };
  }

  updateString(value: string, attributeName: "id" | "path"): void {
    value = value.replace(/\s+/g, "");
    this.editedPage[attributeName] = value;
  }

  savePage(): void {
    this.$store.commit("SET_PAGE", this.editedPage);
    this.close();
  }

  deletePage(): void {
    if (
      confirm(
        "Are you sure you want to delete this page including its requests and element connections?"
      )
    )
      this.$store.commit("DELETE_PAGE", this.editedPage.id);
    else return;
    this.close();
  }

  close(): void {
    this.$store.commit("SET_POPUP_PAGESETTINGS", {
      page: {},
      isOpen: false,
    });
  }

  get page(): PageModel {
    return this.$store.state.popup.pageSettings.page;
  }

  get isValidPage(): boolean {
    if (this.editedPage.id && this.editedPage.path[0] === "/") return true;
    else return false;
  }

  get hasDeleteOption(): boolean {
    if (this.page.path === window.location.pathname || this.createNewPage) {
      return false;
    } else {
      return true;
    }
  }
}
