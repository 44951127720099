


























import { Component, Vue, Watch } from "vue-property-decorator";
import { PageModel } from "@/models/Configuration";
import Button from "@/components/ui/Button.vue";
import DataSelector from "@/components/popup/DataSelector.vue";
import EditPage from "@/components/popup/EditPage.vue";
import EditParameter from "@/components/popup/EditParameter.vue";
import EditVariable from "@/components/popup/EditVariable.vue";
import EditCookie from "@/components/popup/EditCookie.vue";
import EditResource from "@/components/popup/EditResource.vue";
import EditRequest from "@/components/popup/EditRequest.vue";
import EditPreview from "@/components/popup/EditPreview.vue";
import EditFilter from "@/components/popup/EditFilter.vue";
import EditSorting from "@/components/popup/EditSorting.vue";
import Publish from "@/components/popup/Publish.vue";
import Errors from "@/components/popup/Errors.vue";

@Component({
  components: {
    DataSelector,
    Button,
    EditPage,
    EditResource,
    EditRequest,
    EditPreview,
    EditParameter,
    EditVariable,
    EditCookie,
    EditFilter,
    EditSorting,
    Publish,
    Errors,
  },
})
export default class Popups extends Vue {
  //
}
