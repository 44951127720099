











































































import { Component, Vue, Watch } from "vue-property-decorator";
import Save from "@/components/ui/Save.vue";
import Button from "@/components/ui/Button.vue";
import PreviewSettings from "@/components/nav/top/preview/PreviewSettings.vue";
import Page from "@/helpers/page.ts";
import Variable from "@/components/ui/Variable.vue";
import Cookie from "@/helpers/cookie";

@Component({
  components: { Save, Button, PreviewSettings, Variable },
})
export default class TopBarCenter extends Vue {
  logOut(): void {
    Cookie.set("pw-t", "", 0);
    location.reload();
  }

  openFullScreenPreview(): void {
    this.$store.commit("SET_PREVIEW", { isFullScreen: true });
  }

  openEditParameter(name: string, id: string): void {
    this.$store.commit("SET_POPUP_PARAMETERS", {
      isOpen: true,
      name: name,
      id: id,
    });
  }

  openNewParameter(): void {
    this.$store.commit("SET_POPUP_PARAMETERS", {
      isOpen: true,
      name: "",
      id: "",
    });
  }

  openPublish(): void {
    this.$store.commit("SET_POPUP_PUBLISH", { isOpen: true });
  }

  get parameters() {
    const page = Page.get();
    if (!page || !page.parameters) return [];
    else return page.parameters;
  }

  get hostname(): string {
    return window.location.hostname;
  }
  get path(): string {
    return location.pathname.substr(1);
  }
}
