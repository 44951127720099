










































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class LeftBar extends Vue {
  toggleTab(name: string) {
    if (this.$store.state.tab.tabName !== name) {
      this.$store.commit("SET_TAB", name);
    } else {
      this.$store.commit("SET_TAB", "");
    }
  }

  openLink(link: string): void {
    window.open(link, "_blank");
  }
}
