


































import { Component, Vue, Watch } from "vue-property-decorator";
import ElementToolkit from "@/helpers/element";
import ElementModel from "@/models/Element";
import Page from "@/helpers/page";
import { PageModel } from "@/models/Configuration";
import ElementHelper from "@/helpers/element";
import Info from "@/components/ui/tooltips/Info.vue";
import { ElementModel as ElementConfigurationModel } from "@/models/Configuration";
import Button from "@/components/ui/Button.vue";

const Props = Vue.extend({
  props: {
    element: {
      type: Object,
      validator: (value: any) => {
        if (value.queryString && Array.isArray(value.children)) return true;
        else return false;
      },
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
  },
});

@Component({
  components: { Info, Button },
})
export default class ElementUnfound extends Props {
  isHovering = false;

  hover(isHovering: boolean): void {
    this.isHovering = isHovering;
    if (isHovering) this.$store.commit("SET_HOVERED_ELEMENT", this.element);
    else this.$store.commit("SET_HOVERED_ELEMENT", {});
  }

  click(): void {
    this.$store.commit("SET_SELECTED_ELEMENT", this.element);
  }

  deleteElement(): void {
    const elementConnection = Page.get()?.elements.find(
      (element) => element.elementQueryString === this.element.queryString
    );
    if (elementConnection) this.$store.commit("DELETE_ELEMENT", elementConnection);
  }

  get parents(): Array<string> {
    const result: Array<string> = [];
    this.element.parents.forEach((value: string) => {
      if (
        value !== "#wized > div" &&
        value !== "#wized > div > .pw---full-page-wrapper:nth-of-type(2)"
      ) {
        result.push(value);
      }
    });
    return result;
  }

  get hasSettings(): boolean {
    const page = Page.get();
    const elements = (page as PageModel).elements;
    const elementDOM = document.querySelector(this.element.queryString);
    const oldQueryString = ElementHelper.getOldQueryString(elementDOM);
    const olderQueryString = ElementHelper.getOlderQueryString(elementDOM);
    const newQueryString = ElementHelper.getQueryString(elementDOM);
    const oldConnection = elements.find(
      (element) =>
        element.elementQueryString === oldQueryString ||
        element.elementQueryString === olderQueryString
    );

    return (
      Boolean(oldConnection) ||
      Boolean(elements.find((element) => element.elementQueryString === this.element.queryString))
    );
  }

  get elementDisplay() {
    return ElementToolkit.formatForDisplay(this.element);
  }
}
