



































import { Component, Vue, Watch } from "vue-property-decorator";
import OptionTitle from "@/components/ui/OptionTitle.vue";
import BlockHeading from "@/components/ui/BlockHeading.vue";
import InputAll from "@/components/ui/InputAll.vue";
import { ElementModel, PageModel, ListConnectionModel } from "@/models/Configuration";
import Page from "@/helpers/page";
import RequestModel from "@/models/Request";
import Configuration from "@/helpers/configuration";
import Value from "@/helpers/value";

@Component({
  components: {
    OptionTitle,
    BlockHeading,
    InputAll,
  },
})
export default class List extends Vue {
  error = "";

  set(value: any, attribute: string): void {
    const connection = this.connection;
    const evalValue = Value.evaluateInputAll(JSON.parse(value));
    if (Value.getTypeOf(evalValue) === "Array") {
      /*
      // Check if element has parent or child with render list settings
      const elementsWithRenderList = Page.get()?.elements.filter((element: ElementModel) => {
        return element.connections.list.value && element.connections.list.value !== "[]";
      });
      const currentElement = document.querySelector(this.$store.state.element.selected.queryString);
      let renderListFoundInParent = false;
      let renderListFoundInChild = false;
      elementsWithRenderList?.forEach((element: ElementModel) => {
        const renderElement: any = document.querySelector(element.elementQueryString);
        if (this.contains(renderElement, currentElement)) renderListFoundInParent = true;
        else if (this.contains(currentElement, renderElement)) renderListFoundInChild = true;
      });
      if (renderListFoundInChild) {
        this.error =
          "You can not nest render list settings. Please remove the render list from a child to add it here.";
        (connection as any)[attribute] = "";
      } else if (renderListFoundInParent) {
        this.error =
          "You can not nest render list settings. Please remove the render list from a parent to add it here.";
        (connection as any)[attribute] = "";
      } else {
        this.error = "";
        (connection as any)[attribute] = value;
      }
      */
      this.error = "";
      (connection as any)[attribute] = value;

      this.saveConnection(connection);
    } else if (value) {
      this.error = "Please select a variable with the type 'Array'.";
      (connection as any)[attribute] = "";
      this.saveConnection(connection);
    }
  }

  contains(parent: Element, child: Element) {
    return parent !== child && parent.contains(child);
  }

  saveConnection(connection: ListConnectionModel) {
    const connectionAttribute = "list";
    let element: ElementModel = {
      elementQueryString: "",
      connections: {
        onClick: [],
        onInput: [],
        visibility: { condition: "" },
        style: [],
        class: [],
        list: { value: "" },
        value: { value: "" },
        text: { value: "", type: "text" },
        attributes: [],
        parameters: [],
      },
    };
    if (this.element) {
      element = this.element;
    } else {
      element.elementQueryString = this.selectedElementQuery;
    }
    element.connections[connectionAttribute] = connection;
    if (Configuration.isElementEmpty(element)) this.$store.commit("DELETE_ELEMENT", element);
    else this.$store.commit("SET_ELEMENT", element);
  }

  get connection(): ListConnectionModel {
    if (this.element && this.element.connections?.list) {
      return this.element.connections.list;
    } else {
      return { value: "" };
    }
  }

  get element(): ElementModel | undefined | null {
    const DOMElement = document.querySelector(this.selectedElementQuery);
    return Page.domElemementsConfig()?.find((element: any) => element.DOMElement === DOMElement)
      ?.element;
  }

  get selectedElementQuery(): string {
    const element = this.$store.state.element.selected;
    if (element && element.queryString) return element.queryString;
    else return "";
  }
}
