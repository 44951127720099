











import { Component, Vue, Watch } from "vue-property-decorator";

const Props = Vue.extend({
  props: {
    height: {
      type: Number,
      default: 120,
    },
    color: {
      type: String,
      default: "#fff",
    },
  },
});

@Component({
  components: {},
})
export default class LoadingSpinner extends Props {}
