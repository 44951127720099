import { CookieModel } from "@/models/Configuration";
import ResponseModel from "@/models/Response";
import store from "@/store";
import element from "./element";
import Page from "./page";

const Value = {
  evaluateInputAll: (
    value: Array<any>,
    elementContext?: Element,
    array?: Array<any>,
    arrayIndex?: number
  ): any => {
    // Replace array values of list children with the correct (indexed) attribute value
    if (elementContext !== undefined) {
      ifInArrayGetIndexedValue(value, elementContext);
    }

    // Replace array value of provided array with the correct (indexed) attribute value
    if (Array.isArray(array) && array.length !== 0 && arrayIndex !== undefined) {
      // Element is in array, get index
      const listIndex = arrayIndex;
      // Get original list array
      const arrayValue = array;
      // Test if value is array value
      value.forEach((valueItem: any, index: number) => {
        if (valueItem.type === "attribute") {
          const path = valueItem.name.split(".");
          const lastIndexOfArrayInPath = path.lastIndexOf("[]");
          if (lastIndexOfArrayInPath < path.length - 1) {
            // Check if found attribute exists in array
            if (Array.isArray(arrayValue) && arrayValue.length > 0) {
              // Replace value (variable of index) with value (text-input)
              value[index] = {
                name: "text",
                type: "editable",
                value: arrayValue[listIndex][path[lastIndexOfArrayInPath + 1]],
                path: "",
              };
            }
          }
        }
      });
    }
    try {
      return evaluateBlocks(value);
    } catch (error) {
      return error;
    }
  },
  /**
   * Gets the value of any data attribute
   * @param path String with pattern [requestId | 'params' | 'fields' | 'variables' | 'cookies' | 'user' ].[(flattened)key]
   **/
  getValue: (path: string): any => {
    // Determine type
    const firstPart = path.split(".")[0];
    const secondPart = path.split(".")[1];
    if (
      firstPart === "params" ||
      firstPart === "fields" ||
      firstPart === "variables" ||
      firstPart === "cookies"
    ) {
      // firstPart = type of data
      if (firstPart === "params") {
        const parameters = Page.get()?.parameters;
        const parameter = parameters?.find((param) => param.name === secondPart);
        if (!parameter) return undefined;
        const dataParameter = store.state.data.params.find(
          (param: any) => param.id === parameter.id
        );
        return dataParameter?.value;
      } else if (firstPart === "cookies") {
        const cookies: CookieModel[] = store.state.configuration.cookies;
        const cookie = cookies?.find((cookie) => cookie.name === secondPart);
        if (!cookie) return undefined;
        const dataCookie = store.state.data.cookies.find(
          (cookieD: any) => cookieD.id === cookie.id
        );
        return dataCookie?.value;
      } else if (firstPart === "variables") {
        const variables = Page.get()?.variables;
        const variable = variables?.find((variable) => variable.name === secondPart);
        if (!variable) return undefined;
        const dataVariable = store.state.data.variables.find(
          (variableD: any) => variableD.id === variable.id
        );
        return dataVariable?.value;
      } else if (firstPart === "fields") {
        const field = store.state.data.fields.find(
          (field: any) => field.queryString === secondPart
        );
        return field?.value;
      }
      const data = store.state.data[firstPart];
      return data[secondPart];
    } else {
      // Set request data (base data to be flattened)
      let requestData: any = {};
      if (firstPart === "user") requestData = store.state.data.user;
      else if (firstPart === "login") requestData = store.state.data.login;
      else if (firstPart === "requestPasswordReset")
        requestData = store.state.data.requestPasswordReset;
      else if (firstPart === "resetPassword") requestData = store.state.data.resetPassword;
      else {
        // Is request type
        const requestsData = store.state.data.requests;
        requestData = requestsData.find(
          (request: { id: string; value: ResponseModel }) => request.id === firstPart
        )?.value;
      }
      if (!requestData) return undefined;
      if (secondPart === "state") {
        // Is state request value
        return requestData[path.split(".")[2]];
      } else if (secondPart === "attribute") {
        // Is attribute request value
        const requestDataFlatted = Value.flattenObject(requestData.data);
        const queryPath = path.split(".").slice(2).join(".");
        let result: any = "";
        if (queryPath === "[array]") result = requestData.data;
        else result = requestDataFlatted && requestDataFlatted[queryPath];
        if (Value.getTypeOf(result) === "Array") {
          const applyableSortingsAndFilters = getArrayFiltersAndSortingsForPath(path);
          if (applyableSortingsAndFilters.filter) {
            // Apply filter
            result = applyArrayFilter(result, applyableSortingsAndFilters.filter);
          }
          if (applyableSortingsAndFilters.sorting) {
            // Apply sortings
            result = applyArraySorting(result, applyableSortingsAndFilters.sorting);
          }
        }
        return result;
      }
    }
  },
  /**
   * https://stackoverflow.com/questions/44134212/best-way-to-flatten-js-object-keys-and-values-to-a-single-depth-array/59787588
   * @param object Object                 The object to flatten
   * @param prefix String (Optional)  The prefix to add before each key, also used for recursion
   **/
  flattenObject: (object: any, prefix = "", result: any = {}): { [index: string]: any } | null => {
    result = result || {};
    // Preserve empty objects and arrays, they are lost otherwise
    if (
      prefix &&
      typeof object === "object" &&
      object !== null &&
      Object.keys(object).length === 0
    ) {
      result[prefix] = Array.isArray(object) ? [] : {};
      return result;
    }
    if (Array.isArray(object)) {
      prefix = prefix ? prefix + "[]" : "[]";
      // ELSE
      let allAttributesInArray: Array<string> = [];
      object.forEach((item) => {
        // IF item = object, Get all attibute names
        if (typeof item === "object" && item !== null) {
          allAttributesInArray = allAttributesInArray.concat(Object.keys(item));
          allAttributesInArray = allAttributesInArray.filter(
            (item, pos) => allAttributesInArray.indexOf(item) === pos
          );
        } else {
          result[prefix + "item"] = item;
          return;
        }
      });

      // Merge first ocurrance of item in array to one object
      if (allAttributesInArray.length > 0) {
        const objectifiedArray: { [index: string]: any } = {};
        allAttributesInArray.forEach((attribute) => {
          // Find first occurrence
          object.forEach((item) => {
            if (item[attribute] && !objectifiedArray[attribute]) {
              objectifiedArray[attribute] = item[attribute];
              return;
            }
          });
        });
        Value.flattenObject(objectifiedArray, prefix, result);
      }
    } else {
      prefix = prefix ? prefix + "." : "";
      // Loop threw every attribute of the object
      for (const attributeName in object) {
        if (Object.prototype.hasOwnProperty.call(object, attributeName)) {
          // Push attribute and its value to result
          result[prefix + attributeName] = object[attributeName];
          if (typeof object[attributeName] === "object" && object[attributeName] !== null) {
            // Recursion on deeper objects
            Value.flattenObject(object[attributeName], prefix + attributeName, result);
          }
        }
      }
    }
    return result;
  },
  getTypeOf: (value: any): string => {
    let type: string = typeof value;
    if (Array.isArray(value)) type = "array";
    return type.charAt(0).toUpperCase() + type.slice(1);
  },
};

export default Value;

const ifInArrayGetIndexedValue = (value: any[], elementContext: Element): any[] => {
  const listNode = elementContext.closest("[wized---list-index]");
  // Determine if Element is in List
  if (listNode) {
    // Element is in array, get index
    const listIndex = Number(listNode.getAttribute("wized---list-index"));

    // Get original list array
    const arrayPath = listNode.getAttribute("wized---list-array-path");
    const arrayValue = Value.evaluateInputAll(
      JSON.parse(listNode.getAttribute("wized---list-value") || "")
    );
    value.forEach((valueItem: any, index: number) => {
      if (valueItem.type === "attribute") {
        // Strings, Numbers, Boolean
        value = getInArrayAttributeValue(
          valueItem,
          index,
          arrayValue,
          arrayPath || "",
          listIndex,
          value,
          listNode
        );
      } else if (valueItem.type === "object") {
        // Arrays & JSON objects
        value = getInArrayObjectValue(
          valueItem,
          index,
          arrayValue,
          arrayPath || "",
          listIndex,
          value,
          listNode
        );
      }
    });
  }
  return value;
};

const getInArrayAttributeValue = (
  valueItem: any,
  index: number,
  arrayValue: any,
  arrayPath: string,
  listIndex: number,
  value: any[],
  listNode: Element
): any[] => {
  // Test if value is array value
  valueItem.path = valueItem.path.replace("..", ".");
  valueItem.path = valueItem.path.replace("[].item", "");
  valueItem.path = valueItem.path.replace("[]item", "");
  valueItem.name = valueItem.name.replace("..", ".");
  valueItem.name = valueItem.name.replace("[].item", "");
  valueItem.name = valueItem.name.replace("[]item", "");

  if (valueItem.path.split(".")[0] !== arrayPath?.split(".")[0]) return value;
  const path = valueItem.path.replace(arrayPath + "[].", "").split(".");

  let lastIndexOfArrayInPath = -1;
  path.forEach((attribute: string, index: number) => {
    if (attribute.includes("[]")) lastIndexOfArrayInPath = index;
  });
  // Check if found attribute exists in array
  // arrayValue[listIndex][path[lastIndexOfArrayInPath + 1]] !== undefined
  if (
    lastIndexOfArrayInPath < path.length - 1 &&
    Array.isArray(arrayValue) &&
    arrayValue.length > 0
  ) {
    // Check if it is subarray
    let attributes: string[] = [];
    if (lastIndexOfArrayInPath === -1) lastIndexOfArrayInPath = 0;
    const relevantPathLength = path.length - lastIndexOfArrayInPath;
    for (let i = 0; i < relevantPathLength; i++) {
      attributes.push(path[lastIndexOfArrayInPath + i]?.replace("[]", ""));
    }
    attributes = attributes.filter((attribute: string) => attribute !== "");
    let newValue: any = arrayValue[listIndex];
    attributes.forEach((attribute: string) => {
      if (!newValue) {
        return value;
      }
      newValue = newValue[attribute];
      if (Array.isArray(newValue)) {
        newValue = newValue[0];
      }
    });

    // Replace value (variable of index) with value (text-input)
    value[index] = {
      name: "text",
      type: "editable",
      value: newValue,
      path: "",
    };
  }
  return value;
};

const getInArrayObjectValue = (
  valueItem: any,
  index: number,
  arrayValue: any,
  arrayPath: string,
  listIndex: number,
  value: any[],
  listNode: Element
): any[] => {
  // Return if there is no nested array
  if (!valueItem.path.includes("[]")) return value;

  // Find last array in path
  let lastIndexOfArrayInPath = -1;
  const path = valueItem.path.replace(arrayPath + "[].", "").split(".");
  path.forEach((attribute: string, index: number) => {
    if (attribute.includes("[]")) lastIndexOfArrayInPath = index;
  });

  if (
    lastIndexOfArrayInPath < path.length - 1 &&
    Array.isArray(arrayValue) &&
    arrayValue.length > 0
  ) {
    // Check if it is subarray
    const attributes: string[] = [];
    if (lastIndexOfArrayInPath === -1) lastIndexOfArrayInPath = 0;
    const relevantPathLength = path.length - lastIndexOfArrayInPath;
    for (let i = 0; i < relevantPathLength; i++) {
      attributes.push(path[lastIndexOfArrayInPath + i]?.replace("[]", ""));
    }
    let newValue: any = arrayValue[listIndex];
    attributes.forEach((attribute: string) => {
      if (!newValue) {
        return value;
      }
      if (attribute && newValue) newValue = newValue[attribute];
    });
    value[index] = {
      name: "text",
      type: "editable",
      value: newValue,
      path: "",
    };
  }

  // Return indexed value of array

  /*
  // Test if value is array value
  valueItem.path = valueItem.path.replace("..", ".");
  valueItem.path = valueItem.path.replace("[].item", "");
  valueItem.name = valueItem.name.replace("..", ".");
  valueItem.name = valueItem.name.replace("[].item", "");

  if (valueItem.path.split(".")[0] !== arrayPath?.split(".")[0]) return value;
  const path = valueItem.path.replace(arrayPath + "[].", "").split(".");

  let lastIndexOfArrayInPath = -1;
  path.forEach((attribute: string, index: number) => {
    if (attribute.includes("[]")) lastIndexOfArrayInPath = index;
  });
  // Check if found attribute exists in array
  // arrayValue[listIndex][path[lastIndexOfArrayInPath + 1]] !== undefined
  if (
    lastIndexOfArrayInPath < path.length - 1 &&
    Array.isArray(arrayValue) &&
    arrayValue.length > 0
  ) {
    // Check if it is subarray
    let attributes: string[] = [];
    if (lastIndexOfArrayInPath === -1) lastIndexOfArrayInPath = 0;
    const relevantPathLength = path.length - lastIndexOfArrayInPath;
    for (let i = 0; i < relevantPathLength; i++) {
      attributes.push(path[lastIndexOfArrayInPath + i]?.replace("[]", ""));
    }
    attributes = attributes.filter((attribute: string) => attribute !== "");
    let newValue: any = arrayValue[listIndex];
    attributes.forEach((attribute: string) => {
      if (!newValue) {
        return value;
      }
      newValue = newValue[attribute];
      if (Array.isArray(newValue)) {
        newValue = newValue[0];
      }
    });

    // Replace value (variable of index) with value (text-input)
    value[index] = {
      name: "text",
      type: "editable",
      value: newValue,
      path: "",
    };
  }

  */
  return value;
};

const evaluateBlock = (
  index: number,
  variables: {
    name: string;
    type:
      | "auth"
      | "object"
      | "attribute"
      | "custom"
      | "input"
      | "function"
      | "keyword"
      | "operator"
      | "variable"
      | "editable"
      | "state";
    value: string;
    path: string;
  }[],
  results: any[]
): any => {
  const variable = variables[index];
  let continueAtIndex = index + 1;
  if (variable.type === "function") {
    const pairs = functionPairs(variables);
    const pair = pairs.find((pair) => pair.opener === index || pair.closer === index);
    if (!pair) throw new Error("Evaluation error.");
    const semicolons = semicolonsOfFunction(pair, pairs, variables);
    if (variable.name === "if(") {
      const firstPart = variables.slice(pair.opener + 1, semicolons[0]);
      const resultOfFirstPart = evaluateBlocks(firstPart);
      const secondPart = variables.slice(semicolons[0] + 1, semicolons[1]);
      const resultOfSecondPart = evaluateBlocks(secondPart);
      const thirdPart = variables.slice(semicolons[1] + 1, pair.closer);
      const resultOfThirdPart = evaluateBlocks(thirdPart);
      if (resultOfFirstPart) results.push(resultOfSecondPart);
      else results.push(resultOfThirdPart);
    } else if (variable.name === "(") {
      const firstPart = variables.slice(pair.opener + 1, pair.closer);
      const resultOfFirstPart = evaluateBlocks(firstPart);
      results.push(resultOfFirstPart);
    } else if (variable.name === "includes(") {
      const firstPart = variables.slice(pair.opener + 1, semicolons[0]);
      const resultOfFirstPart = evaluateBlocks(firstPart);
      const secondPart = variables.slice(semicolons[0] + 1, pair.closer);
      const resultOfSecondPart = evaluateBlocks(secondPart);
      results.push(resultOfFirstPart.includes(resultOfSecondPart));
    } else if (variable.name === "toString(") {
      const firstPart = variables.slice(pair.opener + 1, pair.closer);
      const resultOfFirstPart = evaluateBlocks(firstPart);
      results.push(resultOfFirstPart ? resultOfFirstPart.toString() : "");
    } else if (variable.name === "toNumber(") {
      const firstPart = variables.slice(pair.opener + 1, pair.closer);
      const resultOfFirstPart = evaluateBlocks(firstPart);
      results.push(parseFloat(resultOfFirstPart));
    } else if (variable.name === "toBoolean(") {
      const firstPart = variables.slice(pair.opener + 1, pair.closer);
      const resultOfFirstPart = evaluateBlocks(firstPart);
      let boolean = true;
      if (resultOfFirstPart === "true") boolean = true;
      else if (resultOfFirstPart === "false") boolean = false;
      else if (resultOfFirstPart) boolean = Boolean(resultOfFirstPart);
      results.push(boolean);
    } else if (variable.name === "parseDate(") {
      const firstPart = variables.slice(pair.opener + 1, pair.closer);
      const resultOfFirstPart = evaluateBlocks(firstPart);
      results.push(Date.parse(resultOfFirstPart));
    } else if (variable.name === "formatDate(") {
      const firstPart = variables.slice(pair.opener + 1, semicolons[0]);
      const resultOfFirstPart = evaluateBlocks(firstPart);
      const secondPart = variables.slice(semicolons[0] + 1, pair.closer);
      const resultOfSecondPart = evaluateBlocks(secondPart);
      results.push(new Date(resultOfFirstPart).toLocaleString(resultOfSecondPart));
    } else if (variable.name === "invertBoolean(") {
      const firstPart = variables.slice(pair.opener + 1, pair.closer);
      const resultOfFirstPart = evaluateBlocks(firstPart);
      results.push(!resultOfFirstPart);
    } else if (variable.name === "addString(") {
      // Add string to Array or Comma-Separated-String
      const firstPart = variables.slice(pair.opener + 1, semicolons[0]);
      const resultOfFirstPart = evaluateBlocks(firstPart);
      const secondPart = variables.slice(semicolons[0] + 1, pair.closer);
      const resultOfSecondPart = evaluateBlocks(secondPart);
      if (Array.isArray(resultOfFirstPart) || !resultOfFirstPart) {
        let tempArray: any = [];
        if (Array.isArray(resultOfFirstPart)) tempArray = [...resultOfFirstPart];
        tempArray.push(resultOfSecondPart);
        results.push(tempArray);
      } else if (typeof resultOfFirstPart === "string") {
        const tempArray = resultOfFirstPart.split(",");
        tempArray.push(resultOfSecondPart);
        results.push(tempArray.join(","));
      }
    } else if (variable.name === "removeString(") {
      // Remove string to Array or Comma-Separated-String
      const firstPart = variables.slice(pair.opener + 1, semicolons[0]);
      const resultOfFirstPart = evaluateBlocks(firstPart);
      const secondPart = variables.slice(semicolons[0] + 1, pair.closer);
      const resultOfSecondPart = evaluateBlocks(secondPart);
      if (Array.isArray(resultOfFirstPart)) {
        const tempArray = [...resultOfFirstPart];
        const foundIndex = tempArray.indexOf(resultOfSecondPart);
        if (foundIndex !== -1) {
          tempArray.splice(foundIndex, 1);
          results.push(tempArray);
        } else results.push(tempArray);
      } else if (typeof resultOfFirstPart === "string") {
        const tempArray = resultOfFirstPart.split(",");
        const foundIndex = tempArray.indexOf(resultOfSecondPart);
        if (foundIndex !== -1) {
          tempArray.splice(foundIndex, 1);
          results.push(tempArray.join(","));
        } else results.push(resultOfFirstPart);
      }
    }
    continueAtIndex = pair.closer + 1;
  } else if (variable.type === "operator") {
    results.push(variable.name + "-PW---OPERATOR");
  } else {
    results.push(getValueOfVariable(variable));
  }
  if (continueAtIndex < variables.length) evaluateBlock(continueAtIndex, variables, results);
};

/**
 * Gets the value of logic attributes
 **/
const getValueOfVariable = (variable: {
  name: string;
  type:
    | "auth"
    | "object"
    | "attribute"
    | "custom"
    | "input"
    | "function"
    | "keyword"
    | "operator"
    | "variable"
    | "editable"
    | "state";
  value: string;
  path: string;
}) => {
  if (variable.type === "variable") {
    if (variable.name === "timestamp") return Math.round(Date.now());
    else throw new Error("Could not find value of variable: " + variable);
  } else if (variable.type === "editable") {
    if (variable.name === "text") return variable.value;
    else if (variable.name === "number") return Number(variable.value);
    else throw new Error("Could not find value of variable: " + variable);
  } else if (variable.type === "keyword") {
    if (variable.name === "true") return true;
    else if (variable.name === "false") return false;
    else if (variable.name === "empty") return "";
    else if (variable.name === "undefined") return undefined;
    else throw new Error("Could not find value of variable: " + variable);
  } else return Value.getValue(variable.path) === undefined ? "" : Value.getValue(variable.path);
};

const functionPairs = (
  variables: {
    name: string;
    type:
      | "auth"
      | "object"
      | "attribute"
      | "custom"
      | "input"
      | "function"
      | "keyword"
      | "operator"
      | "variable"
      | "editable"
      | "state";
    value: string;
    path: string;
  }[]
): Array<{ opener: number; closer: number }> => {
  // Get all varibales that are functions
  const functions: Array<{
    index: number;
    variable: {
      name: string;
      type:
        | "auth"
        | "object"
        | "attribute"
        | "custom"
        | "input"
        | "function"
        | "keyword"
        | "operator"
        | "variable"
        | "editable"
        | "state";
      value: string;
    };
  }> = [];
  variables.forEach((variable, index) => {
    if (variable.type === "function") functions.push({ index: index, variable: variable });
  });
  const openers: Array<number> = [];
  variables.forEach((variable, index) => {
    if (variable.type === "function" && variable.name.includes("(")) openers.push(index);
  });
  let closers: Array<number> = [];
  variables.forEach((variable, index) => {
    if (variable.type === "function" && variable.name === ")") closers.push(index);
  });
  closers = closers.reverse();
  const pairs: Array<{ opener: number; closer: number }> = [];
  if (openers.length !== closers.length)
    throw new Error("Invalid formula. One or more functions don't have a closing or opening tag.");
  else {
    // Evaluate Function Opening & Closing Pairs
    closers.reverse();
    closers.forEach((item) => {
      const _openers = [...openers];
      const openerIndex =
        _openers
          .concat(item)
          .sort((a, b) => a - b)
          .indexOf(item) - 1;
      pairs.push({
        opener: openers[openerIndex],
        closer: item,
      });
      openers.splice(openerIndex, 1);
    });
  }
  return pairs;
};

const semicolonsOfFunction = (
  pair: { opener: number; closer: number },
  pairs: Array<{ opener: number; closer: number }>,
  variables: {
    name: string;
    type:
      | "auth"
      | "object"
      | "attribute"
      | "custom"
      | "input"
      | "function"
      | "keyword"
      | "operator"
      | "variable"
      | "editable"
      | "state";
    value: string;
    path: string;
  }[]
): Array<number> => {
  const allSemicolons: Array<number> = [];
  // Slice variables to the portion of our pair
  const variableSlice = variables.slice(pair.opener, pair.closer + 1);
  variableSlice.forEach((variable, index) => {
    if (variable.type === "operator" && variable.name === ";") allSemicolons.push(index);
  });
  // Exclude all semicolons that live in other pairs
  const pairsExcludingThisPair = pairs.filter(
    (pairEx) => pairEx.opener !== pair.opener && pairEx.opener !== pair.closer
  );
  const functionSemicolons =
    pairs.length > 1
      ? allSemicolons.filter((semicolonIndex) => {
          let isInOtherPair = false;
          pairsExcludingThisPair.forEach((pair) => {
            if (semicolonIndex >= pair.opener && semicolonIndex <= pair.closer)
              isInOtherPair = true;
          });
          return !isInOtherPair;
        })
      : allSemicolons;
  // Correct index for slicing
  functionSemicolons.forEach((semicolonIndex, index) => (functionSemicolons[index] += pair.opener));
  return functionSemicolons;
};

const evaluateBlocks = (
  variables: Array<{
    name: string;
    type:
      | "auth"
      | "object"
      | "attribute"
      | "custom"
      | "input"
      | "function"
      | "keyword"
      | "operator"
      | "variable"
      | "editable"
      | "state";
    value: string;
    path: string;
  }>
): any => {
  try {
    const blocks: any[] = [];
    if (!variables || !Array.isArray(variables) || variables.length === 0) {
      return undefined;
    }
    evaluateBlock(0, variables, blocks);
    let result: any;
    if (blocks.length > 1) {
      const results: Array<any> = [];
      recursiveEvaluation(0, blocks, results);
      result = joinVariablesWithoutLoosingType(results);
    } else result = blocks[0];
    return result;
  } catch (error: any) {
    throw "ERROR: " + error.message;
  }
};

const recursiveEvaluation = (index: number, blocks: Array<any>, results: any[]): any => {
  let continueAtIndex = index + 1;
  if (typeof blocks[index] === "string" && blocks[index].includes("-PW---OPERATOR")) {
    const result = blocks[index].replace("-PW---OPERATOR", "");
    let operationResult: any;
    if (result === "!=") {
      operationResult = blocks[index - 1] != blocks[index + 1];
    } else if (result === "=") {
      operationResult = blocks[index - 1] == blocks[index + 1];
    } else if (
      blocks[index - 1] === undefined ||
      blocks[index - 1] === null ||
      blocks[index + 1] === undefined ||
      blocks[index + 1] === null
    )
      throw new Error(
        "'" +
          result +
          "' operation could not be performed. Either there is no variable before or behind the operator."
      );
    else {
      if (result === "<") {
        operationResult = blocks[index - 1] < blocks[index + 1];
      } else if (result === ">") {
        operationResult = blocks[index - 1] > blocks[index + 1];
      } else if (result === "<=") {
        operationResult = blocks[index - 1] <= blocks[index + 1];
      } else if (result === ">=") {
        operationResult = blocks[index - 1] >= blocks[index + 1];
      } else if (result === "and") {
        operationResult = blocks[index - 1] && blocks[index + 1];
      } else if (result === "or") {
        operationResult = blocks[index - 1] || blocks[index + 1];
      } else if (result === "+") {
        operationResult = blocks[index - 1] + blocks[index + 1];
      } else if (result === "-") {
        operationResult = blocks[index - 1] - blocks[index + 1];
      } else if (result === "*") {
        operationResult = blocks[index - 1] * blocks[index + 1];
      } else if (result === "/") {
        operationResult = blocks[index - 1] / blocks[index + 1];
      } else if (result === "mod") {
        operationResult = blocks[index - 1] % blocks[index + 1];
      }
    }
    results[index - 1] = operationResult;
    continueAtIndex = index + 2;
  } else {
    results.push(blocks[index]);
  }
  if (continueAtIndex < blocks.length) recursiveEvaluation(continueAtIndex, blocks, results);
};

const getArrayFiltersAndSortingsForPath = (
  path: string
): {
  sorting?: {
    arrayPath: string;
    sortings: Array<{ activeIf: string; attributeShortPath: string; direction: "desc" | "asc" }>;
  };
  filter?: { arrayPath: string; activeIf: string; condition: string };
} => {
  const requests = Page.get()?.requests;
  const result: {
    sorting?: {
      arrayPath: string;
      sortings: Array<{ activeIf: string; attributeShortPath: string; direction: "desc" | "asc" }>;
    };
    filter?: { arrayPath: string; activeIf: string; condition: string };
  } = {};
  if (!requests || requests.length === 0) return {};
  requests.forEach((request) => {
    if (request.arraySortings) {
      request.arraySortings.forEach((sorting) => {
        if (sorting.arrayPath === path) {
          result["sorting"] = sorting;
        }
      });
    }
    if (request.arrayFilters) {
      request.arrayFilters.forEach((filter) => {
        if (filter.arrayPath === path) {
          result["filter"] = filter;
        }
      });
    }
  });
  return result;
};

const applyArrayFilter = (
  value: Array<any>,
  arrayFilter: { arrayPath: string; activeIf: string; condition: string }
): Array<any> => {
  if (
    arrayFilter.activeIf &&
    arrayFilter.condition &&
    Value.evaluateInputAll(JSON.parse(arrayFilter.activeIf))
  ) {
    const result: Array<any> = [];
    value.forEach((item, index) => {
      if (Value.evaluateInputAll(JSON.parse(arrayFilter.condition), undefined, value, index)) {
        result.push(item);
      }
    });
    return result;
  } else return value;
};

const applyArraySorting = (
  value: Array<any>,
  arraySorting: {
    arrayPath: string;
    sortings: Array<{ activeIf: string; attributeShortPath: string; direction: "desc" | "asc" }>;
  }
): Array<any> => {
  arraySorting.sortings.reverse().forEach((sorting) => {
    if (
      sorting.activeIf &&
      sorting.attributeShortPath &&
      sorting.direction &&
      Value.evaluateInputAll(JSON.parse(sorting.activeIf))
    ) {
      value.sort((a: any, b: any) => {
        a = Value.flattenObject(a);
        b = Value.flattenObject(b);
        if (!a || !b) return 0;
        if (sorting.direction === "desc") {
          if (a[sorting.attributeShortPath] < b[sorting.attributeShortPath]) {
            return -1;
          }
          if (a[sorting.attributeShortPath] > b[sorting.attributeShortPath]) {
            return 1;
          }
        } else if (sorting.direction === "asc") {
          if (b[sorting.attributeShortPath] < a[sorting.attributeShortPath]) {
            return -1;
          }
          if (b[sorting.attributeShortPath] > a[sorting.attributeShortPath]) {
            return 1;
          }
        }
        return 0;
      });
    }
  });
  return value;
};

const joinVariablesWithoutLoosingType = (results: Array<any>): any => {
  const types: Array<string> = [];
  let result: any;
  results.forEach((result) => {
    if (!types.includes(result)) types.push(typeof result);
  });
  if (types.length === 1) {
    // All results are the same type -> retain type
    if (types[0] === "string") {
      result = results.join("");
    } else if (types[0] === "number") {
      result = 0;
      results.forEach((resultItem) => (result += resultItem));
    } else if (types[0] === "boolean") {
      result = results.reduce((total, bool) => total + bool, 0) === 1;
    } else if (types[0] === "object") {
      if (Array.isArray(result[0])) {
        result = [...results];
      } else {
        result = { ...results };
      }
    }
  } else if (types.length > 1) {
    // merge all results into string
    result = results.join(" ");
  }
  return result;
};

/**
 * //From: https://stackoverflow.com/questions/44134212/best-way-to-flatten-js-object-keys-and-values-to-a-single-depth-array/59787588
 * @param ob Object     The object to unflatten
 **/

/*
   unflattenObject: (ob: any) => {
    const result = {};
    for (const i in ob) {
      if (Object.prototype.hasOwnProperty.call(ob, i)) {
        const keys = i.match(/^\.+[^.]*|[^.]*\.+$|(?:\.{2,}|[^.])+(?:\.+$)?/g) as RegExpMatchArray; // Just a complicated regex to only match a single dot in the middle of the string
        keys.reduce((r: any, e: any, j: any) => {
          return r[e] || (r[e] = isNaN(Number(keys[j + 1])) ? (keys.length - 1 === j ? ob[i] : {}) : []);
        }, result);
      }
    }
    return result;
  },

  */
