var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"wrapper",staticClass:"pw---dropdown-wrapper",style:({ width: _vm.width + 'px', 'min-width': _vm.width + 'px' })},[(this.isOpen)?_c('div',{staticClass:"pw---full-page-blocker",on:{"click":function($event){return _vm.toggleOpen(false)}}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(this.isOpen),expression:"this.isOpen"}],ref:"dropdown",staticClass:"pw---dropdown-selections",style:({
      width: (_vm.openWidth < _vm.width ? _vm.width : _vm.openWidth) + 'px',
      'min-width': _vm.width + 'px',
    })},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"pw---input pw---light w-input",attrs:{"type":"text","placeholder":"Search"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}}),_c('div',{staticClass:"pw---seperator pw---top-down-5"}),(_vm.searchedSelectValueGroups.length === 0)?_c('div',{staticClass:"pw---dropdown-value",staticStyle:{"margin-top":"10px","margin-bottom":"10px"}},[_c('div',{staticClass:"pw---p-small pw---50"},[_vm._v("No options found.")])]):_vm._e(),_vm._l((_vm.searchedSelectValueGroups),function(valueGroup){return _c('div',{key:valueGroup.name,staticClass:"pw---dropdown-scroll"},[(_vm.selectValueGroups.length > 1)?_c('div',{staticClass:"pw---p-small-mono pw---50 pw---bottom-3"},[_vm._v(" "+_vm._s(valueGroup.name)+"} ")]):_vm._e(),_vm._l((valueGroup.values),function(value){return _c('div',{key:value.id,ref:'value_' + value.id,refInFor:true,staticClass:"pw---dropdown-option",on:{"click":function () {
            if (Boolean(_vm.values.find(function (valueSel) { return valueSel === value.id; }))) { _vm.unselect(value.id); }
            else { _vm.select(value.id); }
          }}},[_c('div',{staticClass:"pw---dropdown-value-icon",class:{
            'pw---opacity-0': !Boolean(_vm.values.find(function (valueSel) { return valueSel === value.id; })),
          }},[_c('img',{staticClass:"pw---icon-11",attrs:{"src":_vm.$config.configuratorUrl + 'images/checkmark.svg',"loading":"lazy","alt":""}})]),_c('div',{staticClass:"pw---dropdown-value"},[(_vm.type === 'variables')?_c('Variable'):_vm._e(),(_vm.type === 'default')?_c('div',{staticClass:"pw---p-small",staticStyle:{"text-overflow":"ellipsis","overflow":"hidden","white-space":"nowrap"}},[_vm._v(" "+_vm._s(value.name)+" ")]):_vm._e()],1)])}),(valueGroup.values.length === 0)?_c('div',{staticStyle:{"margin-bottom":"5px","border-radius":"3px","padding":"3px 7px 4px 7px","background-color":"#00000030"}},[_c('div',{staticClass:"pw---p-small pw---60"},[_vm._v("No items.")])]):_vm._e()],2)})],2),_c('div',{staticClass:"pw---dropdown-opener",on:{"click":function($event){return _vm.toggleOpen(true)}}},[_c('div',{staticClass:"pw---dropdown-value"},[_c('div',{staticClass:"pw---p-small",staticStyle:{"text-overflow":"ellipsis","overflow":"hidden","white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.value.name ? _vm.value.name + (_vm.values.length > 1 ? (" (+ " + (_vm.values.length - 1) + ")") : "") : "Select...")+" ")])]),_c('img',{staticClass:"pw---icon-8",attrs:{"src":_vm.$config.configuratorUrl + '/images/dropdown-icon.svg',"loading":"lazy","alt":""}})])])}
var staticRenderFns = []

export { render, staticRenderFns }