interface UserModel {
  uid?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  signUpFormCompleted?: boolean;
  signUpForm?: {
    companySizes?: string;
    role?: string;
    source: string;
    sourceOther?: string;
  };
  billing?: {
    country: string;
    billingInformation: string;
  };
  createdTimestamp?: number;
  updatedTimestamp?: number;
  stripe?: {
    customerId?: string;
    paymentMethod?: {
      id?: string;
      brand?: string;
      last4?: string;
      exp_month?: string;
      exp_year?: string;
    };
  };
}

const emptyUser: UserModel = {};

export { UserModel, emptyUser };
