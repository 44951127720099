
































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { PageModel } from "@/models/Configuration";
import Button from "@/components/ui/Button.vue";
import InputText from "@/components/ui/InputText.vue";
import OptionTitle from "@/components/ui/OptionTitle.vue";
import PopupHeading from "@/components/ui/PopupHeading.vue";
import LoadingSpinner from "@/components/misc/LoadingSpinner.vue";
import Configuration from "@/helpers/configuration";
import Cookie from "@/helpers/cookie";

@Component({
  components: {
    Button,
    InputText,
    OptionTitle,
    PopupHeading,
    LoadingSpinner,
  },
})
export default class Publish extends Vue {
  loading = false;
  published = false;
  lastPublishedTimestamp: number | null = 0;
  mainDestination = false;
  stagingDestination = true;

  async publish() {
    try {
      this.loading = true;
      const destination = { main: this.mainDestination, staging: this.stagingDestination };
      await Configuration.publish(destination);
      this.$store.commit("SET_PUBLISH", destination);
      this.lastPublishedTimestamp = Date.now();
      this.published = true;
      this.loading = false;
    } catch (e) {
      console.log(e);
      this.loading = false;
    }
  }

  mounted() {
    this.lastPublishedTimestamp = this.$store.state.project.lastPublishedTimestamp;
    if (!this.$store.state.project.domain.nameStaging) this.mainDestination = true;
  }

  logOut(): void {
    Cookie.set("pw-t", "", 0);
    const staging = this.$store.state.project.domain.nameStaging;
    const main = this.$store.state.project.domain.name;
    const pathName = window.location.pathname + window.location.search;
    window.location.href = `https://${staging + pathName}`;
  }

  close() {
    this.$store.commit("SET_POPUP_PUBLISH", { isOpen: false });
  }

  selectDestination(destination: string) {
    if (destination === "main") {
      this.mainDestination = !this.mainDestination;
    } else if (destination === "staging") {
      this.stagingDestination = !this.stagingDestination;
    }
  }

  get lastPublished(): string {
    if (!this.published && !this.lastPublishedTimestamp) return "Never";
    else
      return this.lastPublishedTimestamp
        ? new Date(this.lastPublishedTimestamp).toLocaleDateString() +
            " - " +
            new Date(this.lastPublishedTimestamp).toLocaleTimeString()
        : "";
  }
}
