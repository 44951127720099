












































































import { Component, Vue } from "vue-property-decorator";
import InputSelect from "@/components/ui/InputSelect.vue";
import InputAll from "@/components/ui/InputAll.vue";
import OptionTitle from "@/components/ui/OptionTitle.vue";
import Request from "@/helpers/request";
import LoadingSpinner from "@/components/misc/LoadingSpinner.vue";
import ResourceModel from "@/models/Resource";
import RequestModel from "@/models/Request";

@Component({
  components: {
    InputSelect,
    OptionTitle,
    LoadingSpinner,
    InputAll,
  },
})
export default class BaseTableView extends Vue {
  set(value: string, attributeName: "name" | "resourceId" | "type" | "tableId" | "baseId"): void {
    const request = this.request;

    // Reset field values on create or update request
    if (
      (request.type === "create" || request.type === "update") &&
      (attributeName === "tableId" || attributeName === "baseId")
    ) {
      request.fields = [];
    }

    request[attributeName] = value;
    this.$store.commit("SET_POPUP_REQUESTSETTINGS", {
      request: request,
      isOpen: true,
    });
  }

  mounted() {
    if (this.request.baseId) this.loadAirtableBaseStructure();
  }

  loadAirtableBaseStructure(): void {
    Request.loadAirtableBaseStructure(
      this.selectedResource.apiKey ? this.selectedResource.apiKey : "",
      this.request.baseId ? this.request.baseId : ""
    );
  }

  get basesOfSelectedResource(): Array<any> {
    return this.airtableBases.length > 0 ? [{ name: "Bases", values: this.airtableBases }] : [];
  }

  get tablesOfSelectedBase(): Array<any> {
    return this.airtableBaseStructure.length > 0
      ? [{ name: "Tables", values: this.airtableBaseStructure }]
      : [];
  }

  get fieldsOfSelectedTable(): Array<any> {
    const selectedTableId = this.request.tableId;
    const table = this.tablesOfSelectedBase[0]
      ? this.tablesOfSelectedBase[0].values.find((table: any) => table.id === selectedTableId)
      : {};
    return table && table.fields ? [{ name: "Views", values: table ? table.fields : [] }] : [];
  }

  get selectedResource(): ResourceModel {
    return this.$store.state.configuration.resources.find(
      (resource: ResourceModel) => resource.id === this.request.resourceId
    );
  }

  get request(): RequestModel {
    return JSON.parse(JSON.stringify(this.$store.state.popup.requestSettings.request));
  }

  get airtableBases() {
    return this.$store.state.popup.requestSettings.airtableBases;
  }

  get airtableBaseStructure() {
    return this.$store.state.popup.requestSettings.airtableBaseStructure;
  }
}
