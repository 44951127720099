

















import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class PWBadge extends Vue {
  hasWebflowBadge(): boolean {
    const badge: any = document.querySelector(".w-webflow-badge");
    if (badge) return true;
    else return false;
  }

  bottomPadding(): string {
    let result = "";
    if (this.hasWebflowBadge()) result = "48px";
    else result = "12px";
    return result;
  }
}
