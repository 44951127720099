


















































































import { Component, Vue } from "vue-property-decorator";
import Button from "@/components/ui/Button.vue";
import InputText from "@/components/ui/InputText.vue";
import InputSelect from "@/components/ui/InputSelect.vue";
import OptionTitle from "@/components/ui/OptionTitle.vue";
import Toggle from "@/components/ui/Toggle.vue";
import PopupHeading from "@/components/ui/PopupHeading.vue";
import Variable from "@/components/ui/Variable.vue";
import LoadingSpinner from "@/components/misc/LoadingSpinner.vue";
import Page from "@/helpers/page";
import Configuration from "@/helpers/configuration";
import Request from "@/helpers/request";
import { ParameterModel } from "@/models/Configuration";
import { UserModel } from "@/models/User";
import Parameter from "@/helpers/parameter";
import Authentication from "@/helpers/authentication";

@Component({
  components: {
    Button,
    InputText,
    InputSelect,
    OptionTitle,
    Toggle,
    PopupHeading,
    Variable,
    LoadingSpinner,
  },
})
export default class EditPreview extends Vue {
  hasValueForEveryParameter = false;
  users: Array<{ name: string; id: string }> = [];
  loadingUsers = false;
  loadedUsers = false;

  mounted() {
    this.hasValueForEveryParameter = this.hasParameterValueForEveryParameter();
    if (this.previewSettings.viewAsUser) this.loadUsers();
    // Prefill parameters if present
    this.parameters.forEach((parameter, index) => {
      const value = Parameter.get(parameter.name);
      if (value) this.setParameter(value, parameter.id, index);
    });
  }

  set(value: any, attributeName: "viewAsUser" | "userEmail"): void {
    const settings = this.previewSettings;
    (settings as any)[attributeName] = value;
    if (attributeName === "viewAsUser" && !value) Authentication.logout(true);
    this.$store.commit("SET_POPUP_PREVIEW", {
      isOpen: true,
      preview: settings,
    });
  }

  setParameter(value: any, id: string, index: number): void {
    const settings = this.previewSettings;
    settings.parameters[index] = { id: id, value: value };
    const parameterName = this.parameters.find((param) => param.id === id)?.name;
    this.hasValueForEveryParameter = this.hasParameterValueForEveryParameter();
    this.$store.commit("SET_POPUP_PREVIEW", {
      isOpen: true,
      preview: settings,
    });
  }

  close(): void {
    this.$store.commit("SET_POPUP_PREVIEW", {
      isOpen: false,
      preview: { viewAsUser: false, user: {}, parameters: [] },
    });
  }

  save(): void {
    this.$store.commit("SET_PREVIEW", {
      isFullScreen: false,
      settings: this.previewSettings,
    });
    if (this.parameters.length > 0)
      this.previewSettings.parameters.forEach((parameter) => {
        const parameterName = this.parameters.find((param) => param.id === parameter.id)?.name;
        Parameter.set(parameterName || "", parameter.value);
      });
    if (this.previewSettings.viewAsUser && this.previewSettings.userEmail)
      Request.loadPreviewUser();
    this.close();
  }

  previewParameterValue(id: string): string {
    const parameter = this.previewSettings.parameters.find(
      (parameter: { id: string; value: string }) => parameter.id === id
    );
    return parameter ? parameter.value : "";
  }

  async loadUsers(): Promise<void> {
    this.loadingUsers = true;
    this.users = await Configuration.loadPreviewUsers();
    this.loadingUsers = false;
    this.loadedUsers = true;
  }

  get previewSettings(): {
    viewAsUser: boolean;
    userEmail: string;
    isLoading: boolean;
    user: { [index: string]: any };
    parameters: Array<{ id: string; value: string }>;
  } {
    return this.$store.state.popup.previewSettings.preview;
  }

  get parameters(): Array<{ id: string; name: string }> {
    const page = Page.get();
    if (!page || !page.parameters) return [];
    else return page.parameters;
  }

  get isValidPreview(): boolean {
    const hasAllValues = this.hasValueForEveryParameter;
    if (this.previewSettings.viewAsUser === false) {
      return hasAllValues ? true : false;
    } else {
      if (this.previewSettings.userEmail) return this.hasValueForEveryParameter ? true : false;
      else return false;
    }
  }

  get formattedUsers() {
    return [{ name: "Users", values: this.users }];
  }

  hasParameterValueForEveryParameter(): boolean {
    let result = true;
    const allParameters = this.parameters;
    const setParameters = this.previewSettings.parameters;
    allParameters.forEach((param: any) => {
      let foundParameter = false;
      setParameters.forEach((paramSetting: any) => {
        if (paramSetting.id === param.id && paramSetting.value) foundParameter = true;
      });
      if (!foundParameter) result = false;
    });
    return result;
  }
}
