

































import { Component, Vue, Watch } from "vue-property-decorator";
import Reset from "@/components/ui/tooltips/Reset.vue";
import Info from "@/components/ui/tooltips/Info.vue";

const Props = Vue.extend({
  props: {
    text: {
      type: String,
      default: "",
    },
    infoText: {
      type: String,
      default: "",
    },
    isSet: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
  },
});

@Component({
  components: { Reset, Info },
})
export default class OptionTitle extends Props {
  tooltipOpen = false;
  hover = false;
  descriptionShown = false;

  reset(): void {
    this.tooltipOpen = false;
    this.hover = false;
    this.$emit("reset");
  }

  @Watch("tooltipOpen")
  turnOffDescription(value: boolean): void {
    if (!value) this.descriptionShown = false;
  }

  showDescriptions(): void {
    setTimeout(() => {
      if (this.hover) this.descriptionShown = true;
    }, 400);
  }
}
