













import { Component, Vue, Watch } from "vue-property-decorator";
import Button from "@/components/ui/Button.vue";
import envConfig from "@/envConfig";

const Props = Vue.extend({
  props: {
    headline: {
      type: String,
      default: "Upgrade to the Launch Plan",
    },
    description: {
      type: String,
      default: "Remove the branding badge and more.",
    },
  },
});

@Component({
  components: {
    Button,
  },
})
export default class UpgradeNotice extends Props {
  get isOnFreePlan(): boolean {
    return (
      this.$store.state.project.plan !== "launch" && this.$store.state.project.plan !== "business"
    );
  }

  openProjectSettings(): void {
    const url = envConfig.dashboardUrl + "project/" + this.$store.state.project.id;
    if (this.$store.state.save.isSaved) window.open(url, "_blank")?.focus();
    else if (confirm("Recent changes are not saved. Are you sure you want to go to the dashboard?"))
      window.open(url, "_blank")?.focus();
    else return;
  }
}
