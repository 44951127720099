




















import { Component, Vue, Watch } from "vue-property-decorator";

const Props = Vue.extend({
  props: {
    text: {
      type: String,
      default: "",
    },
    styles: {
      type: String,
      default: "",
      validator: (value: string) => {
        if (
          value === "" ||
          value === "blue" ||
          value === "super-light" ||
          value === "light-blue" ||
          value === "light" ||
          value === "small" ||
          value === "transparent"
        )
          return true;
        else return false;
      },
    },
    isActive: {
      type: Boolean,
      default: true,
    },
    isSquare: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: "",
    },
    iconSize: {
      type: Number,
      default: 10,
    },
    iconOpacity: {
      type: Number,
      default: 1,
    },
    isSubtle: {
      type: Boolean,
      default: false,
    },
    bigText: {
      type: Boolean,
      default: false,
    },
    isToggle: {
      type: Boolean,
      default: false,
    },
    additionalClass: {
      type: String,
      default: "",
    },
  },
});

@Component({
  components: {},
})
export default class Button extends Props {
  get classes(): string {
    let style = "";
    let styles = this.styles;
    switch (styles) {
      case "blue":
        style += "pw---blue";
        break;
      case "light":
        style += "pw---light";
        break;
      case "super-light":
        style += "pw---cancel";
        break;
      case "light-blue":
        style += "pw---save";
        break;
      case "small":
        style += "pw---small";
        break;
      case "transparent":
        style += "pw---no-back";
        break;
    }
    return (
      style +
      (this.isActive && !this.isLoading ? "" : " pw---inactive") +
      (this.isSubtle ? " pw---lesson" : "") +
      (this.isSquare ? " pw---small-square" : "") +
      (this.isToggle ? " pw---toggle" : "") +
      (this.additionalClass ? " " + this.additionalClass : "")
    );
  }

  everyClick(): void {
    this.$emit("everyClick");
    this.click();
  }

  click(): void {
    if (this.isActive && !this.isLoading) this.$emit("click");
  }
}
