










































































import { Component, Vue, Watch } from "vue-property-decorator";
import InputSelect from "@/components/ui/InputSelect.vue";
import InputAll from "@/components/ui/InputAll.vue";
import OptionTitle from "@/components/ui/OptionTitle.vue";
import BlockHeading from "@/components/ui/BlockHeading.vue";
import Button from "@/components/ui/Button.vue";
import RequestModel from "@/models/Request";

@Component({
  components: {
    InputSelect,
    InputAll,
    OptionTitle,
    BlockHeading,
    Button,
  },
})
export default class FilterSettings extends Vue {
  set(value: string, attributeName: "viewId"): void {
    const request = this.request;
    request[attributeName] = value;
    this.$store.commit("SET_POPUP_REQUESTSETTINGS", {
      request: request,
      isOpen: true,
    });
  }

  setArray(
    value: string,
    attributeName: "fieldId" | "condition" | "variable",
    index: number,
    arrayName: "filter"
  ): void {
    const request = this.request;
    let array = request[arrayName];
    if (!array) return;
    const item = array[index];
    item[attributeName] = value;
    this.$store.commit("SET_POPUP_REQUESTSETTINGS", {
      request: request,
      isOpen: true,
    });
  }

  addArrayItem(arrayName: "filter"): void {
    const request = this.request;
    let array = request[arrayName];
    if (!array) array = [];
    const item = { fieldId: "", condition: "", variable: "" };
    array.push(item);
    request[arrayName] = array;
    this.$store.commit("SET_POPUP_REQUESTSETTINGS", {
      request: request,
      isOpen: true,
    });
  }

  deleteArrayItem(index: number, arrayName: "filter"): void {
    const request = this.request;
    let array = request[arrayName];
    if (!array) return;
    array.splice(index, 1);
    request[arrayName] = array;
    this.$store.commit("SET_POPUP_REQUESTSETTINGS", {
      request: request,
      isOpen: true,
    });
  }

  get request(): RequestModel {
    return JSON.parse(JSON.stringify(this.$store.state.popup.requestSettings.request));
  }

  get filterConditions(): Array<any> {
    return [
      {
        name: "Conditions",
        values: [
          { id: "equals", name: "Equals" },
          { id: "not-equals", name: "Does not equal" },
          { id: "includes", name: "Includes" },
          { id: "not-includes", name: "Does not include" },
        ],
      },
    ];
  }

  get fieldsOfSelectedTable(): Array<any> {
    const selectedTableId = this.request.tableId;
    const table = this.tablesOfSelectedBase[0]
      ? this.tablesOfSelectedBase[0].values.find((table: any) => table.id === selectedTableId)
      : {};
    return table && table.fields ? [{ name: "Fields", values: table ? table.fields : [] }] : [];
  }

  get viewsOfSelectedTable(): Array<any> {
    const selectedTableId = this.request.tableId;
    const table = this.tablesOfSelectedBase[0]
      ? this.tablesOfSelectedBase[0].values.find((table: any) => table.id === selectedTableId)
      : {};
    return table && table.views ? [{ name: "Views", values: table ? table.views : [] }] : [];
  }

  get tablesOfSelectedBase(): Array<any> {
    return this.$store.state.popup.requestSettings.airtableBaseStructure.length > 0
      ? [
          {
            name: "Tables",
            values: this.$store.state.popup.requestSettings.airtableBaseStructure,
          },
        ]
      : [];
  }
}
