
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import LoadingSpinner from "@/components/misc/LoadingSpinner.vue";
import { ConfigurationModel } from "@/models/Configuration";
import RequestModel from "@/models/Request";
import Page from "@/helpers/page";
import Value from "@/helpers/value";
import ResourceModel from "@/models/Resource";

const Props = Vue.extend({
  props: {
    name: {
      type: String,
      default: "",
    },
  },
});

@Component({
  components: { LoadingSpinner },
})
export default class LogicTooltip extends Props {
  height = 0;
  width = 0;
  offsetLeft = 0;
  offsetTop = 0;

  mounted(): void {
    const distanceToWindowTop =
      window.pageYOffset + (this.$el.firstElementChild?.getBoundingClientRect().top || 0);
    if (distanceToWindowTop < 150 && this.$el && this.$el.firstElementChild) {
      (this.$el.firstElementChild as HTMLElement).style.bottom = "auto";
      (this.$el.firstElementChild as HTMLElement).style.top = "23px";
    }
    this.height = (this.$el as HTMLElement).offsetHeight;
    this.width = (this.$el as HTMLElement).offsetWidth;

    this.offsetLeft = this.$el.getBoundingClientRect().left + 46;
    this.offsetTop = this.$el.getBoundingClientRect().top;
  }
}
