import store from "@/store";
import Page from "@/helpers/page";
import RequestModel from "@/models/Request";

const History = {
  init: (): void => {
    store.commit("ADD_REQUEST_HISTORY", [
      { message: "Configurator initialized", color: "#ffffff80" },
    ]);
  },
  requestStart: (requestId: string): void => {
    if (
      requestId === "user" ||
      requestId === "login-magicLink" ||
      requestId === "login-emailPassword" ||
      requestId === "requestPasswordReset" ||
      requestId === "resetPassword"
    ) {
      const messages = [];
      if (requestId === "user") messages.push({ message: "Load user: ⏳ Start", color: "#ffffff" });
      if (requestId === "login-magicLink")
        messages.push({ message: "Login (Magic link): ⏳ Start", color: "#ffffff" });
      if (requestId === "login-emailPassword")
        messages.push({ message: "Login (Email & Password): ⏳ Start", color: "#ffffff" });
      if (requestId === "requestPasswordReset")
        messages.push({ message: "Request password reset link: ⏳ Start", color: "#ffffff" });
      if (requestId === "resetPassword")
        messages.push({ message: "Reset password: ⏳ Start", color: "#ffffff" });
      store.commit("ADD_REQUEST_HISTORY", messages);
    } else {
      const request = Page.get()?.requests.find(
        (request: RequestModel) => request.id === requestId
      );
      const messages = [{ message: request && request.name + ": ⏳ Start", color: "#ffffff" }];
      store.commit("ADD_REQUEST_HISTORY", messages);
    }
  },
  requestEnd: (response: any, responseParsed: any, requestId: string): void => {
    let messages: { message: string; color: string }[] = [];
    if (
      requestId === "user" ||
      requestId === "login-magicLink" ||
      requestId === "login-emailPassword" ||
      requestId === "requestPasswordReset" ||
      requestId === "resetPassword"
    ) {
      if (requestId === "user") messages.push({ message: "Load user: 🏁 End", color: "#ffffff" });
      if (requestId === "login-magicLink")
        messages.push({ message: "Login (Magic link): 🏁 End", color: "#ffffff" });
      if (requestId === "login-emailPassword")
        messages.push({ message: "Login (Email & Password): 🏁 End", color: "#ffffff" });
      if (requestId === "requestPasswordReset")
        messages.push({ message: "Request password reset link: 🏁 End", color: "#ffffff" });
      if (requestId === "resetPassword")
        messages.push({ message: "Reset password: 🏁 End", color: "#ffffff" });
      messages.push({
        message: "Response code: " + response.status,
        color: "#ffffff80",
      });
    } else {
      const request = Page.get()?.requests.find(
        (request: RequestModel) => request.id === requestId
      );
      messages = [
        { message: (request && request.name ? request.name : "") + ": 🏁 End", color: "#ffffff" },
        {
          message: "Response code: " + response.status,
          color: "#ffffff80",
        },
      ];
    }
    if (responseParsed.error)
      messages.push({
        message: "Error message: " + responseParsed.error,
        color: "#FF715D",
      });
    store.commit("ADD_REQUEST_HISTORY", messages);
  },
};

export default History;
