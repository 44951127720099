













import { Component, Vue, Watch } from "vue-property-decorator";
import LoadingSpinner from "@/components/misc/LoadingSpinner.vue";
import { ConfigurationModel } from "@/models/Configuration";

const Props = Vue.extend({
  props: {
    infoText: {
      type: String,
      default: "",
    },
    infoHeading: {
      type: String,
      default: "",
    },
    isElement: {
      type: Boolean,
      default: false,
    },
  },
});

@Component({
  components: { LoadingSpinner },
})
export default class Info extends Props {
  mounted(): void {
    const distanceToWindowTop = window.pageYOffset + this.$el.getBoundingClientRect().top;
    if (distanceToWindowTop < 100) {
      (this.$el as HTMLElement).style.bottom = "auto";
      (this.$el as HTMLElement).style.top = "23px";
    }
  }
}
