











































import { Component, Vue, Watch } from "vue-property-decorator";
import OptionTitle from "@/components/ui/OptionTitle.vue";
import BlockHeading from "@/components/ui/BlockHeading.vue";
import InputAll from "@/components/ui/InputAll.vue";
import InputSelect from "@/components/ui/InputSelect.vue";
import { ElementModel, PageModel, TextConnectionModel } from "@/models/Configuration";
import Page from "@/helpers/page";
import RequestModel from "@/models/Request";
import Configuration from "@/helpers/configuration";

@Component({
  components: {
    OptionTitle,
    BlockHeading,
    InputAll,
    InputSelect,
  },
})
export default class TextBlock extends Vue {
  set(value: any, attribute: string): void {
    const connection = this.connection;
    (connection as any)[attribute] = value;
    this.saveConnection(connection);
  }

  saveConnection(connection: TextConnectionModel) {
    const connectionAttribute = "text";
    let element: ElementModel = {
      elementQueryString: "",
      connections: {
        onClick: [],
        onInput: [],
        visibility: { condition: "" },
        style: [],
        class: [],
        list: { value: "" },
        value: { value: "" },
        text: { value: "", type: "text" },
        attributes: [],
        parameters: [],
      },
    };
    if (this.element) {
      element = this.element;
    } else {
      element.elementQueryString = this.selectedElementQuery;
    }
    element.connections[connectionAttribute] = connection;
    if (Configuration.isElementEmpty(element)) this.$store.commit("DELETE_ELEMENT", element);
    else this.$store.commit("SET_ELEMENT", element);
  }

  get connection(): TextConnectionModel {
    if (this.element && this.element.connections?.text) {
      return this.element.connections.text;
    } else {
      return { value: "", type: "text" };
    }
  }

  get element(): ElementModel | undefined | null {
    const DOMElement = document.querySelector(this.selectedElementQuery);
    return Page.domElemementsConfig()?.find((element: any) => element.DOMElement === DOMElement)
      ?.element;
  }

  get selectedElementQuery(): string {
    const element = this.$store.state.element.selected;
    if (element && element.queryString) return element.queryString;
    else return "";
  }

  get fieldConfiguration() {
    return {
      types: [
        {
          name: "Types",
          values: [
            { id: "text", name: "Text" },
            { id: "html", name: "HTML" },
            { id: "markdown", name: "Markdown" },
          ],
        },
      ],
    };
  }
}
