import store from "@/store";
import env from "@/envConfig";
import Token from "@/helpers/token";
import { reportError } from "@/helpers/error-handler";
import RequestModel from "@/models/Request";
import ResourceModel from "@/models/Resource";
import Page from "@/helpers/page";
import History from "@/helpers/history";
import Value from "@/helpers/value";
import { loadStripe } from "@stripe/stripe-js";
import passwordValidator from "password-validator";
import Authentication from "./authentication";
import Cookie from "./cookie";
import ResponseModel from "@/models/Response";
import API from "./api";
import { PageModel } from "@/models/Configuration";
import emailValidator from "email-validator";

const Request = {
  loadAirtableBases: async (apiKey: string): Promise<void> => {
    const token = await Token.getAdminToken();
    store.commit("SET_POPUP_REQUESTSETTINGS_AIRTABLE", {
      airtableBases: store.state.popup.requestSettings.airtableBases,
      airtableBaseStructure: store.state.popup.requestSettings.airtableBaseStructure,
      airtableBasesLoading: true,
      airtableBaseStructureLoading: false,
    });
    const response = await fetch(`${env.serverUrl}v1/configurator/airtableBases`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ apiKey }),
    }).catch((error) => {
      throw new Error(error.message);
    });
    if (response.status === 403) {
      // Log out user
      store.commit("SET_ADMIN_USER", {});
    } else if (response.status !== 200) {
      store.commit("SET_POPUP_REQUESTSETTINGS_AIRTABLE", {
        airtableBases: [],
        airtableBaseStructure: store.state.popup.requestSettings.airtableBaseStructure,
        airtableBasesLoading: false,
        airtableBaseStructureLoading: false,
      });
      reportError(
        "11000",
        `Server Error. Code: ${response.status}. Could not load airtable bases.`
      );
    }
    const responseParsed = await response.json();
    store.commit("SET_POPUP_REQUESTSETTINGS_AIRTABLE", {
      airtableBases: responseParsed.bases,
      airtableBaseStructure: store.state.popup.requestSettings.airtableBaseStructure,
      airtableBasesLoading: false,
      airtableBaseStructureLoading: false,
    });
  },
  loadAirtableBaseStructure: async (apiKey: string, baseId: string): Promise<void> => {
    const token = await Token.getAdminToken();
    store.commit("SET_POPUP_REQUESTSETTINGS_AIRTABLE", {
      airtableBases: store.state.popup.requestSettings.airtableBases,
      airtableBaseStructure: store.state.popup.requestSettings.airtableBaseStructure,
      airtableBasesLoading: false,
      airtableBaseStructureLoading: true,
    });
    const response = await fetch(`${env.serverUrl}v1/configurator/airtableBaseStructure`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ apiKey, baseId }),
    }).catch((error) => {
      throw new Error(error.message);
    });
    if (response.status === 403) {
      // Log out user
      store.commit("SET_ADMIN_USER", {});
    } else if (response.status !== 200) {
      store.commit("SET_POPUP_REQUESTSETTINGS_AIRTABLE", {
        airtableBases: store.state.popup.requestSettings.airtableBases,
        airtableBaseStructure: store.state.popup.requestSettings.airtableBaseStructure,
        airtableBasesLoading: false,
        airtableBaseStructureLoading: true,
      });
      reportError(
        "11001",
        `Server Error. Code: ${response.status}. Could not load airtable structure.`
      );
    }
    const responseParsed = await response.json();
    store.commit("SET_POPUP_REQUESTSETTINGS_AIRTABLE", {
      airtableBases: store.state.popup.requestSettings.airtableBases,
      airtableBaseStructure: responseParsed.tables,
      airtableBasesLoading: false,
      airtableBaseStructureLoading: false,
    });
  },
  loadResourceProducts: async (privateKey: string): Promise<void> => {
    const token = await Token.getAdminToken();
    store.commit("SET_POPUP_REQUESTSETTINGS_STRIPE", {
      resourceProducts: store.state.popup.requestSettings.airtableBases,
      resourceProductsLoading: true,
    });
    const response = await fetch(`${env.serverUrl}v1/configurator/products`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ privateKey }),
    });
    const responseParsed = await response.json();
    if (response.status === 403) {
      // Log out user
      store.commit("SET_ADMIN_USER", {});
    } else if (response.status !== 200) {
      store.commit("SET_POPUP_REQUESTSETTINGS_STRIPE", {
        resourceProducts: [],
        resourceTaxes: [],
        resourceProductsLoading: false,
      });
      reportError("11002", `Server Error. Code: ${response.status}. Could not load products.`);
      return;
    }
    store.commit("SET_POPUP_REQUESTSETTINGS_STRIPE", {
      resourceProducts: responseParsed.products,
      resourceTaxes: responseParsed.taxes,
      resourceProductsLoading: false,
    });
  },
  isValid: async (request: RequestModel): Promise<boolean> => {
    const errors: Array<string> = [];
    if (!request.name) {
      errors.push("Name mising");
    }

    if (!request.resourceId) {
      errors.push("Select a resource.");
    }

    if (!request.type && request.resourceId !== "file-upload") {
      errors.push("Select a request type");
    }

    const resources: any = [];
    store.state.configuration.resources.forEach((resource: any) => {
      resources.push({ id: resource.id, name: resource.name });
    });

    if (request.resourceId !== "file-upload") {
      const resource = store.state.configuration.resources.find(
        (resource: ResourceModel) => resource.id === request.resourceId
      );

      if (resource.type === "airtable") {
        if (!request.baseId) {
          errors.push("Select a base");
        }
        if (!request.tableId) {
          errors.push("Select a table");
        }
        if (
          (request.type === "get" || request.type === "update" || request.type === "delete") &&
          (!request.recordId || request.recordId === "[]")
        )
          errors.push("Enter a record id");

        if (
          request.type === "list" &&
          request.maxRecords &&
          (request.maxRecords > 99 || request.maxRecords < 1)
        ) {
          errors.push("Number of loaded records needs to be between 1 and 99");
        }

        if (request.filter && request.filter.length > 0) {
          request.filter.every(
            (filter: { fieldId: string; condition: string; variable: string }) => {
              if (
                !filter.fieldId ||
                !filter.condition ||
                !filter.variable ||
                filter.variable === "[]"
              ) {
                errors.push(
                  "The 'Include record if' statement is not complete for at least one filter"
                );
                return false;
              } else return true;
            }
          );
        }

        if (request.sort && request.sort.length > 0) {
          request.sort.every((sort: { fieldId: string; direction: string }) => {
            if (!sort.fieldId || !sort.direction) {
              errors.push("The 'Sort by' statement is not complete for at least one sorting");
              return false;
            } else return true;
          });
        }
      }

      if (resource.type === "stripe") {
        if (request.type === "checkout") {
          if (!request.successUrl || request.successUrl === "[]") {
            errors.push("Success url missing");
          }
          if (!request.cancelUrl || request.cancelUrl === "[]") {
            errors.push("Cancel url missing");
          }
          if (request.items && request.items.length === 0) {
            errors.push("Select at lest one item for checkout");
          }
          if (request.items && request.items.length > 0) {
            request.items.every(
              (item: { productId: string; priceId: string; quantity: string }) => {
                if (
                  !item.productId ||
                  !item.priceId ||
                  !item.quantity ||
                  Number(item.quantity) < 1
                ) {
                  errors.push(
                    "Please select a product, price id and quantity for every item you want to sell in the checkout."
                  );
                  return false;
                } else return true;
              }
            );
          }
        }
      }

      if (resource.type === "rest") {
        if (!request.endpoint) {
          errors.push("Please enter the endpoint path for your REST request");
        }
        if (request.params && request.params.length > 0) {
          request.params.every((attribute: { key: string; value: string }) => {
            if (
              !attribute.key ||
              attribute.key === "[]" ||
              !attribute.value ||
              attribute.value === "[]"
            ) {
              errors.push("Make sure to set the key and value for every url parameter");
              return false;
            } else return true;
          });
        }
        if (request.headers && request.headers.length > 0) {
          request.headers.every((attribute: { key: string; value: string }) => {
            if (
              !attribute.key ||
              attribute.key === "[]" ||
              !attribute.value ||
              attribute.value === "[]"
            ) {
              errors.push("Make sure to set the key and value for every header");
              return false;
            } else return true;
          });
        }
        if (
          request.contentType === "multipart/form-data" &&
          request.bodyForm &&
          request.bodyForm.length > 0
        ) {
          request.bodyForm.every((attribute: { key: string; value: string }) => {
            if (
              !attribute.key ||
              attribute.key === "[]" ||
              !attribute.value ||
              attribute.value === "[]"
            ) {
              errors.push("Make sure to set the key and value for every body attribute");
              return false;
            } else return true;
          });
        }
      }
    }
    if (errors.length > 0) throw errors;

    return true;
  },
  perform: async (
    requestId: string,
    requestCallChain?: Array<string>,
    elementContext?: Element
  ): Promise<void> => {
    try {
      const request: RequestModel | undefined = Page.get()?.requests.find(
        (request: RequestModel) => request.id === requestId
      );
      if (!request) throw new Error("Request not found.");

      if (request.resourceId === "file-upload") {
        store.commit("TOGGLE_UPLOAD", { id: request.id, visible: true });
        return;
      }

      beforeCreateUserAction(request);

      const resource: ResourceModel = store.state.configuration.resources.find(
        (resource: ResourceModel) => resource.id === request?.resourceId
      );
      const oldDataValue = store.state.data.requests.find(
        (request: { id: string; value: ResponseModel }) => request.id === requestId
      )?.value?.data;

      store.commit("SET_DATA_TYPE_VALUE", {
        value: {
          id: requestId,
          value: {
            isRequesting: true,
            hasRequested: false,
            statusCode: 0,
            errorMessage: "",
            data: oldDataValue ? oldDataValue : {},
          },
        },
        type: "requests",
      });
      History.requestStart(requestId);

      // Get data dump to attach on request
      const data = getDataDump(elementContext);

      // Request run request from server
      const authToken = Cookie.get("w---at");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      };
      const response = await fetch(`${env.serverUrl}v1/request`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          projectId: store.state.project.id,
          requestId: requestId,
          pageId: Page.get()?.id,
          data: data,
          href: window.location.href,
        }),
      });

      if (response.status === 200) {
        // Reset input fields
        resetInputFields(request);
      }
      // Set request response to data
      const responseParsed = await response.json();
      store.commit("SET_DATA_TYPE_VALUE", {
        value: {
          id: requestId,
          value: {
            isRequesting: false,
            hasRequested: true,
            statusCode: response.status,
            errorMessage: responseParsed.error || "",
            data: responseParsed,
          },
        },
        type: "requests",
      });
      History.requestEnd(response, responseParsed, requestId);
      if (response.status !== 200 && !store.state.user.loggedIn) {
        reportError(
          "12002",
          "Error " + response.status + ": " + responseParsed.error,
          responseParsed.error
        );
      }

      // Log in & Redirect after creating user
      afterCreateUserAction(request, response, responseParsed);

      // Run after request actions
      Request.afterRequestActions(request, elementContext);

      // Execute Stripe front-end request after creating sessionId
      if (resource?.type === "stripe") {
        // Run stripe request
        // Make sure to call `loadStripe` outside of a component’s render to avoid
        // recreating the `Stripe` object on every render.
        const stripePublicKey = resource.publicKey || "";
        const stripePromise = loadStripe(stripePublicKey);
        const stripe = await stripePromise;
        if (!stripe) return;
        // When the customer clicks on the button, redirect them to Checkout or Customer Portal
        if (request?.type === "checkout") {
          await stripe.redirectToCheckout({
            sessionId: responseParsed.sessionId,
          });
        } else if (request?.type === "customer-portal") {
          window?.open(responseParsed.url, "_blank")?.focus();
        }
      }

      // Call chained requests
      if (!requestCallChain) requestCallChain = [requestId];
      else requestCallChain.push(requestId);
      checkExecutionConditionsOfEveryRequest(requestCallChain);
    } catch (error) {
      console.log(error);
      reportError(
        "12000",
        "Error on request: " +
          Page.get()?.requests.find((request: RequestModel) => request.id === requestId)?.name,
        error
      );
      store.commit("SET_DATA_TYPE_VALUE", {
        value: {
          id: requestId,
          value: {
            isRequesting: false,
            hasRequested: true,
            statusCode: 500,
            errorMessage: error,
            data: {},
          },
        },
        type: "requests",
      });
    }
  },
  loadPreviewUser: async (): Promise<void> => {
    try {
      if (
        !store.state.configuration.settings.login.isEnabled ||
        !store.state.configuration.preview.settings.viewAsUser ||
        !store.state.configuration.preview.settings.userEmail
      )
        return;
      const projectId = store.state.project.id;
      const token = await Token.getAdminToken();
      const userEmail = store.state.configuration.preview.settings.userEmail;
      const response = await fetch(
        `${env.serverUrl}v1/project/preview/user-token?projectId=${projectId}&email=${userEmail}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      ).catch((error) => {
        throw new Error(error.message);
      });
      const responseParsed = await response.json();
      const userAccessToken = responseParsed.accessToken;
      Cookie.set("w---at", userAccessToken, 30);
      Authentication.loadUser(userAccessToken);
    } catch (error) {
      console.log(error);
      reportError("12001", "Server error while loading user.", error);
    }
  },
  performInitialRequests: async (): Promise<void> => {
    const requests = Page.get()?.requests;
    const asyncRequests: Array<Promise<void>> = [];
    requests?.forEach(async (request) => {
      if (request.executeOnPageLoad) asyncRequests.push(Request.perform(request.id));
    });
    await Promise.all(asyncRequests);
    if (
      store.state.user.loggedIn &&
      store.state.configuration.preview.settings.viewAsUser &&
      store.state.configuration.preview.settings.userEmail
    ) {
      Request.loadPreviewUser();
    }
    // Trigger API loaded
    API.trigger.loaded();
  },
  afterRequestActions: async (
    request: RequestModel,
    element?: Element,
    requestCallChain?: Array<string>
  ) => {
    if (request.actions && request.actions.length > 0)
      for await (const action of request.actions) {
        if (
          action.conditionalAction &&
          !Value.evaluateInputAll(JSON.parse(action.condition || "[]"))
        )
          continue;
        if (action.action === "request" && action.requestId) {
          // Call chained requests
          if (!requestCallChain) requestCallChain = [request.id];
          else requestCallChain.push(request.id);
          if (requestCallChain.includes(action.requestId)) continue;
          await Request.perform(action.requestId, requestCallChain, element);
        } else if (action.action === "navigate" && action.url) {
          location.href = Value.evaluateInputAll(JSON.parse(action.url || ""));
        } else if (action.action === "setVariable" && action.variableId && action.value) {
          store.commit("SET_DATA_TYPE_VALUE", {
            type: "variables",
            value: {
              id: action.variableId,
              value: Value.evaluateInputAll(JSON.parse(action.value)),
            },
          });
        } else if (
          action.action === "setCookie" &&
          action.cookieId &&
          action.value &&
          action.cookieLifetime
        ) {
          let expirationTimeInDays = 1;
          if (action.cookieLifetime === "1h") expirationTimeInDays = 1 / 24;
          else if (action.cookieLifetime === "1d") expirationTimeInDays = 1;
          else if (action.cookieLifetime === "1w") expirationTimeInDays = 7;
          else if (action.cookieLifetime === "1m") expirationTimeInDays = 31;
          else if (action.cookieLifetime === "3m") expirationTimeInDays = 31 * 3;
          Cookie.set(
            "w-c---" + action.cookieId,
            Value.evaluateInputAll(JSON.parse(action.value)),
            expirationTimeInDays
          );
          store.commit("SET_DATA_TYPE_VALUE", {
            type: "cookies",
            value: {
              id: action.cookieId,
              value: Value.evaluateInputAll(JSON.parse(action.value)),
            },
          });
        }
      }
    API.trigger.loadedRequest(request.id);
  },
};

export default Request;

const checkExecutionConditionsOfEveryRequest = (requestCallChain: Array<string>): void => {
  const internalRequestCallChain: Array<string> = [];
  Page.get()?.requests.forEach((request) => {
    // Prevent request loop
    if (requestCallChain.includes(request.id)) return;

    // Run chained request
    requestCallChain.forEach((calledRequest) => {
      if (
        request.executeAfterRequests?.includes(calledRequest) &&
        !internalRequestCallChain.includes(request.id)
      )
        Request.perform(request.id, requestCallChain);
      internalRequestCallChain.push(request.id);
    });

    // Evaluate if execute condition is true
    if (
      Value.evaluateInputAll(
        request.executeOnCondition ? JSON.parse(request.executeOnCondition) : ""
      ) &&
      !internalRequestCallChain.includes(request.id)
    ) {
      Request.perform(request.id, requestCallChain);
      internalRequestCallChain.push(request.id);
    }
  });
};

const afterCreateUserAction = async (request: RequestModel, response: any, responseParsed: any) => {
  if (response.status === 500 && request?.isCreateUser) alert("Error: " + responseParsed.error);
  if (response.status === 200 && request?.isCreateUser && request?.type === "create") {
    const fallBackPage: PageModel | undefined =
      request?.redirectPageIdAfterCreateAccount &&
      store.state.configuration.pages.find(
        (page: PageModel) => page.id === request?.redirectPageIdAfterCreateAccount
      );

    if (request?.fields?.find((v) => v.fieldId === "wized---password")?.value) {
      // TODO: Log in user if password is set
      const accessToken = responseParsed.accessToken;
      Cookie.set("w---at", accessToken, 30);
    }
    if (
      request?.redirectAfterCreateAccount &&
      request?.redirectPageIdAfterCreateAccount &&
      fallBackPage
    ) {
      location.href = fallBackPage.path;
    } else if (!request?.fields?.find((v) => v.fieldId === "wized---password")?.value) {
      alert(API.alertText.loginEmailSent);
    } else alert(API.alertText.accountCreationSuccess);
  }
};

const beforeCreateUserAction = (request: RequestModel) => {
  if (
    request?.isCreateUser &&
    request?.type === "create" &&
    request?.fields?.find((v) => v.fieldId === "wized---password")?.value
  ) {
    // Valdiate password & email requirements
    const passwordFieldValue = request?.fields?.find((v) => v.fieldId === "wized---password")
      ?.value;
    const password = Value.evaluateInputAll(
      passwordFieldValue ? JSON.parse(passwordFieldValue) : []
    );
    const schema = new passwordValidator();
    schema.is().min(6).is().max(100).has().uppercase().has().lowercase();

    // const emailFieldId = store.state.configuration.settings.login.fieldId;
    // const emailFieldValue = request?.fields?.find((v) => v.fieldId === emailFieldId)?.value;
    // const email = Value.evaluateInputAll(emailFieldValue ? JSON.parse(emailFieldValue) : []);
    // console.log(email, password, emailFieldValue, passwordFieldValue);
    // if (!emailValidator.validate(email)) {
    //   alert(API.alertText.emailInvalid);
    //   throw new Error(`Email "${email}" is not valid. Please try again.`);
    // }

    if (!schema.validate(password)) {
      alert(API.alertText.passwordInsecure);
      throw new Error(
        "The password does not meet criteria for a strong password. The password must be at least 6 characters long and must consist of lowercase and uppercase letters."
      );
    }
  }
};

const getDataDump = (elementContext?: Element) => {
  // Get data dump to attach on request
  const data = JSON.parse(JSON.stringify(store.state.data));

  // Replace request in data with item of array if parent list can be found
  if (elementContext !== undefined) {
    const listNode = elementContext.closest("[wized---list-index]");
    // Determine if Element is in List
    if (listNode) {
      // Element is in array, get index
      const listIndex = Number(listNode.getAttribute("wized---list-index"));
      const arrayPath = listNode.getAttribute("wized---list-array-path");
      const arrayValue = Value.evaluateInputAll(
        JSON.parse(listNode.getAttribute("wized---list-value") || "")
      );
      const arrayItem = arrayValue[listIndex];
      const requestId = arrayPath?.split(".")[0];
      // Replace value of requestId with found array item
      const requestIndex = data.requests.findIndex(
        (request: { id: string; value: ResponseModel }) => request.id === requestId
      );

      // if arrayQuery=== "[array]" replace all data
      const arrayQuery = arrayPath?.split(".").slice(2).join(".");
      if (arrayQuery === "[array]") data.requests[requestIndex].value.data = [arrayItem];
      // TODO: Else impliment find item by query path, and replace it then.
    }
  }
  return data;
};

const resetInputFields = (request: RequestModel) => {
  request?.fields?.forEach((field) => {
    const value = field.value ? JSON.parse(field.value) : [];
    value.forEach((valueItem: any) => {
      if (valueItem.type === "input" && document.querySelector(valueItem.name)) {
        document.querySelector(valueItem.name).value = "";
      }
    });
  });
};
