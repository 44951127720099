












































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import Variable from "@/components/ui/Variable.vue";
import draggable from "vuedraggable";
import AllData from "@/components/tab/data/AllData.vue";

@Component({
  components: { Variable, draggable, AllData },
})
export default class DataSelector extends Vue {
  tab = "data";
  logic = {
    custom: [
      { name: "text", type: "editable" },
      { name: "number", type: "editable" },
    ],
    functions: [
      { name: "if", type: "function" },
      { name: "includes", type: "function" },
      { name: "()", type: "function" },
      { name: "toString", type: "function" },
      { name: "toNumber", type: "function" },
      { name: "toBoolean", type: "function" },
      { name: "parseDate", type: "function" },
      { name: "formatDate", type: "function" },
      { name: "invertBoolean", type: "function" },
      { name: "addString", type: "function" },
      { name: "removeString", type: "function" },
    ],
    keywords: [
      { name: "true", type: "keyword" },
      { name: "false", type: "keyword" },
      { name: "empty", type: "keyword" },
      { name: "undefined", type: "keyword" },
    ],
    operators: [
      { name: "!=", type: "operator" },
      { name: "=", type: "operator" },
      { name: "<", type: "operator" },
      { name: ">", type: "operator" },
      { name: "<=", type: "operator" },
      { name: ">=", type: "operator" },
      { name: "and", type: "operator" },
      { name: "or", type: "operator" },
      { name: "+", type: "operator" },
      { name: "-", type: "operator" },
      { name: "*", type: "operator" },
      { name: "/", type: "operator" },
      { name: "mod", type: "operator" },
    ],
    variables: [{ name: "timestamp", type: "variable" }],
  };

  clone(attributes: { name: string; type: string }): { name: string; type: string } {
    return {
      name: attributes.name,
      type: attributes.type,
    };
  }

  checkMove(event: any) {
    if (event.from === event.to) return false;
    else true;
  }

  setIsDragging(value: boolean): void {
    this.$store.commit("SET_HELPER_ISDRAGGINGVARIABLE", value);
  }

  open(tabName: string) {
    this.tab = tabName;
  }

  close(): void {
    this.$store.commit("SET_POPUP_DATASELECTOR", false);
  }
}
