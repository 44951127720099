
















































import { Component, Vue, Watch } from "vue-property-decorator";
import InputMultiSelect from "@/components/ui/InputMultiSelect.vue";
import InputText from "@/components/ui/InputText.vue";
import OptionTitle from "@/components/ui/OptionTitle.vue";
import BlockHeading from "@/components/ui/BlockHeading.vue";
import RequestModel from "@/models/Request";

@Component({
  components: {
    InputMultiSelect,
    InputText,
    OptionTitle,
    BlockHeading,
  },
})
export default class Limit extends Vue {
  set(value: any, attributeName: "maxRecords"): void {
    const request = this.request;
    (request[attributeName] as any) = value;
    this.$store.commit("SET_POPUP_REQUESTSETTINGS", {
      request: request,
      isOpen: true,
    });
  }

  get request(): RequestModel {
    return JSON.parse(JSON.stringify(this.$store.state.popup.requestSettings.request));
  }

  get fieldsOfSelectedTable(): Array<any> {
    const selectedTableId = this.request.tableId;
    const table = this.tablesOfSelectedBase[0]
      ? this.tablesOfSelectedBase[0].values.find((table: any) => table.id === selectedTableId)
      : {};
    return table && table.fields ? [{ name: "Fields", values: table ? table.fields : [] }] : [];
  }

  get tablesOfSelectedBase(): Array<any> {
    return this.$store.state.popup.requestSettings.airtableBaseStructure.length > 0
      ? [
          {
            name: "Tables",
            values: this.$store.state.popup.requestSettings.airtableBaseStructure,
          },
        ]
      : [];
  }
}
