





















import { Component, Vue, Watch } from "vue-property-decorator";
import envConfig from "@/envConfig";

@Component({
  components: {},
})
export default class TopBarPageSelector extends Vue {
  toggleTab(name: string) {
    this.$store.commit("SET_PREVIEW", { isFullScreen: false });
    if (this.$store.state.tab.tabName !== "pages") {
      this.$store.commit("SET_TAB", "pages");
    } else {
      this.$store.commit("SET_TAB", "");
    }
  }

  get path(): string {
    return window.location.pathname;
  }

  openDashboard(): void {
    if (this.$store.state.save.isSaved) window.location.href = envConfig.dashboardUrl;
    else if (confirm("Recent changes are not saved. Are you sure you want to go to the dashboard?"))
      window.location.href = envConfig.dashboardUrl;
    else return;
  }
}
