var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hoveredElement)?_c('div',{staticClass:"pw---selection-wrapper",style:({
    top: _vm.hoveredRect.top + 'px',
    left: _vm.hoveredRect.left + 'px',
    height: _vm.hoveredRect.height + 'px',
    width: _vm.hoveredRect.width + 'px',
  })},[_c('div',{staticClass:"pw---selection-text",staticStyle:{"position":"absolute"},style:({
      marginTop: _vm.hoveredRect.top <= 45 ? '5px' : '-20px',
    })},[_c('div',{staticClass:"pw---p-small pw---blue",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.hoveredElementDisplay.tagName)+" ")]),_c('div',{staticClass:"pw---p-small pw---left-5 pw---blue",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.hoveredElementDisplay.identifier)+" ")])]),_c('div',{staticClass:"pw---selection"})]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }