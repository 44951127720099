






























































import { Component, Vue, Watch } from "vue-property-decorator";
import Button from "@/components/ui/Button.vue";
import Info from "@/components/ui/tooltips/Info.vue";

const Props = Vue.extend({
  props: {
    title: {
      type: String,
      default: "",
    },
    iconName: {
      type: String,
    },
    iconSize: {
      type: Number,
      default: 11,
    },
    addButton: {
      type: Boolean,
      default: false,
    },
    lessonUrl: {
      type: String,
      default: "",
    },
    iconText: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    hasSettingsIndicator: {
      type: Boolean,
      default: false,
    },
  },
});

@Component({
  components: {
    Button,
    Info,
  },
})
export default class BlockHeading extends Props {
  showBlock = true;
  hover = false;
  descriptionShown = false;

  toggleBlock(): void {
    this.showBlock = !this.showBlock;
    this.$emit("showBlock", this.showBlock);
  }

  showDescriptions(): void {
    setTimeout(() => {
      if (this.hover) this.descriptionShown = true;
    }, 400);
  }

  add(): void {
    this.$emit("add");
  }

  openLesson() {
    window.open(this.lessonUrl, "_blank")?.focus();
  }
}
