const Parameter = {
  get: (name: string): string => {
    const urlString = window.location.href;
    const url = new URL(urlString);
    if (url.searchParams.get(name)) {
      return url.searchParams.get(name) || "";
    } else {
      return "";
    }
  },
  delete: (paramName: string): void => {
    const url = new URL(window.location.href);
    url.searchParams.delete(paramName);
    const urlString = url.toString();
    window.history.replaceState(null, "", urlString);
  },
  set: (name: string, value: any): void => {
    const url = new URL(window.location.href);
    const params = url && new URLSearchParams(url.search);
    params.set(name, value.toString());
    url.search = "?" + params.toString();
    window.location.href = url.toString();
  },
};

export default Parameter;
