
















import { Component, Vue, Watch } from "vue-property-decorator";
import Variable from "./Variable.vue";
import Element from "@/helpers/element";

const Props = Vue.extend({
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
});

@Component({
  components: {
    Variable,
  },
})
export default class Auth extends Props {
  hover = false;

  setHover(value: string): void {
    if (value) {
      this.hover = true;
      const element = document.querySelector(value);
      if (!element) return;
      const elementFormatted = Element.format(element as HTMLElement);
      this.$store.commit("SET_HOVERED_ELEMENT", elementFormatted);
    } else {
      this.hover = false;
      this.$store.commit("SET_HOVERED_ELEMENT", {});
    }
  }

  getElementForDisplay(queryString: string) {
    if (queryString) {
      const element = document.querySelector(queryString);
      const elementFormatted = Element.format(element as HTMLElement);
      return elementFormatted;
    }
  }
}
