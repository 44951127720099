var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%","display":"flex","flex-direction":"column","align-items":"flex-start"}},[(!_vm.$store.state.popup.requestSettings.airtableBasesLoading)?_c('div',{staticClass:"pw---option-row"},[_c('OptionTitle',{attrs:{"description":"Select an Airtable base.","text":"Base","isSet":Boolean(_vm.request.baseId)},on:{"reset":function () { return _vm.set('', 'baseId'); }}}),_c('div',{staticClass:"pw---option-space"}),_c('InputSelect',{attrs:{"initialValueId":_vm.request.baseId,"selectValueGroups":_vm.basesOfSelectedResource,"openWidth":180},on:{"change":function (value) {
          _vm.set(value, 'baseId');
          _vm.loadAirtableBaseStructure();
        }}})],1):_vm._e(),(
      !_vm.$store.state.popup.requestSettings.airtableBasesLoading &&
      !_vm.$store.state.popup.requestSettings.airtableBaseStructureLoading
    )?_c('div',{staticClass:"pw---option-row"},[_c('OptionTitle',{attrs:{"description":"Select a table inside your base.","text":"Table","isSet":Boolean(_vm.request.tableId)},on:{"reset":function () { return _vm.set('', 'tableId'); }}}),_c('div',{staticClass:"pw---option-space"}),_c('InputSelect',{attrs:{"initialValueId":_vm.request.tableId,"selectValueGroups":_vm.tablesOfSelectedBase,"openWidth":180},on:{"change":function (value) { return _vm.set(value, 'tableId'); }}})],1):_vm._e(),(
      _vm.$store.state.popup.requestSettings.airtableBasesLoading ||
      _vm.$store.state.popup.requestSettings.airtableBaseStructureLoading
    )?_c('LoadingSpinner',{attrs:{"height":60}}):_vm._e(),(_vm.request.type === 'get' || _vm.request.type === 'update' || _vm.request.type === 'delete')?_c('div',{staticClass:"pw---option-row"},[_c('OptionTitle',{attrs:{"description":"Enter the record id of the item you want to interact with. Feel free to use data from other requests, variables, parameters, cookies, plain old text or a formula.","text":"Record id","isSet":Boolean(_vm.request.recordId ? !(_vm.request.recordId === '[]') : false)},on:{"reset":function () { return _vm.set('', 'recordId'); }}}),_c('div',{staticClass:"pw---option-space"})],1):_vm._e(),(_vm.request.type === 'get' || _vm.request.type === 'update' || _vm.request.type === 'delete')?_c('div',{staticClass:"pw---option-row"},[_c('InputAll',{attrs:{"width":200,"initialValue":_vm.request.recordId},on:{"change":function (value) { return _vm.set(value, 'recordId'); }}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }