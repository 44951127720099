
























import { Component, Vue, Watch } from "vue-property-decorator";

export default class Errors extends Vue {
  hideError(index: number): void {
    const errors = JSON.parse(JSON.stringify(this.$store.state.errors));
    errors.splice(index, 1);
    this.$store.commit("SET_ERRORS", errors);
  }
}
