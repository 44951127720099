




































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import InputAll from "@/components/ui/InputAll.vue";
import InputSelect from "@/components/ui/InputSelect.vue";
import Toggle from "@/components/ui/Toggle.vue";
import OptionTitle from "@/components/ui/OptionTitle.vue";
import BlockHeading from "@/components/ui/BlockHeading.vue";
import Button from "@/components/ui/Button.vue";
import RequestModel from "@/models/Request";
import { PageModel } from "@/models/Configuration";

@Component({
  components: {
    InputAll,
    InputSelect,
    Toggle,
    OptionTitle,
    BlockHeading,
    Button,
  },
})
export default class Values extends Vue {
  fieldValue(id: string): string {
    const field: { fieldId: string; value: string } | undefined = this.request.fields?.find(
      (fieldFind: { fieldId: string; value: string }) => fieldFind.fieldId === id
    );
    if (field) return !field.value || field.value === "[]" ? "" : field.value;
    else return "";
  }

  set(value: any, attributeName: string): void {
    const request = this.request;
    ((request as any)[attributeName] as any) = value;
    this.$store.commit("SET_POPUP_REQUESTSETTINGS", {
      request: request,
      isOpen: true,
    });
  }

  setArray(value: string, attributeId: string, arrayName: "fields"): void {
    const request = this.request;
    let array = request[arrayName];
    if (!array) return;
    const item = array.find((itemFind: any) => itemFind.fieldId === attributeId);
    if (item) item.value = value;
    else array.push({ fieldId: attributeId, value: value });
    request[arrayName] = array;
    this.$store.commit("SET_POPUP_REQUESTSETTINGS", {
      request: request,
      isOpen: true,
    });
  }

  fieldIsDisabled(field: { id: string; name: string; type: string }) {
    if (
      field.type === "autoNumber" ||
      field.type === "barcode" ||
      field.type === "button" ||
      field.type === "count" ||
      field.type === "createdBy" ||
      field.type === "lastModifiedTime" ||
      field.type === "createdTime" ||
      field.type === "externalSyncSource" ||
      field.type === "formula" ||
      field.type === "lastModifiedBy" ||
      field.type === "multipleLookupValues" ||
      field.type === "rollup"
    )
      return true;
    else return false;
  }

  get request(): RequestModel {
    return JSON.parse(JSON.stringify(this.$store.state.popup.requestSettings.request));
  }

  get fieldsOfSelectedTable(): Array<any> {
    const selectedTableId = this.request.tableId;
    const table = this.tablesOfSelectedBase[0]
      ? this.tablesOfSelectedBase[0].values.find((table: any) => table.id === selectedTableId)
      : {};
    return table && table.fields ? table.fields.sort((a: any, b: any) => a.name - b.name) : [];
  }

  get tablesOfSelectedBase(): Array<any> {
    return this.$store.state.popup.requestSettings.airtableBaseStructure.length > 0
      ? [
          {
            name: "Tables",
            values: this.$store.state.popup.requestSettings.airtableBaseStructure,
          },
        ]
      : [];
  }

  get hasSetPassword(): boolean {
    if (
      this.$store.state.configuration.settings.login.isEnabled &&
      this.request.baseId === this.$store.state.configuration.settings.login.baseId &&
      this.request.tableId === this.$store.state.configuration.settings.login.tableId &&
      this.$store.state.configuration.settings.login.userLocationValid &&
      this.$store.state.configuration.pages.find(
        (page: PageModel) => page.settings.login.emailPasswordLoginEnabled
      ) &&
      this.request.type == "create"
    )
      return true;
    else return false;
  }

  get hasSetPasswordOrMagicLink(): boolean {
    if (
      this.$store.state.configuration.settings.login.isEnabled &&
      this.request.baseId === this.$store.state.configuration.settings.login.baseId &&
      this.request.tableId === this.$store.state.configuration.settings.login.tableId &&
      this.$store.state.configuration.settings.login.userLocationValid &&
      (this.$store.state.configuration.pages.find(
        (page: PageModel) => page.settings.login.magicLinkEnabled
      ) ||
        this.$store.state.configuration.pages.find(
          (page: PageModel) => page.settings.login.emailPasswordLoginEnabled
        )) &&
      this.request.type == "create"
    )
      return true;
    else return false;
  }

  get pages(): Array<any> {
    const result: { name: string; id: string }[] = [];
    const allPages = this.$store.state.configuration.pages;
    allPages.forEach((page: PageModel) => {
      if ((page as any).type !== "folder") result.push({ name: page.path, id: page.id });
    });
    return [
      {
        name: "Pages",
        values: result,
      },
    ];
  }
}
