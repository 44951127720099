






























































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import BlockHeading from "@/components/ui/BlockHeading.vue";
import Toggle from "@/components/ui/Toggle.vue";
import InputSelect from "@/components/ui/InputSelect.vue";
import InputAll from "@/components/ui/InputAll.vue";
import InputElement from "@/components/ui/InputElement.vue";
import OptionTitle from "@/components/ui/OptionTitle.vue";
import Page from "@/helpers/page";
import { PageModel } from "@/models/Configuration";
import Variable from "@/components/ui/Variable.vue";
import Element from "@/helpers/element";
import Button from "@/components/ui/Button.vue";
import RequestModel from "@/models/Request";
import SelectedElement from "@/components/ui/SelectedElement.vue";

@Component({
  components: {
    BlockHeading,
    Toggle,
    InputAll,
    InputSelect,
    InputElement,
    OptionTitle,
    Variable,
    Button,
    SelectedElement,
  },
})
export default class Auth extends Vue {
  mounted() {
    if (typeof Page.get()?.settings?.accessControl.allowIf === "string") {
      const settings = Page.get()?.settings;
      if (!settings) return;
      const rule = (settings.accessControl.allowIf as unknown) as string;
      settings.accessControl.allowIf = [
        { rule: rule, fallbackPageId: settings.accessControl.fallbackPageId },
      ];
      this.$store.commit("SET_PAGE_SETTINGS", settings);
      this.$forceUpdate();
      this.$store.dispatch("saveConfiguration");
    }
  }

  set(value: any, settingType: "accessControl" | "login", attributeName: string) {
    const settings = Page.get()?.settings;
    if (!settings) return;
    ((settings[settingType] as any)[attributeName] as any) = value;
    this.$store.commit("SET_PAGE_SETTINGS", settings);
    if (attributeName === "logoutButton" || attributeName === "authFallbackPageId") {
      this.$forceUpdate();
      this.$store.dispatch("saveConfiguration");
    }
  }

  setArray(value: string, attributeName: "rule" | "fallbackPageId", index: number): void {
    const settings = Page.get()?.settings;
    (settings as any)["accessControl"]["allowIf"][index][attributeName] = value;
    this.$store.commit("SET_PAGE_SETTINGS", settings);
    this.$forceUpdate();
    this.$store.dispatch("saveConfiguration");
  }

  addArrayItem(arrayName: "sort"): void {
    const settings = Page.get()?.settings;
    const item = { rule: "", fallbackPageId: "" };
    (settings as any)["accessControl"]["allowIf"].push(item);
    this.$store.commit("SET_PAGE_SETTINGS", settings);
    this.$forceUpdate();
    this.$store.dispatch("saveConfiguration");
  }

  deleteArrayItem(index: number): void {
    const settings = Page.get()?.settings;
    (settings as any)["accessControl"]["allowIf"].splice(index, 1);
    this.$store.commit("SET_PAGE_SETTINGS", settings);
    this.$forceUpdate();
    this.$store.dispatch("saveConfiguration");
  }

  addSignUpRequest(): void {
    const emptyRequest: RequestModel = {
      id: "",
      resourceId: this.$store.state.configuration?.settings?.login?.resourceId || "",
      name: "Create new user",
      type: "create",
      executeOnPageLoad: false,
      executeOnCondition: "",
      executeAfterRequests: [],
      redirectAfterCreateAccount: false,
      redirectPageIdAfterCreateAccount: "",
      actions: [],

      // Type Airtable
      baseId: this.$store.state.configuration?.settings?.login?.baseId || "",
      tableId: this.$store.state.configuration?.settings?.login?.tableId || "",
      recordId: "",
      viewId: "",
      filter: [],
      sort: [],
      fields: [],
      typecast: true,
      limitToFields: [],
      maxRecords: 99,
      isCreateUser: false,

      // Type Stripe: Single Payment
      purchaseType: "one-time",
      items: [],
      customerEmail: "",
      billingAddressCollection: false,
      allowedCountries: [],
      submitType: "auto", // auto, book, donate, pay
      successUrl: "",
      cancelUrl: "",
      paymentMethods: [],
      // Type Stripe: Customer portal
      customerId: "",
      returnUrl: "",
      metadata: [],

      // Type REST
      endpoint: "",
      headers: [],
      params: [],
      contentType: "none", // none, application/json, multipart/form-data
      bodyForm: [],
      body: "",

      arrayFilters: [],
      arraySortings: [],
    };
    this.$store.commit("SET_POPUP_REQUESTSETTINGS", {
      request: emptyRequest,
      isOpen: true,
    });
    this.$store.commit("SET_TAB", "requests");
  }

  get accessControl() {
    return Page.get()?.settings.accessControl;
  }

  get login() {
    return Page.get()?.settings.login;
  }

  get pages(): Array<any> {
    const result: Array<{ name: string; id: string }> = [];
    const allPages = this.$store.state.configuration.pages;
    allPages.forEach((page: PageModel) => {
      if ((page as any).type !== "folder") result.push({ name: page.path, id: page.id });
    });
    return [
      {
        name: "Pages",
        values: result,
      },
    ];
  }

  get loginConfigured(): boolean {
    if (
      this.login?.magicLinkEnabled &&
      this.login?.magicLinkEmailQuery &&
      this.login?.magicLinkButtonQuery
    )
      return true;
    else if (
      this.login?.emailPasswordLoginEnabled &&
      this.login?.emailPasswordEmailQuery &&
      this.login?.emailPasswordPasswordQuery &&
      this.login.emailPasswordButtonQuery
    )
      return true;
    else return false;
  }

  get emailPasswordLoginConfiguredSomewhere(): boolean {
    const pages = this.$store.state.configuration.pages;
    let result = false;
    pages.forEach((page: PageModel) => {
      if (
        (page as any).type !== "folder" &&
        page.settings.login.emailPasswordLoginEnabled &&
        page.settings.login.emailPasswordEmailQuery &&
        page.settings.login.emailPasswordPasswordQuery &&
        page.settings.login.emailPasswordButtonQuery
      )
        result = true;
    });
    return result;
  }

  get requestResetConfigured(): boolean {
    if (
      this.login?.emailPasswordRequestReset &&
      this.login?.emailPasswordRequestResetEmailQuery &&
      this.login?.emailPasswordRequestResetButtonQuery &&
      this.login?.resetPasswordPageId
    )
      return true;
    else return false;
  }

  get resetPasswordConfigured(): boolean {
    if (
      this.login?.emailPasswordReset &&
      this.login?.emailPasswordResetPasswordQuery &&
      this.login?.emailPasswordResetPasswordValidationQuery &&
      this.login?.emailPasswordResetButtonQuery
    )
      return true;
    else return false;
  }
}
