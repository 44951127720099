



















import { Component, Vue, Watch } from "vue-property-decorator";
import Button from "@/components/ui/Button.vue";

const Props = Vue.extend({
  props: {
    title: {
      type: String,
      default: "",
    },
    deleteButton: {
      type: Boolean,
      default: false,
    },
    lessonUrl: {
      type: String,
    },
  },
});

@Component({
  components: {
    Button,
  },
})
export default class PopupHeading extends Props {
  emitDelete(): void {
    this.$emit("delete");
  }

  openLesson() {
    window.open(this.lessonUrl, "_blank")?.focus();
  }
}
