import { render, staticRenderFns } from "./BlockHeading.vue?vue&type=template&id=45f3d218&scoped=true&"
import script from "./BlockHeading.vue?vue&type=script&lang=ts&"
export * from "./BlockHeading.vue?vue&type=script&lang=ts&"
import style0 from "./BlockHeading.vue?vue&type=style&index=0&id=45f3d218&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45f3d218",
  null
  
)

export default component.exports