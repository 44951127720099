import env from "@/envConfig";
import Token from "@/helpers/token";
import store from "@/store";
import ResourceModel from "@/models/Resource";
import Element from "@/helpers/element";

const Variables = {
  getAllInputFields: (): Array<{
    name: string;
    context: string;
    queryString: string;
    value: string;
    type: string;
  }> => {
    const orignalPage = document.querySelector("#pw---website");
    if (!orignalPage) return [];
    const inputElementsRaw = orignalPage.querySelectorAll("input");
    if (!inputElementsRaw) return [];
    const inputElements: Array<{
      name: string;
      context: string;
      queryString: string;
      value: string;
      type: string;
    }> = [];
    const radioNames: Array<string> = [];
    inputElementsRaw.forEach((element: HTMLInputElement) => {
      const elementFormatted = Element.format(element);
      if (
        (element.type === "radio" && radioNames.includes(element.name)) ||
        element.type === "submit"
      )
        return;
      const value =
        element.type !== "radio"
          ? element.value
          : (document.querySelector("input[name=" + element.name + "]:checked") as HTMLInputElement)
              ?.value;
      const name =
        element.type !== "radio"
          ? elementFormatted.id
            ? "#" + elementFormatted.id
            : "." + elementFormatted.classes
          : '[name="' + element.name + '"]';
      inputElements.push({
        name: name,
        context: elementFormatted.tagName + "[" + element.getAttribute("type") + "]",
        queryString:
          element.type !== "radio"
            ? elementFormatted.queryString
            : 'input[type=radio][name="' + (element as HTMLInputElement).name + '"]',
        value: value,
        type: element.type,
      });
      if (element.type === "radio") radioNames.push(element.name);
    });
    const selectElementsRaw = orignalPage.querySelectorAll("select");
    selectElementsRaw.forEach((element: HTMLSelectElement) => {
      const elementFormatted = Element.format(element);
      inputElements.push({
        name: elementFormatted.id ? "#" + elementFormatted.id : "." + elementFormatted.classes,
        context: elementFormatted.tagName,
        queryString: elementFormatted.queryString,
        value: element.value,
        type: element.type,
      });
    });
    const textareaElementsRaw = orignalPage.querySelectorAll("textarea");
    textareaElementsRaw.forEach((element: HTMLTextAreaElement) => {
      const elementFormatted = Element.format(element);
      inputElements.push({
        name: elementFormatted.id ? "#" + elementFormatted.id : "." + elementFormatted.classes,
        context: elementFormatted.tagName,
        queryString: elementFormatted.queryString,
        value: element.value,
        type: element.type,
      });
    });
    return inputElements;
  },
};

export default Variables;
