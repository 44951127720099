














import { Component, Vue, Watch } from "vue-property-decorator";

const Props = Vue.extend({
  props: {
    initialValue: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 120,
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
  },
});

@Component({
  components: {},
})
export default class InputText extends Props {
  value = "";

  @Watch("initialValue", { immediate: true })
  onInitialValueChanged(value: string): void {
    this.value = value;
  }

  @Watch("value")
  onValueChanged(value: string): void {
    this.change();
  }

  change(): void {
    this.$emit("change", this.value);
  }
}
