
















































import { Component, Vue, Watch } from "vue-property-decorator";
import env from "@/envConfig";
import LoadingSpinner from "@/components/misc/LoadingSpinner.vue";
import History from "@/helpers/history";
import API from "@/helpers/api";
import Request from "@/helpers/request";
import Page from "@/helpers/page";

@Component({
  components: { LoadingSpinner },
})
export default class Upload extends Vue {
  file = "";
  uploading = false;

  handleBoxClick(event: any) {
    event.stopPropagation();
  }

  closeUpload() {
    this.file = "";
    (this.$refs as any).value = (this.$refs as any).defaultValue;
    this.$store.commit("TOGGLE_UPLOAD", { id: "", visibility: false });
  }

  handleFileUpload() {
    console.log((this.$refs as any).file.files[0].size);
    if ((this.$refs as any).file.files[0].size > 20000000) {
      alert(API.alertText.fileInvalid);
    } else this.file = (this.$refs as any).file.files[0];
  }

  get fileSize() {
    if (this.file) return Math.round((this.file as any).size / 1000);
    else return 0;
  }

  async submitFile() {
    this.uploading = true;
    let formData = new FormData();
    formData.append("file", this.file);

    this.$store.commit("SET_DATA_TYPE_VALUE", {
      value: {
        id: this.$store.state.upload.id,
        value: {
          isRequesting: true,
          hasRequested: false,
          statusCode: 0,
          errorMessage: "",
          data: {},
        },
      },
      type: "requests",
    });
    History.requestStart(this.$store.state.upload.id);

    const response = await fetch(`${env.serverUrl}v1/upload`, {
      method: "POST",
      body: formData,
    });
    const responseParsed = await response.json();
    console.log(response, responseParsed);

    this.$store.commit("SET_DATA_TYPE_VALUE", {
      value: {
        id: this.$store.state.upload.id,
        value: {
          isRequesting: false,
          hasRequested: true,
          statusCode: response.status,
          errorMessage: "",
          data: responseParsed,
        },
      },
      type: "requests",
    });
    History.requestEnd(response, responseParsed, this.$store.state.upload.id);

    const request = Page.get()?.requests.find((r) => r.id === this.$store.state.upload.id);
    Request.afterRequestActions(request as any);

    this.uploading = false;
    this.closeUpload();
  }
}
