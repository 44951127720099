






















































import { Component, Vue, Watch } from "vue-property-decorator";
import OptionTitle from "@/components/ui/OptionTitle.vue";
import BlockHeading from "@/components/ui/BlockHeading.vue";
import InputSelect from "@/components/ui/InputSelect.vue";
import Toggle from "@/components/ui/Toggle.vue";
import InputAll from "@/components/ui/InputAll.vue";
import Button from "@/components/ui/Button.vue";
import {
  ElementModel,
  StyleConnectionModel,
  PageModel,
  ParameterConnectionModel,
} from "@/models/Configuration";
import Page from "@/helpers/page";
import RequestModel from "@/models/Request";
import Configuration from "@/helpers/configuration";

@Component({
  components: {
    OptionTitle,
    BlockHeading,
    InputSelect,
    InputAll,
    Toggle,
    Button,
  },
})
export default class Parameters extends Vue {
  setArray(value: any, index: number, attribute: string): void {
    const connection = this.connection;
    (connection[index] as any)[attribute] = value;
    this.saveConnection(connection);
  }

  addArrayItem(): void {
    const emptyConnection: ParameterConnectionModel = {
      parameterId: "",
      value: "",
    };
    let connection = this.connection;
    connection.push(emptyConnection);
    this.saveConnection(connection);
  }

  deleteArrayItem(index: number): void {
    let connection = this.connection;
    connection.splice(index, 1);
    this.saveConnection(connection);
  }

  saveConnection(connection: Array<ParameterConnectionModel>) {
    const connectionAttribute = "parameters";
    let element: ElementModel = {
      elementQueryString: "",
      connections: {
        onClick: [],
        onInput: [],
        visibility: { condition: "" },
        style: [],
        class: [],
        list: { value: "" },
        value: { value: "" },
        text: { value: "", type: "text" },
        attributes: [],
        parameters: [],
      },
    };
    if (this.element) {
      element = this.element;
    } else {
      element.elementQueryString = this.selectedElementQuery;
    }
    element.connections[connectionAttribute] = connection;
    if (Configuration.isElementEmpty(element)) this.$store.commit("DELETE_ELEMENT", element);
    else this.$store.commit("SET_ELEMENT", element);
  }

  get connection(): Array<ParameterConnectionModel> {
    if (
      this.element &&
      this.element.connections.parameters &&
      this.element.connections.parameters.length > 0
    ) {
      return this.element.connections.parameters;
    } else {
      return [];
    }
  }

  get element(): ElementModel | undefined | null {
    const DOMElement = document.querySelector(this.selectedElementQuery);
    return Page.domElemementsConfig()?.find((element: any) => element.DOMElement === DOMElement)
      ?.element;
  }

  get selectedElementQuery(): string {
    const element = this.$store.state.element.selected;
    if (element && element.queryString) return element.queryString;
    else return "";
  }

  get fieldConfiguration() {
    const pages = this.$store.state.configuration.pages;
    const parameters: { id: string; name: string }[] = [];
    pages.forEach((page: PageModel) => {
      if ((page as any).type !== "folder")
        page.parameters.forEach((param) => {
          parameters.push(param);
        });
    });
    return {
      parameters: [
        {
          name: "Parameters",
          values: parameters,
        },
      ],
    };
  }
}
