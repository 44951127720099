


















import { Component, Vue, Watch } from "vue-property-decorator";
import BlockHeading from "@/components/ui/BlockHeading.vue";
import {
  CookieModel,
  ElementModel,
  OnClickConnectionModel,
  PageModel,
} from "@/models/Configuration";
import Page from "@/helpers/page";
import RequestModel from "@/models/Request";
import Configuration from "@/helpers/configuration";
import ActionConfig from "@/components/misc/ActionConfig.vue";

@Component({
  components: {
    BlockHeading,
    ActionConfig,
  },
})
export default class OnClick extends Vue {
  setArray(value: any, index: number, attribute: string): void {
    const connection = this.connection;
    Vue.set(connection[index], attribute, value);
    this.saveConnection(connection);
  }

  addArrayItem(): void {
    const emptyConnection: OnClickConnectionModel = {
      action: "request",
      conditionalAction: false,
      condition: "",
      requestId: "",
      url: "",
      variableId: "",
      cookieId: "",
      value: "",
      cookieLifetime: "",
    };
    let connection = this.connection;
    connection = connection.concat([emptyConnection]);
    this.saveConnection(connection);
  }

  deleteArrayItem(index: number): void {
    let connection = this.connection;
    connection.splice(index, 1);
    this.saveConnection(connection);
  }

  saveConnection(connection: Array<OnClickConnectionModel>) {
    const connectionAttribute = "onClick";
    let element: ElementModel = {
      elementQueryString: "",
      connections: {
        onClick: [],
        onInput: [],
        visibility: { condition: "" },
        style: [],
        class: [],
        list: { value: "" },
        value: { value: "" },
        text: { value: "", type: "text" },
        attributes: [],
        parameters: [],
      },
    };
    if (this.element) {
      element = this.element;
    } else {
      element.elementQueryString = this.selectedElementQuery;
    }
    Vue.set(element.connections, connectionAttribute, connection);
    if (Configuration.isElementEmpty(element)) this.$store.commit("DELETE_ELEMENT", element);
    else this.$store.commit("SET_ELEMENT", element);
  }

  get connection(): Array<OnClickConnectionModel> {
    if (
      this.element &&
      this.element.connections.onClick &&
      this.element.connections.onClick.length > 0
    ) {
      return this.element.connections.onClick;
    } else {
      return [];
    }
  }

  get element(): ElementModel | undefined | null {
    const DOMElement = document.querySelector(this.selectedElementQuery);
    return Page.domElemementsConfig()?.find((element: any) => element.DOMElement === DOMElement)
      ?.element;
  }

  get selectedElementQuery(): string {
    const element = this.$store.state.element.selected;
    if (element && element.queryString) return element.queryString;
    else return "";
  }
}
