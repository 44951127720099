var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isEmpty)?_c('div',{staticClass:"pw---data-variable-row pw---padding-left-5"},[_c('Variable',{attrs:{"name":"no usable variables","type":"input","hasHover":false}})],1):_vm._e(),_vm._l((_vm.formattedAndFilteredData),function(dataF){return _c('div',{key:dataF.path,staticClass:"pw---data-variable-row"},[_vm._l((_vm.depthArray(dataF)),function(value,index){return _c('div',{key:index,staticClass:"pw---variable-child"},[(_vm.hasVerticalSeperator(dataF, index))?_c('div',{staticClass:"pw---vertical-seperator"}):_vm._e()])}),(dataF.type === 'array' || dataF.type === 'object')?_c('div',{staticClass:"pw---variable-child",on:{"click":function($event){return _vm.toggleVisibility(dataF)}}},[_c('img',{staticClass:"pw---icon-5",style:({
          transform: Boolean(_vm.closedData.find(function (dataClosed) { return dataClosed.path === dataF.path; }))
            ? 'rotate(-90deg)'
            : '',
        }),attrs:{"src":_vm.$config.configuratorUrl + 'images/dropdown.svg',"loading":"lazy","alt":""}})]):_vm._e(),_vm._l(([
        {
          name: dataF.path,
          path: _vm.requestId + '.attribute.' + dataF.path,
          type:
            dataF.type === 'object' || dataF.type === 'array'
              ? 'object'
              : _vm.requestId === 'user'
              ? 'auth'
              : 'attribute',
        } ]),function(dataA){return _c('draggable',{key:dataA.path,attrs:{"group":{ name: 'variables', pull: 'clone', put: false },"list":[
        {
          name: dataF.path,
          path: _vm.requestId + '.attribute.' + dataF.path,
          type:
            dataF.type === 'object' || dataF.type === 'array'
              ? 'object'
              : _vm.requestId === 'user'
              ? 'auth'
              : 'attribute',
        } ],"clone":_vm.clone,"move":_vm.checkMove,"disabled":!_vm.hasDragNDrop},on:{"start":function($event){return _vm.setIsDragging(true)},"end":function($event){return _vm.setIsDragging(false)}}},[_c('Variable',{attrs:{"isDraggable":_vm.hasDragNDrop,"path":dataA.path,"name":dataA.name,"type":dataA.type,"hasHover":true,"hasSetting":dataF.type === 'array'}})],1)})],2)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }