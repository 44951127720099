




































































































import { Component, Vue, Watch } from "vue-property-decorator";
import VariableTooltip from "@/components/ui/tooltips/Variable.vue";
import LogicTooltip from "@/components/ui/tooltips/Logic.vue";
import RequestModel from "@/models/Request";
import Page from "@/helpers/page";
import SortFilter from "@/components/ui/tooltips/SortFilter.vue";

const Props = Vue.extend({
  props: {
    name: {
      type: String,
      required: true,
    },
    context: {
      type: String,
    },
    type: {
      type: String,
      required: true,
      validator: (value) => {
        return (
          [
            "auth",
            "object",
            "attribute",
            "custom",
            "input",
            "function",
            "keyword",
            "operator",
            "variable",
            "editable",
            "state",
          ].indexOf(value) !== -1
        );
      },
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
    hasHover: {
      type: Boolean,
      default: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Object, Array, Boolean, Number],
      default: "",
    },
    hasDelete: {
      type: Boolean,
      default: false,
    },
    hasSetting: {
      type: Boolean,
      default: false,
    },
    path: {
      type: String,
      default: "",
    },
  },
});

@Component({
  components: { VariableTooltip, LogicTooltip, SortFilter },
})
export default class Variable extends Props {
  hover = false;
  hoverAll = false;
  hoverSortFilter = false;
  editableValue = "";
  showDeleteIcon = false;
  hoverBlock = false;

  mounted() {
    this.editableValue = this.value;
    this.resizeFieldBasedOnContent();
    if (this.hasDelete) this.showDeleteIcon = true;
  }

  handleEdit(value: string | number) {
    this.$emit("change", this.editableValue);
  }

  deleteVariable() {
    this.$emit("delete");
  }

  clickSetting() {
    this.$emit("clickSetting");
    if (this.type === "object") this.hoverSortFilter = true;
    else this.hoverSortFilter = false;
  }

  @Watch("value")
  handleInitalValueChange() {
    this.editableValue = this.value;
    this.resizeFieldBasedOnContent();
  }

  @Watch("editableValue")
  handle(): void {
    this.resizeFieldBasedOnContent();
  }

  resizeFieldBasedOnContent(): void {
    if (this.$refs.input)
      (this.$refs.input as HTMLElement).style.width = this.editableValue.length + 2 + "ch";
  }

  get request(): RequestModel | undefined {
    if (this.type === "object" || this.type === "attribute") {
      const requestId = this.path.split(".")[0];
      return this.requests.find((request: RequestModel) => request.id === requestId);
    } else return undefined;
  }

  get requests(): Array<RequestModel> {
    const page = Page.get();
    if (page) return page.requests;
    else return [];
  }

  get style(): string {
    let style = "";
    switch (this.type) {
      case "auth":
        style += "pw---orange";
        break;
      case "object":
        style += "pw---light-blue";
        break;
      case "attribute":
        style += "pw---dark-blue";
        break;
      case "custom":
        style += "pw---purple";
        break;
      case "input":
        style += "";
        break;
      case "function":
        style += "pw---mint-green";
        break;
      case "keyword":
        style += "pw---light-pink";
        break;
      case "operator":
        style += "pw---dark-grey";
        break;
      case "variable":
        style += "pw---bordeaux";
        break;
      case "editable":
        style += "pw---grey pw---outline";
        break;
      case "state":
        style += "pw---grey";
        break;
    }
    return style;
  }
}
