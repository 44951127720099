import ElementModel from "@/models/Element";
import store from "@/store";
import { PageModel } from "@/models/Configuration";
import Id from "./id";
import { reportError } from "./error-handler";
import { ElementModel as PageElementModel } from "@/models/Configuration";

const Page = {
  get: (id?: string): PageModel | undefined => {
    const page = store.state.configuration.pages.find((page: PageModel) =>
      !id ? page.path === window.location.pathname : page.id === id
    );
    if (page) return page;
    else return undefined;
  },
  async checkAndUpdatePageList(): Promise<void> {
    try {
      if (store.state.pagesUpdating) return;
      store.commit("SET_PAGES_UPDATE_LOADING", true);
      const response = await fetch(window.location.origin + "/sitemap.xml", {
        method: "GET",
        redirect: "follow",
      });
      const responseText = await response.text();
      const xmlDoc = new DOMParser().parseFromString(responseText, "text/xml");
      const pagesRaw = xmlDoc.getElementsByTagName("url");
      const pagesRawArray = [].slice.call(pagesRaw);
      const siteMapPages: Array<PageModel> = [];
      pagesRawArray.forEach((page: any) => {
        siteMapPages.push({
          path: new URL(page.textContent).pathname,
          id: "",
          requests: [],
          parameters: [],
          variables: [],
          responses: [],
          settings: {
            accessControl: {
              accessToThisPageRestricted: false,
              authRequired: false,
              authFallbackPageId: "",
              customAccessRule: false,
              allowIf: [],
              fallbackPageId: "",
            },
            login: {
              onThisPage: false,
              magicLinkEnabled: false,
              magicLinkEmailQuery: "",
              magicLinkButtonQuery: "",
              googleLoginEnabled: false,
              googleLoginButtonQuery: "",
              emailPasswordLoginEnabled: false,
              emailPasswordEmailQuery: "",
              emailPasswordPasswordQuery: "",
              emailPasswordButtonQuery: "",
              redirectAfterLogin: false,
              redirectPageId: "",
              emailPasswordRequestReset: false,
              emailPasswordRequestResetEmailQuery: "",
              emailPasswordRequestResetButtonQuery: "",
              resetPasswordPageId: "",
              emailPasswordReset: false,
              emailPasswordResetPasswordQuery: "",
              emailPasswordResetPasswordValidationQuery: "",
              emailPasswordResetButtonQuery: "",
              redirectAfterPasswordReset: false,
              redirectPageIdAfterPasswordReset: "",
              logoutButton: "",
            },
          },
          elements: [],
        });
      });
      const addToStore: Array<PageModel> = [];
      siteMapPages.forEach((siteMapPage: PageModel) => {
        let foundPageInStore = false;
        store.state.configuration.pages.forEach((storePage: PageModel) => {
          if (siteMapPage.path === storePage.path) {
            foundPageInStore = true;
          }
        });
        const pageDeleted = store.state.configuration.deletedPages.find(
          (path: string) => path === siteMapPage.path
        );
        if (!foundPageInStore && !pageDeleted) addToStore.push(siteMapPage);
      });
      addToStore.forEach((storePage: PageModel) => {
        storePage.id = Id.create(store.state.configuration.pages);
        store.commit("SET_PAGE", storePage);
      });
      store.commit("SET_PAGES_UPDATE_LOADING", false);
    } catch (e) {
      store.commit("SET_PAGES_UPDATE_LOADING", false);
      reportError("15000", "Could not update list of pages.", e);
    }
  },
  domElemementsConfig(): Array<{ DOMElement: HTMLElement | null; element: PageElementModel }> {
    const elementsInDOM: Array<{ DOMElement: HTMLElement | null; element: PageElementModel }> = [];
    Page.get()?.elements?.forEach((element: PageElementModel) => {
      elementsInDOM.push({
        DOMElement: document.querySelector(element.elementQueryString),
        element,
      });
    });
    return elementsInDOM;
  },
};

export default Page;
