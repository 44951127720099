








































import { Component, Vue, Watch } from "vue-property-decorator";
import { PageModel } from "@/models/Configuration";
import Button from "@/components/ui/Button.vue";
import InputText from "@/components/ui/InputText.vue";
import OptionTitle from "@/components/ui/OptionTitle.vue";
import PopupHeading from "@/components/ui/PopupHeading.vue";
import Id from "@/helpers/id";
import Page from "@/helpers/page";

@Component({
  components: {
    Button,
    InputText,
    OptionTitle,
    PopupHeading,
  },
})
export default class EditParameter extends Vue {
  createNewParameter = false;

  set(value: string, attributeName: "name" | "id") {
    const settings = this.parameterSettings;
    if (attributeName === "name") {
      value = value.replace(/\s+/g, "-").toLowerCase();
    }
    settings[attributeName] = value;
    this.$store.commit("SET_POPUP_PARAMETERS", settings);
  }

  saveParameter(): void {
    this.$store.commit("SET_PARAMETER", this.parameterSettings);
    this.close();
  }

  deleteParameter(): void {
    if (confirm("Are you sure you want to delete this parameter?"))
      this.$store.commit("DELETE_PARAMETER", this.parameterSettings.id);
    else return;
    this.close();
  }

  close(): void {
    this.$store.commit("SET_POPUP_PARAMETERS", {
      name: "",
      id: "",
      isOpen: false,
    });
  }

  mounted(): void {
    if (!this.parameterSettings.name || !this.parameterSettings.id) {
      this.createNewParameter = true;
      const id = Id.create(this.parameters);
      this.set(id, "id");
    }
  }

  get parameters() {
    const page = Page.get();
    if (!page || !page.parameters) return [];
    else return page.parameters;
  }

  get parameterSettings(): { id: string; name: string; isOpen: boolean } {
    return this.$store.state.popup.parameterSettings;
  }

  get isValidParameter(): boolean {
    if (this.parameterSettings.id && this.parameterSettings.name) return true;
    else return false;
  }

  get hasDeleteOption(): boolean {
    if (this.createNewParameter) return false;
    else return true;
  }
}
