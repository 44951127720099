

































































import { Component, Vue, Watch } from "vue-property-decorator";
import SelectedElement from "@/components/tab/element/SelectedElement.vue";
import OnClick from "@/components/tab/element/OnClick.vue";
import OnChange from "@/components/tab/element/OnChange.vue";
import Visibility from "@/components/tab/element/Visibility.vue";
import Style from "@/components/tab/element/Style.vue";
import Class from "@/components/tab/element/Class.vue";
import List from "@/components/tab/element/List.vue";
import TextBlock from "@/components/tab/element/TextBlock.vue";
import Value from "@/components/tab/element/Value.vue";
import HTMLAttribute from "@/components/tab/element/HTMLAttribute.vue";
import Parameters from "@/components/tab/element/Parameters.vue";
import Page from "@/helpers/page";
import { ElementModel, PageModel } from "@/models/Configuration";
import ElementHelper from "@/helpers/element";

@Component({
  components: {
    SelectedElement,
    OnClick,
    OnChange,
    Visibility,
    Style,
    Class,
    List,
    TextBlock,
    Value,
    HTMLAttribute,
    Parameters,
  },
})
export default class Element extends Vue {
  get isSelected(): boolean {
    return Object.keys(this.$store.state.element.selected).length !== 0;
  }

  @Watch("selected", { deep: true })
  checkForUnfoundElementConnections(): void {
    //console.log(this.selected);

    const page = Page.get();
    const elements = (page as PageModel)?.elements;
    if (!elements) return;
    const elementDOM = document.querySelector(this.selected.queryString);
    const oldQueryString = ElementHelper.getOldQueryString(elementDOM);
    const olderQueryString = ElementHelper.getOlderQueryString(elementDOM);
    const newQueryString = ElementHelper.getQueryString(elementDOM);
    const oldConnection = elements.find(
      (element) =>
        element.elementQueryString === oldQueryString ||
        element.elementQueryString === olderQueryString
    );
    if (oldConnection) {
      this.$store.commit("DELETE_ELEMENT", oldConnection);
      const updatedConnection: ElementModel = {
        elementQueryString: newQueryString,
        connections: oldConnection.connections,
      };
      this.$store.commit("SET_ELEMENT", updatedConnection);
    }
  }

  get selectedTagName(): string {
    if (Object.keys(this.$store.state.element.selected).length !== 0)
      return this.$store.state.element.selected.tagName;
    else return "";
  }

  get selected() {
    return this.$store.state.element.selected;
  }
}
