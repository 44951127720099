






import Vue from "vue";
import WebFont from "webfontloader";
import VueRouter, { RouteConfig } from "vue-router";
import Token from "@/helpers/token";

export default Vue.extend({
  methods: {
    loadWebfont() {
      WebFont.load({
        google: {
          families: [
            "Poppins:100,200,300,regular,500,600,700,800,900",
            "Roboto Mono:300,regular,500,600,700",
          ],
        },
      });
    },
  },
  mounted() {
    if (Token.getAdminToken()) this.$router.push({ name: "configurator" });
    else this.$router.push({ name: "display" });
    this.loadWebfont();
  },
});
