import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//import VueCustomElement from "vue-custom-element";
import envConfig from "@/envConfig";
import History from "@/helpers/history";
import API from "@/helpers/api";

Vue.config.productionTip = process.env.NODE_ENV === "development" ? false : true;

Vue.prototype.$config = envConfig;

const rootElement = document.querySelector("wized");
if (rootElement) {
  const projectId = rootElement.getAttribute("projectid");
  store.commit("SET_PROJECT", { id: projectId });
  History.init();
}

(window as any).Wized = API;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("wized");
