













































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class PreviewSettings extends Vue {
  toggleFullScreenPreview(): void {
    if (this.$store.state.configuration.preview.isFullScreen)
      this.$store.commit("SET_PREVIEW", { isFullScreen: false });
    else this.$store.commit("SET_PREVIEW", { isFullScreen: true });
  }
  openPreviewSettings(): void {
    this.$store.commit("SET_POPUP_PREVIEW", {
      isOpen: true,
      preview: this.$store.state.configuration.preview.settings,
    });
  }
  get hostname(): string {
    return window.location.hostname;
  }
  get path(): string {
    return (location.pathname + location.search).substr(1);
  }
}
