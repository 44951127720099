














































































































import { Component, Vue, Watch } from "vue-property-decorator";
import Variable from "@/components/ui/Variable.vue";

const Props = Vue.extend({
  props: {
    width: {
      type: Number,
      default: 120,
    },
    openWidth: {
      type: Number,
      default: 120,
    },
    type: {
      type: String,
      default: "default",
      validator: (value) => {
        return ["default", "variables"].indexOf(value) !== -1;
      },
    },
    initialValueIds: {
      type: Array,
      required: true,
    },
    selectValueGroups: {
      type: Array,
      required: true,
      validator: (value) => {
        value.forEach((value: any) => {
          if (Object.keys(value).length != 2 || !value.name || value.values.length < 1) {
            return false;
          }
          value.values.forEach((value: any) => {
            if (Object.keys(value).length != 2 || !value.id || !value.name) {
              return false;
            }
          });
        });
        return true;
      },
    },
  },
});

@Component({
  components: {},
})
export default class InputMultiSelect extends Props {
  values: Array<string> = [];
  search = "";
  isOpen = false;
  componentId = "";

  toggleOpen(value: boolean): void {
    this.isOpen = value;
    // TODO: Change to on display change of element
    setTimeout(() => {
      this.positionDropdown();
    }, 1);
  }

  @Watch("initialValueIds")
  onInitialValueChanged(values: Array<string>): void {
    this.values = values;
  }

  select(valueId: string): void {
    this.values.push(valueId);
    this.$emit("change", this.values);
  }

  unselect(valueId: string): void {
    const index = this.values.findIndex((value) => value === valueId);
    if (index === 0 || index) {
      if (this.values.length === 1) this.values = [];
      else this.values.splice(index, 1);
      this.$emit("change", this.values);
    }
  }

  get searchedSelectValueGroups() {
    if (!this.search) return this.selectValueGroups;
    const result: any = [];
    this.selectValueGroups.forEach((group: any) => {
      group.values.forEach((value: any) => {
        if (value.name.toLowerCase().includes(this.search.toLowerCase())) {
          let groupIndex = result.findIndex((group: any) => group.name === group.name);
          if (groupIndex !== -1) {
            result[groupIndex].values.push(value);
          } else {
            result.push({ name: group.name, values: [value] });
          }
        }
      });
    });
    return result;
  }

  get value(): { id: string; name: string } {
    let foundValue = {
      id: "",
      name: "",
    };
    this.selectValueGroups.forEach((group: any) => {
      group.values.forEach((value: any) => {
        if (value.id === this.values[0]) foundValue = value;
      });
    });
    return foundValue;
  }

  positionDropdown(): void {
    // If wrapper is at lease 300px away from top of window and value is selected
    let selectedElement = this.$refs["value_" + this.value.id];
    if (
      (this.$refs["wrapper"] as any).getBoundingClientRect().top >= 300 &&
      Array.isArray(selectedElement)
    ) {
      selectedElement = selectedElement[0];
      // selectedElemwentToDropdownInPixel = How far the selected element is from top of the window - how for the dropdown element is from the top of the window
      const selectedElementToDropdownTopInPixel =
        (selectedElement as any).getBoundingClientRect().top -
        (this.$refs["dropdown"] as any).getBoundingClientRect().top;
      // Set css top of drowdown
      (this.$refs["dropdown"] as any).style.top = 0 - selectedElementToDropdownTopInPixel + "px";
    } else {
      // Set css top of drowdown
      (this.$refs["dropdown"] as any).style.top = 0 + "px";
    }
  }

  mounted(): void {
    (this.$refs["dropdown"] as any).addEventListener("visibilitychange", this.positionDropdown());
    this.values = this.$props.initialValueIds ? this.$props.initialValueIds : [];
  }
}
