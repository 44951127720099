

































































import { Component, Vue, Watch } from "vue-property-decorator";
import ElementToolkit from "@/helpers/element";
import ElementModel from "@/models/Element";
import Page from "@/helpers/page";
import { PageModel } from "@/models/Configuration";
import ElementHelper from "@/helpers/element";
import Info from "@/components/ui/tooltips/Info.vue";

const Props = Vue.extend({
  props: {
    element: {
      type: Object,
      validator: (value: any) => {
        if (value.tagName && value.queryString && Array.isArray(value.children)) return true;
        else return false;
      },
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
    hasSettings: {
      type: Boolean,
      default: false,
    },
  },
});

@Component({
  components: { Info },
})
export default class Element extends Props {
  isHovering = false;

  get elementDisplay() {
    return ElementToolkit.formatForDisplay(this.element);
  }

  hover(isHovering: boolean): void {
    this.isHovering = isHovering;
    if (isHovering) this.$store.commit("SET_HOVERED_ELEMENT", this.element);
    else this.$store.commit("SET_HOVERED_ELEMENT", {});
  }

  click(): void {
    this.$store.commit("SET_SELECTED_ELEMENT", this.element);
  }

  get parents(): Array<string> {
    const result: Array<string> = [];
    this.element.parents.forEach((value: string) => {
      if (
        value !== "#wized > div" &&
        value !== "#wized > div > .pw---full-page-wrapper:nth-of-type(2)"
      ) {
        result.push(value);
      }
    });
    return result;
  }
}
