import Cookie from "./cookie";
import Parameter from "./parameter";

const Token = {
  getAdminToken: (): string => {
    const tokenName = "pw-t";
    saveToken(tokenName);
    const token = Cookie.get(tokenName);
    Parameter.delete(tokenName);
    return token;
  },
  getUserToken: (): string => {
    const tokenName = "pw--u";
    const token = Cookie.get(tokenName);
    Parameter.delete(tokenName);
    return token;
  },
};

export default Token;

const saveToken = (tokenName: string): void => {
  const token = Parameter.get(tokenName);
  if (token) {
    Cookie.set(tokenName, token, 1);
  }
};

const isPreview = (): boolean => {
  const url = new URL(window.location.href);
  const preview = url.searchParams.get("preview");
  if (preview) {
    return true;
  } else return false;
};
