








































































import { Component, Vue, Watch } from "vue-property-decorator";
import Button from "@/components/ui/Button.vue";
import Variable from "@/components/ui/Variable.vue";
import ResourceModel from "@/models/Resource";
import ResponseModel from "@/models/Response";
import Request from "@/helpers/request";
import LoadingSpinner from "@/components/misc/LoadingSpinner.vue";
import RequestData from "@/components/tab/data/RequestData.vue";
import draggable from "vuedraggable";
import Authentication from "@/helpers/authentication";

const Props = Vue.extend({
  props: {
    name: {
      type: String,
      required: true,
    },
    requestType: {
      type: String,
      required: true,
    },
    reqData: {
      type: Object,
      required: true,
    },
    loginType: {
      type: String,
    },
    pathName: {
      type: String,
    },
  },
});

@Component({
  components: { Button, Variable, LoadingSpinner, RequestData, draggable },
})
export default class RequestUserRuntime extends Props {
  requestData: ResponseModel = {
    isRequesting: false,
    hasRequested: false,
    statusCode: 0,
    errorMessage: "",
    data: {},
  };

  runRequest(): void {
    if (this.name === "Load user") Request.loadPreviewUser();
    else if (
      this.name === "Login user" &&
      (this.loginType === "emailPassword" || this.loginType === "magicLink")
    )
      Authentication.loginUser(this.loginType);
    else if (this.name === "Request password reset") Authentication.requestPasswordReset();
    else if (this.name === "Reset password") Authentication.resetPassword();
  }

  clone(attributes: {
    name: string;
    type: string;
    path: string;
  }): { name: string; type: string; path: string } {
    return {
      name: attributes.name,
      type: attributes.type,
      path: attributes.path,
    };
  }

  checkMove(event: any) {
    if (event.from === event.to) return false;
    else true;
  }

  setIsDragging(value: boolean): void {
    this.$store.commit("SET_HELPER_ISDRAGGINGVARIABLE", value);
  }

  get state(): Array<{
    id: string;
    name: string;
    type: string;
    path: string;
  }> {
    return [
      {
        name: "isRequesting",
        id: "isRequesting",
        path: this.pathName + ".state." + "isRequesting",
        type: "state",
      },
      {
        name: "hasRequested",
        id: "hasRequested",
        path: this.pathName + ".state." + "hasRequested",
        type: "state",
      },
      {
        name: "statusCode",
        id: "statusCode",
        path: this.pathName + ".state." + "statusCode",
        type: "state",
      },
    ];
  }
}
