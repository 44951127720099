
















































































import { Component, Vue, Watch } from "vue-property-decorator";
import { PageModel } from "@/models/Configuration";
import ElementToolkit from "@/helpers/element";
import Page from "@/helpers/page";
import Button from "@/components/ui/Button.vue";
import Info from "@/components/ui/tooltips/Info.vue";
import { ElementModel } from "@/models/Configuration";

@Component({
  components: { Button, Info },
})
export default class SelectedElement extends Vue {
  isHovering = false;

  openLesson() {
    window.open("https://www.wized.io/lesson/all-element-settings", "_blank")?.focus();
  }

  hover(isHovering: boolean) {
    this.isHovering = isHovering;
  }

  get selectedElement(): { tagName: string; identifier: string; id: string } {
    if (Object.keys(this.$store.state.element.selected).length > 0) {
      return ElementToolkit.formatForDisplay(this.$store.state.element.selected);
    } else {
      return {
        tagName: "-",
        identifier: "",
        id: "",
      };
    }
  }

  get element(): ElementModel | undefined | null {
    if (!this.selectedElementQuery) return undefined;
    const DOMElement = document.querySelector(this.selectedElementQuery);
    return Page.domElemementsConfig()?.find((element: any) => element.DOMElement === DOMElement)
      ?.element;
  }

  get selectedElementQuery(): string {
    const element = this.$store.state.element.selected;
    if (element && element.queryString) return element.queryString;
    else return "";
  }

  get hasSettings(): boolean {
    return Boolean(this.element);
  }
}
