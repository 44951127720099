import Vue from "vue";
import Vuex from "vuex";
import { emptyUser, UserModel } from "../models/User";
import ElementModel from "../models/Element";
import { ElementModel as ElementConnectionModel } from "../models/Configuration";
import { PageModel } from "../models/Configuration";
import ResourceModel from "../models/Resource";
import { emptyProject } from "../models/Project";
import Configuration from "@/helpers/configuration";
import RequestModel from "@/models/Request";
import StateModel from "@/models/State";
import merge from "deepmerge";
import Page from "@/helpers/page";
import ResponseModel from "@/models/Response";
import HistoryModel from "@/models/History";
import AlteredElementModel from "@/models/Render";
import Render from "@/helpers/render";
import ErrorModel from "@/models/Error";
import configuration from "@/helpers/configuration";
import API from "@/helpers/api";

Vue.use(Vuex);

const state: StateModel = {
  user: {
    loggedIn: false,
    data: emptyUser,
  },
  tab: {
    isOpen: false,
    tabName: "requests" || "data" || "page-tree" || "auth" || "pages" || "settings",
  },
  popup: {
    publish: {
      isOpen: false,
    },
    dataSelector: {
      isOpen: false,
    },
    pageSettings: {
      isOpen: false,
      page: undefined,
    },
    previewSettings: {
      isOpen: false,
      preview: {
        viewAsUser: false,
        userEmail: "",
        parameters: [],
      },
    },
    resourceSettings: {
      isOpen: false,
      resource: undefined,
    },
    requestSettings: {
      isOpen: false,
      request: undefined,
      airtableBases: [],
      airtableBaseStructure: [],
      airtableBasesLoading: false,
      airtableBaseStructureLoading: false,
      resourceProducts: [],
      resourceTaxes: [],
      resourceProductsLoading: false,
    },
    parameterSettings: {
      isOpen: false,
      name: "",
      id: "",
    },
    variableSettings: {
      isOpen: false,
      name: "",
      id: "",
    },
    cookieSettings: {
      isOpen: false,
      name: "",
      id: "",
    },
    filterSettings: {
      isOpen: false,
      path: "",
    },
    sortSettings: {
      isOpen: false,
      path: "",
    },
  },
  configuration: {
    pages: [],
    resources: [],
    cookies: [],
    settings: {
      hasBranding: false,
      login: {
        isEnabled: false,
        userLocationValid: false,
        resourceId: "",
        baseId: "",
        tableId: "",
        fieldId: "",
      },
    },
    preview: {
      isFullScreen: false,
      settings: {
        viewAsUser: false,
        userEmail: "",
        parameters: [],
      },
    },
    deletedPages: [],
  },
  project: emptyProject,
  element: { selected: {}, hovered: {} },
  save: { isSaved: true, lastSaveSuccessTimestamp: 0 },
  helpers: {
    isDraggingVariable: false,
  },
  data: {
    requests: [],
    params: [],
    fields: [],
    variables: [],
    cookies: [],
    user: {
      isRequesting: false,
      hasRequested: false,
      statusCode: 0,
      errorMessage: "",
      data: undefined,
    },
    login: {
      isRequesting: false,
      hasRequested: false,
      statusCode: 0,
      errorMessage: "",
      data: undefined,
    },
    requestPasswordReset: {
      isRequesting: false,
      hasRequested: false,
      statusCode: 0,
      errorMessage: "",
      data: undefined,
    },
    resetPassword: {
      isRequesting: false,
      hasRequested: false,
      statusCode: 0,
      errorMessage: "",
      data: undefined,
    },
  },
  history: [],
  alteredElements: [],
  errors: [],
  pagesUpdating: false,
  upload: { id: "", visible: false },
};

export default new Vuex.Store({
  state: state,
  mutations: {
    SET_TAB(state: any, data: string) {
      if (data) {
        state.tab.isOpen = true;
        state.tab.tabName = data;
      } else {
        state.tab.isOpen = false;
        state.tab.tabName = "";
      }
    },
    SET_POPUP_DATASELECTOR(state: StateModel, data: boolean) {
      state.popup.dataSelector.isOpen = data;
    },
    SET_CONFIGURATION(state: any, data: any) {
      const mergedConfiguration = merge.all([state.configuration, data]);
      state.configuration = mergedConfiguration;
    },
    SET_ADMIN_USER(state: StateModel, data: UserModel) {
      state.user.loggedIn = Object.keys(data).length > 0 ? true : false;
      state.user.data = data;
    },
    SET_PROJECT(state: StateModel, data: any) {
      state.project = data;
    },
    SET_SELECTED_ELEMENT(state: StateModel, data: ElementModel) {
      Vue.set(state.element, "selected", data);
    },
    SET_HOVERED_ELEMENT(state: StateModel, data: ElementModel) {
      Vue.set(state.element, "hovered", data);
    },
    SET_POPUP_PUBLISH(state: StateModel, data: { isOpen: boolean }) {
      state.popup.publish.isOpen = data.isOpen;
    },
    SET_POPUP_PAGESETTINGS(state: StateModel, data: { page: PageModel; isOpen: boolean }) {
      state.popup.pageSettings.isOpen = data.isOpen;
      state.popup.pageSettings.page = data.page;
    },
    SET_POPUP_PARAMETERS(state: StateModel, data: { name: string; id: string; isOpen: boolean }) {
      state.popup.parameterSettings.isOpen = data.isOpen;
      state.popup.parameterSettings.name = data.name;
      state.popup.parameterSettings.id = data.id;
    },
    SET_POPUP_VARIABLES(state: StateModel, data: { name: string; id: string; isOpen: boolean }) {
      state.popup.variableSettings.isOpen = data.isOpen;
      state.popup.variableSettings.name = data.name;
      state.popup.variableSettings.id = data.id;
    },
    SET_POPUP_COOKIES(state: StateModel, data: { name: string; id: string; isOpen: boolean }) {
      state.popup.cookieSettings.isOpen = data.isOpen;
      state.popup.cookieSettings.name = data.name;
      state.popup.cookieSettings.id = data.id;
    },
    SET_POPUP_FILTER(state: StateModel, data: { path: string; isOpen: boolean }) {
      state.popup.filterSettings.isOpen = data.isOpen;
      state.popup.filterSettings.path = data.path;
    },
    SET_POPUP_SORT(state: StateModel, data: { path: string; isOpen: boolean }) {
      state.popup.sortSettings.isOpen = data.isOpen;
      state.popup.sortSettings.path = data.path;
    },
    SET_POPUP_PREVIEW(
      state: StateModel,
      data: {
        isOpen: boolean;
        preview: any;
      }
    ) {
      state.popup.previewSettings.isOpen = data.isOpen;
      state.popup.previewSettings.preview = data.preview;
    },
    SET_POPUP_RESOURCESETTINGS(
      state: StateModel,
      data: { resource: ResourceModel; isOpen: boolean }
    ) {
      state.popup.resourceSettings.isOpen = data.isOpen;
      Vue.set(state.popup.resourceSettings, "resource", data.resource);
    },
    SET_POPUP_REQUESTSETTINGS(state: StateModel, data: { request: RequestModel; isOpen: boolean }) {
      state.popup.requestSettings.isOpen = data.isOpen;
      state.popup.requestSettings.request = data.request;
    },
    SET_POPUP_REQUESTSETTINGS_AIRTABLE(
      state: StateModel,
      data: {
        airtableBases: Array<any>;
        airtableBaseStructure: Array<any>;
        airtableBasesLoading: boolean;
        airtableBaseStructureLoading: boolean;
      }
    ) {
      Vue.set(state.popup.requestSettings, "airtableBases", data.airtableBases);
      Vue.set(state.popup.requestSettings, "airtableBaseStructure", data.airtableBaseStructure);
      state.popup.requestSettings.airtableBasesLoading = data.airtableBasesLoading;
      state.popup.requestSettings.airtableBaseStructureLoading = data.airtableBaseStructureLoading;
    },
    SET_POPUP_REQUESTSETTINGS_STRIPE(
      state: StateModel,
      data: {
        resourceProducts: Array<any>;
        resourceTaxes: Array<any>;
        resourceProductsLoading: boolean;
      }
    ) {
      Vue.set(state.popup.requestSettings, "resourceProducts", data.resourceProducts);
      Vue.set(state.popup.requestSettings, "resourceTaxes", data.resourceTaxes);
      Vue.set(state.popup.requestSettings, "resourceProductsLoading", data.resourceProductsLoading);
    },
    SET_SETTINGS(state: StateModel, data: { hasBranding: boolean; login: any }) {
      state.configuration.settings.login = data.login;
      state.configuration.settings.hasBranding = data.hasBranding;
    },
    SET_PAGE(state: StateModel, data: PageModel) {
      const existingPageIndex = state.configuration.pages.findIndex(
        (page: PageModel) => page.id === data.id
      );
      const deletedPageIndex = state.configuration.deletedPages.findIndex(
        (page: string) => page === data.path
      );
      if (deletedPageIndex !== -1) state.configuration.deletedPages.splice(deletedPageIndex, 1);
      if (existingPageIndex !== -1) Vue.set(state.configuration.pages, existingPageIndex, data);
      else state.configuration.pages.push(data);
    },
    DELETE_PAGE(state: StateModel, pageId: string) {
      const existingPageIndex = state.configuration.pages.findIndex(
        (page: PageModel) => page.id === pageId
      );
      const pages = state.configuration.pages;
      const deletedPageIndex = state.configuration.deletedPages.findIndex(
        (page: string) => page === pages[existingPageIndex].path
      );
      if (deletedPageIndex === -1)
        state.configuration.deletedPages.push(pages[existingPageIndex].path);
      pages.splice(existingPageIndex, 1);
      Vue.set(state.configuration, "pages", pages);
    },
    SET_PARAMETER(state: StateModel, data: { id: string; name: string; isOpen: string }) {
      const page = Page.get();
      const existingPageIndex = page
        ? state.configuration.pages.findIndex((pageConfig: PageModel) => pageConfig.id === page.id)
        : -1;
      const existingIndex =
        page && page.parameters
          ? page.parameters.findIndex(
              (parameter: { name: string; id: string }) => parameter.id === data.id
            )
          : -1;
      if (existingIndex !== -1)
        Vue.set(state.configuration.pages[existingPageIndex].parameters, existingIndex, {
          name: data.name,
          id: data.id,
        });
      else {
        if (!state.configuration.pages[existingPageIndex].parameters) {
          state.configuration.pages[existingPageIndex].parameters = [
            {
              name: data.name,
              id: data.id,
            },
          ];
        } else
          state.configuration.pages[existingPageIndex].parameters.push({
            name: data.name,
            id: data.id,
          });
      }
    },
    SET_VARIABLE(state: StateModel, data: { id: string; name: string; isOpen: string }) {
      const page = Page.get();
      const existingPageIndex = page
        ? state.configuration.pages.findIndex((pageConfig: PageModel) => pageConfig.id === page.id)
        : -1;
      const existingIndex =
        page && page.variables
          ? page.variables.findIndex(
              (variable: { name: string; id: string }) => variable.id === data.id
            )
          : -1;
      if (existingIndex !== -1)
        Vue.set(state.configuration.pages[existingPageIndex].variables, existingIndex, {
          name: data.name,
          id: data.id,
        });
      else {
        if (!state.configuration.pages[existingPageIndex].variables) {
          state.configuration.pages[existingPageIndex].variables = [
            {
              name: data.name,
              id: data.id,
            },
          ];
        } else
          state.configuration.pages[existingPageIndex].variables.push({
            name: data.name,
            id: data.id,
          });
      }
    },
    SET_COOKIE(state: StateModel, data: { id: string; name: string; isOpen: string }) {
      const existingIndex = state.configuration.cookies.findIndex(
        (cookie: { name: string; id: string }) => cookie.id === data.id
      );
      if (existingIndex !== -1)
        Vue.set(state.configuration.cookies, existingIndex, {
          name: data.name,
          id: data.id,
        });
      else {
        state.configuration.cookies.push({
          name: data.name,
          id: data.id,
        });
      }
    },
    SET_REQUEST(state: StateModel, data: RequestModel) {
      const page = Page.get();
      const existingPageIndex = page
        ? state.configuration.pages.findIndex((pageConfig: PageModel) => pageConfig.id === page.id)
        : -1;
      const existingIndex =
        page && page.requests
          ? page.requests.findIndex((request: RequestModel) => request.id === data.id)
          : -1;
      if (existingIndex !== -1)
        Vue.set(state.configuration.pages[existingPageIndex].requests, existingIndex, data);
      else {
        if (!state.configuration.pages[existingPageIndex].requests) {
          state.configuration.pages[existingPageIndex].requests = [data];
        } else state.configuration.pages[existingPageIndex].requests.push(data);
      }
    },
    SET_ELEMENT(state: StateModel, element: ElementConnectionModel) {
      const page = Page.get();
      const existingPageIndex = page
        ? state.configuration.pages.findIndex((pageConfig: PageModel) => pageConfig.id === page.id)
        : -1;
      const existingIndex =
        page && page.elements
          ? page.elements.findIndex(
              (elementF: ElementConnectionModel) =>
                elementF.elementQueryString === element.elementQueryString
            )
          : -1;
      if (existingIndex !== -1) {
        Vue.set(state.configuration.pages[existingPageIndex].elements, existingIndex, element);
      } else {
        state.configuration.pages[existingPageIndex].elements.push(element);
      }
      Render.applyPageConfiguration();
    },
    DELETE_ELEMENT(state: StateModel, element: ElementConnectionModel) {
      const page = Page.get();
      if (!page) return;
      const existingPageIndex = page
        ? state.configuration.pages.findIndex((pageConfig: PageModel) => pageConfig.id === page.id)
        : -1;
      const existingIndex =
        page && page.elements
          ? page.elements.findIndex(
              (elementF: ElementConnectionModel) =>
                elementF.elementQueryString === element.elementQueryString
            )
          : -1;

      if (existingIndex !== -1) {
        const elements = page?.elements;
        elements.splice(existingIndex, 1);
        Vue.set(state.configuration.pages[existingPageIndex], "elements", elements);
      }
      Render.applyPageConfiguration();
    },
    SET_PAGE_SETTINGS(state: StateModel, settings: PageModel["settings"]) {
      const page = Page.get();
      const existingPageIndex = page
        ? state.configuration.pages.findIndex((pageConfig: PageModel) => pageConfig.id === page.id)
        : -1;
      Vue.set(state.configuration.pages[existingPageIndex], "settings", settings);
    },
    SET_RESOURCE(state: StateModel, data: ResourceModel) {
      if (Array.isArray(state.configuration.resources)) {
        const existingResourceIndex = state.configuration.resources.findIndex(
          (resource: ResourceModel) => resource.id === data.id
        );
        if (existingResourceIndex !== -1)
          Vue.set(state.configuration.resources, existingResourceIndex, data);
        else state.configuration.resources.push(data);
      } else {
        state.configuration.resources = [data];
      }
    },
    DELETE_PARAMETER(state: StateModel, parameterId: string) {
      const page = Page.get();
      const existingPageIndex = page
        ? state.configuration.pages.findIndex((pageConfig: PageModel) => pageConfig.id === page.id)
        : -1;
      const existingIndex =
        page && page.parameters
          ? page.parameters.findIndex(
              (parameter: { name: string; id: string }) => parameter.id === parameterId
            )
          : -1;
      const parameters = state.configuration.pages[existingPageIndex].parameters;
      parameters.splice(existingIndex, 1);
      Vue.set(state.configuration.pages[existingPageIndex], "parameters", parameters);
    },
    DELETE_VARIABLE(state: StateModel, variableId: string) {
      const page = Page.get();
      const existingPageIndex = page
        ? state.configuration.pages.findIndex((pageConfig: PageModel) => pageConfig.id === page.id)
        : -1;
      const existingIndex =
        page && page.variables
          ? page.variables.findIndex(
              (variable: { name: string; id: string }) => variable.id === variableId
            )
          : -1;
      const variables = state.configuration.pages[existingPageIndex].variables;
      variables.splice(existingIndex, 1);
      Vue.set(state.configuration.pages[existingPageIndex], "variables", variables);
    },
    DELETE_COOKIE(state: StateModel, cookieId: string) {
      const existingIndex = state.configuration.cookies
        ? state.configuration.cookies.findIndex(
            (cookie: { name: string; id: string }) => cookie.id === cookieId
          )
        : -1;
      const cookies = state.configuration.cookies;
      cookies.splice(existingIndex, 1);
      Vue.set(state.configuration, "cookies", cookies);
    },
    DELETE_REQUEST(state: StateModel, requestId: string) {
      const page = Page.get();
      const existingPageIndex = page
        ? state.configuration.pages.findIndex((pageConfig: PageModel) => pageConfig.id === page.id)
        : -1;
      const existingIndex =
        page && page.requests
          ? page.requests.findIndex((request: RequestModel) => request.id === requestId)
          : -1;
      const requests = state.configuration.pages[existingPageIndex].requests;
      requests.splice(existingIndex, 1);
      Vue.set(state.configuration.pages[existingPageIndex], "requests", requests);
    },
    DELETE_RESOURCE(state: StateModel, resourceId: string) {
      const existingResourceIndex = state.configuration.resources.findIndex(
        (resource: ResourceModel) => resource.id === resourceId
      );
      const resources = state.configuration.resources;
      resources.splice(existingResourceIndex, 1);
      Vue.set(state.configuration, "resources", resources);
    },
    SET_SAVE(state: StateModel, data: boolean) {
      state.save.isSaved = data;
      if (data) state.save.lastSaveSuccessTimestamp = Math.round(Date.now() / 1000);
    },
    SET_PUBLISH(state: StateModel, data: { main: boolean; staging: boolean }) {
      if (data.main) state.project.configPublished = state.project.config;
      if (data.staging) state.project.configPublishedStaging = state.project.config;
      state.project.lastPublishedTimestamp = Date.now();
    },
    SET_PREVIEW(state: StateModel, data: { isFullScreen: boolean; settings?: any }) {
      state.configuration.preview.isFullScreen = data.isFullScreen;
      if (data.settings) state.configuration.preview.settings = data.settings;
      Render.applyPageConfiguration();
    },
    SET_HELPER_ISDRAGGINGVARIABLE(state: StateModel, data: boolean) {
      state.helpers.isDraggingVariable = data;
    },
    SET_DATA_TYPE_VALUE(
      state: StateModel,
      data: {
        value:
          | {
              id: string;
              values: Array<any>;
            }
          | {
              queryString: string;
              value: string;
            }
          | {
              id: string;
              value: string;
            }
          | {
              id: string;
              value: ResponseModel;
            }
          | { [index: string]: any };
        type:
          | "requests"
          | "params"
          | "fields"
          | "variables"
          | "cookies"
          | "user"
          | "login"
          | "requestPasswordReset"
          | "resetPassword";
      }
    ) {
      if (
        data.type === "user" ||
        data.type === "login" ||
        data.type === "requestPasswordReset" ||
        data.type === "resetPassword"
      ) {
        state.data[data.type] = (data.value as unknown) as ResponseModel;
      } else {
        // Check if value exists in array
        const index = state.data[data.type].findIndex((value: any) => {
          if (data.type === "fields") return value.queryString === (data.value as any).queryString;
          else return value.id === (data.value as any).id;
        });
        if (index === -1) {
          if (data.type === "fields")
            state.data[data.type].push(data.value as { queryString: string; value: string });
          if (data.type === "requests")
            state.data[data.type].push(data.value as { id: string; value: ResponseModel });
          if (data.type === "params" || data.type === "variables" || data.type === "cookies")
            state.data[data.type].push(data.value as { id: string; value: string });
        } else {
          if (data.type === "fields")
            Vue.set(
              state.data[data.type],
              index,
              data.value as {
                queryString: string;
                value: string;
              }
            );
          if (data.type === "requests")
            Vue.set(
              state.data[data.type],
              index,
              data.value as {
                id: string;
                value: ResponseModel;
              }
            );
          if (data.type === "params" || data.type === "variables" || data.type === "cookies")
            Vue.set(
              state.data[data.type],
              index,
              data.value as {
                id: string;
                value: string;
              }
            );
        }
      }
      Render.applyPageConfiguration();
      API.trigger.dataUpdated();
    },
    RESET_DATA_TYPE(
      state: StateModel,
      type:
        | "requests"
        | "params"
        | "fields"
        | "variables"
        | "cookies"
        | "user"
        | "login"
        | "requestPasswordReset"
        | "resetPassword"
    ) {
      if (
        type === "user" ||
        type === "login" ||
        type === "requestPasswordReset" ||
        type === "resetPassword"
      )
        state.data[type] = {
          isRequesting: false,
          hasRequested: false,
          statusCode: 0,
          errorMessage: "",
          data: undefined,
        };
      else state.data[type] = [];
    },
    ADD_REQUEST_HISTORY(state: StateModel, messages: HistoryModel["messages"]) {
      state.history.unshift({ timestamp: Date.now(), messages: messages });
    },
    SET_ALTERED_ELEMENT(state: StateModel, log: AlteredElementModel) {
      state.alteredElements.push(log);
    },
    UPDATE_ALTERED_ELEMENT(state: StateModel, data: { id: string; log: AlteredElementModel }) {
      const index = state.alteredElements.findIndex((e) => e.id === data.id);
      Vue.set(state.alteredElements, index, data.log);
    },
    RESET_ALTERED_ELEMENTS(state: StateModel) {
      state.alteredElements = [];
    },
    SET_ERRORS(state: StateModel, errors: ErrorModel[]) {
      state.errors = errors;
    },
    SET_PAGES_UPDATE_LOADING(state: StateModel, status: boolean) {
      state.pagesUpdating = status;
    },
    TOGGLE_UPLOAD(state: StateModel, upload: { id: string; visible: boolean }) {
      state.upload = upload;
    },
  },
  actions: {
    async saveConfiguration({ state, commit }): Promise<void> {
      await Configuration.save();
      commit("SET_SAVE", true);
    },
  },
});
