


































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import RequestModel from "@/models/Request";
import Button from "@/components/ui/Button.vue";
import InputText from "@/components/ui/InputText.vue";
import InputSelect from "@/components/ui/InputSelect.vue";
import InputMultiSelect from "@/components/ui/InputMultiSelect.vue";
import OptionTitle from "@/components/ui/OptionTitle.vue";
import Toggle from "@/components/ui/Toggle.vue";
import InputAll from "@/components/ui/InputAll.vue";
import PopupHeading from "@/components/ui/PopupHeading.vue";
import BlockHeading from "@/components/ui/BlockHeading.vue";
import Id from "@/helpers/id";
import Page from "@/helpers/page";
import ResourceModel from "@/models/Resource";
import Request from "@/helpers/request";
import BaseTableView from "@/components/popup/editrequest/airtable/BaseTableView.vue";
import FilterSettings from "@/components/popup/editrequest/airtable/FilterSettings.vue";
import Sort from "@/components/popup/editrequest/airtable/Sort.vue";
import Limit from "@/components/popup/editrequest/airtable/Limit.vue";
import Values from "@/components/popup/editrequest/airtable/Values.vue";
import Checkout from "@/components/popup/editrequest/stripe/Checkout.vue";
import Portal from "@/components/popup/editrequest/stripe/Portal.vue";
import Rest from "@/components/popup/editrequest/rest/Rest.vue";
import ActionConfig from "@/components/misc/ActionConfig.vue";
import { OnClickConnectionModel } from "@/models/Configuration";

@Component({
  components: {
    Button,
    InputText,
    InputSelect,
    InputMultiSelect,
    InputAll,
    OptionTitle,
    Toggle,
    PopupHeading,
    BlockHeading,
    BaseTableView,
    FilterSettings,
    Sort,
    Limit,
    Values,
    Checkout,
    Portal,
    Rest,
    ActionConfig,
  },
})
export default class EditRequest extends Vue {
  createNewRequest = false;
  errors: Array<string> = [];
  isValidRequest = false;

  // ----------------------------------------------------------------

  setArray(value: any, index: number, attribute: string): void {
    const request = this.request;
    ((request.actions as OnClickConnectionModel[])[index] as any)[attribute] = value;
    this.$store.commit("SET_POPUP_REQUESTSETTINGS", {
      request: request,
      isOpen: true,
    });
  }

  addArrayItem(): void {
    const emptyAction: OnClickConnectionModel = {
      action: "setCookie",
      conditionalAction: false,
      condition: "",
      requestId: "",
      url: "",
      variableId: "",
      cookieId: "",
      value: "",
      cookieLifetime: "",
    };
    let request = this.request;
    const actions = this.request.actions || [];
    actions.push(emptyAction);
    request.actions = actions;
    this.$store.commit("SET_POPUP_REQUESTSETTINGS", {
      request: request,
      isOpen: true,
    });
  }

  deleteArrayItem(index: number): void {
    let request = this.request;
    const actions = this.request.actions || [];
    actions.splice(index, 1);
    request.actions = actions;
    this.$store.commit("SET_POPUP_REQUESTSETTINGS", {
      request: request,
      isOpen: true,
    });
  }

  //----------------------------------------------------------------

  mounted(): void {
    if (!this.request.name || !this.request.id) {
      this.createNewRequest = true;
      const page = Page.get();
      const request = this.request;
      request.id = Id.create(page && page.requests ? page.requests : []);
      this.$store.commit("SET_POPUP_REQUESTSETTINGS", {
        request: request,
        isOpen: true,
      });
    }

    if (this.request.resourceId && this.resourceIsAirtable) {
      this.loadAirtableBases();
    }
  }

  set(
    value: any,
    attributeName:
      | "name"
      | "resourceId"
      | "type"
      | "executeOnCondition"
      | "executeOnPageLoad"
      | "executeAfterRequests"
  ): void {
    const request = this.request;
    (request[attributeName] as any) = value;
    this.$store.commit("SET_POPUP_REQUESTSETTINGS", {
      request: request,
      isOpen: true,
    });
  }

  saveRequest(): void {
    this.$store.commit("SET_REQUEST", this.request);
    this.close();
  }

  deleteRequest(): void {
    // TODO: Check if there are no more connections or requests on the page
    if (confirm("Are you sure you want to delete this request?"))
      this.$store.commit("DELETE_REQUEST", this.request.id);
    else return;
    this.close();
  }

  close(): void {
    this.$store.commit("SET_POPUP_REQUESTSETTINGS", {
      request: {},
      isOpen: false,
    });
  }

  loadAirtableBases(): void {
    if (this.selectedResource.type === "airtable")
      Request.loadAirtableBases(this.selectedResource.apiKey ? this.selectedResource.apiKey : "");
  }

  get requestTypes() {
    let types: Array<any> = [];
    if (!this.selectedResource) {
      return [];
    }
    if (this.selectedResource.type === "airtable")
      types = [
        { name: "Get item list", id: "list" },
        { name: "Get item", id: "get" },
        { name: "Create item", id: "create" },
        { name: "Update item", id: "update" },
        { name: "Delete item", id: "delete" },
      ];
    if (this.selectedResource.type === "stripe")
      types = [
        { name: "Open checkout", id: "checkout" },
        { name: "Open customer portal", id: "customer-portal" },
      ];
    if (this.selectedResource.type === "rest")
      types = [
        { name: "GET", id: "get" },
        { name: "POST", id: "post" },
        { name: "PUT", id: "put" },
        { name: "PATCH", id: "patch" },
        { name: "DELETE", id: "delete" },
      ];
    return [
      {
        name: "Types",
        values: types,
      },
    ];
  }

  get resources(): any {
    const resources: any = [];
    this.$store.state.configuration.resources.forEach((resource: any) => {
      resources.push({ id: resource.id, name: resource.name });
    });
    resources.push({ id: "file-upload", name: "File Upload (Beta)" });
    return [
      {
        name: "Resources",
        values: resources,
      },
    ];
  }

  get selectedResource(): ResourceModel {
    const selectedResourceId = this.request.resourceId;
    if (selectedResourceId === "file-upload")
      return { id: "file-upload", name: "File Upload", type: "file-upload" };
    const resource = this.$store.state.configuration.resources.find(
      (resource: ResourceModel) => resource.id === this.request.resourceId
    );
    return resource;
  }

  get request(): RequestModel {
    return JSON.parse(JSON.stringify(this.$store.state.popup.requestSettings.request));
  }

  @Watch("request", { deep: true })
  async evaluateRequest(): Promise<void> {
    const isValid = await Request.isValid(this.request).catch((errors: Array<string>) => {
      this.errors = errors;
      this.isValidRequest = false;
      return;
    });

    if (isValid) {
      this.errors = [];
      this.isValidRequest = true;
    } else this.isValidRequest = false;
  }

  get hasDeleteOption(): boolean {
    if (this.createNewRequest) return false;
    else return true;
  }

  get resourceIsAirtable(): any {
    return (
      this.request &&
      this.request.tableId &&
      this.selectedResource &&
      this.selectedResource.type === "airtable" &&
      !this.$store.state.popup.requestSettings.airtableBasesLoading &&
      !this.$store.state.popup.requestSettings.airtableBaseStructureLoading
    );
  }

  get resourceIsStripe(): any {
    return this.selectedResource && this.selectedResource.type === "stripe";
  }

  get resourceIsRest(): any {
    return this.selectedResource && this.selectedResource.type === "rest";
  }

  get requests(): Array<any> {
    const requests: { id: string; name: string }[] = [];
    Page.get()?.requests.forEach((request) => {
      if (request.id !== this.request.id) requests.push({ id: request.id, name: request.name });
    });
    return [{ name: "Request", values: requests }];
  }

  get endpoint(): string {
    try {
      JSON.parse(this.request.endpoint as string);
    } catch {
      return JSON.stringify([
        {
          name: "text",
          type: "editable",
          value: this.request.endpoint,
          path: "",
        },
      ]);
    }
    return this.request.endpoint || "[]";
  }
}
