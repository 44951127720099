var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pw---tooltip-content",style:({
    backgroundColor: _vm.backgroundColor,
    'z-index': 2,
    height: _vm.height ? _vm.height + 'px' : '',
    left: _vm.offsetLeft ? _vm.offsetLeft + 'px' : '',
    top: _vm.offsetTop ? _vm.offsetTop + 'px' : '',
    position: _vm.width !== 0 && _vm.height !== 0 && _vm.offsetTop !== 0 && _vm.offsetLeft !== 0 ? 'fixed' : '',
  })},[_c('div',{staticClass:"pw---tooltip-wrapper"},[(_vm.resource && _vm.resource.id)?_c('div',{staticClass:"pw---block-flex-hor pw---60"},[_c('img',{staticClass:"pw---icon-13 pw---50 pw---right-5",attrs:{"src":_vm.$config.configuratorUrl +
          'images/' +
          (_vm.resource.type === 'airtable'
            ? 'Airtable.svg'
            : _vm.resource.type === 'rest'
            ? 'API.svg'
            : 'Stripe.svg'),"loading":"lazy","alt":""}}),_c('div',{staticClass:"pw---p-small"},[_vm._v(_vm._s(_vm.request.name))])]):_vm._e(),(_vm.resource && _vm.resource.id)?_c('div',{staticClass:"pw---seperator pw---light pw---top-bottom-10 pw----super-light"}):_vm._e(),_c('div',{staticClass:"pw---block-flex-hor pw---bottom-5",staticStyle:{"flex-wrap":"wrap"}},[_c('div',{staticClass:"pw---p-small pw---60 pw---right-5"},[_vm._v("Value:")]),_c('div',{staticClass:"pw---p-small",staticStyle:{"overflow-wrap":"anywhere","width":"100%"}},[_vm._v(" "+_vm._s(_vm.value != "undefined" ? _vm.value : _vm.computedValue)+" ")])]),_c('div',{staticClass:"pw---block-flex-hor pw---bottom-5",staticStyle:{"flex-wrap":"wrap"}},[_c('div',{staticClass:"pw---p-small pw---60 pw---right-5"},[_vm._v("Name:")]),_c('div',{staticClass:"pw---p-small",staticStyle:{"overflow-wrap":"anywhere","width":"100%"}},[_vm._v(" "+_vm._s(_vm.name)+" ")])]),(_vm.type)?_c('div',{staticClass:"pw---block-flex-hor"},[_c('div',{staticClass:"pw---p-small pw---60"},[_vm._v("Type:")]),_c('div',{staticClass:"pw---p-small pw---left-5"},[_vm._v(" "+_vm._s(_vm.getTypeOf(_vm.value != "undefined" ? _vm.value : _vm.computedValue))+" ")])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }