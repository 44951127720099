







import { Component, Vue, Watch } from "vue-property-decorator";

const Props = Vue.extend({
  props: {
    text: {
      type: String,
      required: true,
    },
  },
});

@Component({})
export default class EmptyAdd extends Props {
  click(): void {
    this.$emit("click");
  }
}
