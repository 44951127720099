
























































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import OptionTitle from "@/components/ui/OptionTitle.vue";
import BlockHeading from "@/components/ui/BlockHeading.vue";
import InputSelect from "@/components/ui/InputSelect.vue";
import InputAll from "@/components/ui/InputAll.vue";
import Button from "@/components/ui/Button.vue";
import {
  CookieModel,
  ElementModel,
  OnClickConnectionModel,
  PageModel,
} from "@/models/Configuration";
import Page from "@/helpers/page";
import RequestModel from "@/models/Request";
import Configuration from "@/helpers/configuration";
import Toggle from "@/components/ui/Toggle.vue";

const Props = Vue.extend({
  props: {
    connection: {
      type: Array,
      required: true,
    },
    hideRequests: {
      type: Boolean,
      default: false,
    },
  },
});

@Component({
  components: {
    OptionTitle,
    BlockHeading,
    InputSelect,
    InputAll,
    Button,
    Toggle,
  },
})
export default class OnClick extends Props {
  setArray(value: any, index: number, attribute: string): void {
    this.$emit("setArray", { value, index, attribute });
  }

  addArrayItem(): void {
    this.$emit("addArrayItem");
  }

  deleteArrayItem(index: number): void {
    this.$emit("deleteArrayItem", index);
  }

  get requests(): Array<{ id: string; name: string }> {
    const page = Page.get();
    const requestsRaw = Page.get()?.requests;
    const requestsFormattet: Array<{ id: string; name: string }> = [];
    requestsRaw?.forEach((request) =>
      requestsFormattet.push({ id: request.id, name: request.name })
    );
    return requestsFormattet;
  }

  get variables(): Array<{ id: string; name: string }> {
    const page = Page.get();
    const variablesRaw = Page.get()?.variables;
    const variablesFormattet: Array<{ id: string; name: string }> = [];
    variablesRaw?.forEach((variable) =>
      variablesFormattet.push({ id: variable.id, name: variable.name })
    );
    return variablesFormattet;
  }

  get cookies(): Array<{ id: string; name: string }> {
    const cookiesRaw = this.$store.state.configuration.cookies;
    const cookiesFormattet: Array<{ id: string; name: string }> = [];
    cookiesRaw?.forEach((cookie: CookieModel) =>
      cookiesFormattet.push({ id: cookie.id, name: cookie.name })
    );
    return cookiesFormattet;
  }

  get fieldConfiguration() {
    const configuration = {
      requests: [{ name: "Requests", values: this.requests }],
      variables: [{ name: "Variables", values: this.variables }],
      cookies: [{ name: "Cookies", values: this.cookies }],
      actions: [
        {
          name: "Actions",
          values: this.hideRequests
            ? [
                { id: "navigate", name: "Navigate to" },
                { id: "setVariable", name: "Set variable" },
                { id: "setCookie", name: "Set cookie" },
              ]
            : [
                { id: "request", name: "Perform request" },
                { id: "navigate", name: "Navigate to" },
                { id: "setVariable", name: "Set variable" },
                { id: "setCookie", name: "Set cookie" },
              ],
        },
      ],
      cookieLifetimes: [
        {
          name: "Cookie lifetimes",
          values: [
            { id: "1h", name: "1 hour" },
            { id: "1d", name: "1 day" },
            { id: "1w", name: "1 week" },
            { id: "1m", name: "1 month" },
            { id: "3m", name: "3 months" },
          ],
        },
      ],
    };
    return configuration;
  }
}
