import ElementModel from "@/models/Element";
import store from "@/store";

const Element = {
  format: (element: HTMLElement): ElementModel => {
    return {
      queryString: Element?.getQueryString(element),
      tagName: element?.tagName.toLowerCase(),
      id: element?.id,
      classes: element?.className,
      parents: Element?.getParentsOfElement(element),
      children: Element?.getChildNodes(element),
    };
  },
  formatForDisplay: (
    element: ElementModel
  ): { tagName: string; identifier: string; id: string; classes: string } => {
    const tagName = element.tagName;
    const id = element.id.length > 15 ? element.id.slice(0, 15) + "..." : element.id;
    const classes =
      element.classes.length > 15 ? element.classes.slice(0, 15) + "..." : element.classes;
    const identifier = element.classes ? "." + classes : "";
    const _id = element.queryString;
    const classessFull = element.classes ? "." + element.classes : "";
    return { tagName, identifier, id: _id, classes: classessFull };
  },
  getParentsOfElement: (element: HTMLElement): Array<string> => {
    const result = [];
    while (
      element &&
      element.nodeName !== "#document" &&
      element.id !== "wized" &&
      element.nodeName !== "HTML"
    ) {
      result.unshift(Element.getQueryString(element));
      element = element.parentNode as HTMLElement;
    }
    result.pop();
    return result;
  },
  getQueryString: (element: HTMLElement): string => {
    let result = "";

    const loop = (element: HTMLElement) => {
      if (element?.nodeType === Node.ELEMENT_NODE) {
        /* Script from Stackoverflow  */

        //stop here = element has ID
        if (element.getAttribute("id")) {
          result = result.replace(/^/, " #" + element.getAttribute("id"));
          result = result.replace(/\s/, "");
          result = result.replace(/\s/g, " > ");
          return result;
        }

        // stop here = element is body
        if (document.body === element) {
          result = result.replace(/^/, " body");
          result = result.replace(/\s/, "");
          result = result.replace(/\s/g, " > ");
          return result;
        }

        // concat all classes in "queryselector" style
        if (element.getAttribute("class")) {
          let elemClasses = ".";
          elemClasses += element.getAttribute("class");
          elemClasses = elemClasses.replace(/\s/g, ".");
          elemClasses = elemClasses.replace(/^/g, " ");
          let classNth = "";

          // check if element class is the unique child
          const childrens = element.parentNode?.children || [];

          const similarElements: Array<HTMLElement> = [];
          for (let i = 0; i < childrens.length; i++) {
            if (
              element.getAttribute("class") == childrens[i].getAttribute("class") ||
              element.tagName == childrens[i].tagName
            ) {
              similarElements.push(childrens[i] as HTMLElement);
            }
          }
          if (similarElements.length > 1) {
            for (let j = 0; j < similarElements.length; j++) {
              if (element === similarElements[j]) {
                j++;
                classNth =
                  (similarElements.filter((elementSim) => elementSim.tagName === element.tagName)
                    .length === similarElements.length
                    ? ":nth-of-type("
                    : ":nth-child(") +
                  j +
                  ")";
                break;
              }
            }
          }

          result = result.replace(/^/, elemClasses + classNth);
        } else {
          // get nodeType
          let name = element.nodeName;
          name = name.toLowerCase();
          let nodeNth = "";

          const childrens = element.parentNode?.children || [];

          if (childrens.length > 1) {
            const similarNodes = [];

            for (let i = 0; i < childrens.length; i++) {
              if (element.nodeName == childrens[i].nodeName) {
                similarNodes.push(childrens[i]);
              }
            }

            if (similarNodes.length > 1) {
              for (let j = 0; j < similarNodes.length; j++) {
                if (element === similarNodes[j]) {
                  j++;
                  nodeNth = ":nth-of-type(" + j + ")";
                  break;
                }
              }
            }
          }

          result = result.replace(/^/, " " + name + nodeNth);
        }

        if (element.parentNode) {
          loop(element.parentNode as HTMLElement);
        } else {
          result = result.replace(/\s/g, " > ");
          result = result.replace(/\s/, "");
          return result;
        }
      } else {
        result = "";
      }
    };

    loop(element);

    return result;
  },
  getOldQueryString: (element: HTMLElement): string => {
    let result = "";

    const loop = (element: HTMLElement) => {
      if (element?.nodeType === Node.ELEMENT_NODE) {
        /* Script from Stackoverflow  */

        //stop here = element has ID
        if (element.getAttribute("id")) {
          result = result.replace(/^/, " #" + element.getAttribute("id"));
          result = result.replace(/\s/, "");
          result = result.replace(/\s/g, " > ");
          return result;
        }

        // stop here = element is body
        if (document.body === element) {
          result = result.replace(/^/, " body");
          result = result.replace(/\s/, "");
          result = result.replace(/\s/g, " > ");
          return result;
        }

        // concat all classes in "queryselector" style
        if (element.getAttribute("class")) {
          let elemClasses = ".";
          elemClasses += element.getAttribute("class");
          elemClasses = elemClasses.replace(/\s/g, ".");
          elemClasses = elemClasses.replace(/^/g, " ");
          let classNth = "";

          // check if element class is the unique child
          const childrens = element.parentNode?.children || [];

          const similarElements: Array<HTMLElement> = [];
          for (let i = 0; i < childrens.length; i++) {
            if (
              element.getAttribute("class") == childrens[i].getAttribute("class") ||
              element.tagName == childrens[i].tagName
            ) {
              similarElements.push(childrens[i] as HTMLElement);
            }
          }
          if (similarElements.length > 1) {
            for (let j = 0; j < similarElements.length; j++) {
              if (element === similarElements[j]) {
                j++;
                classNth =
                  (similarElements.filter((elementSim) => elementSim.tagName === element.tagName)
                    .length === similarElements.length
                    ? ":nth-of-type("
                    : ":nth-child(") +
                  j +
                  ")";
                break;
              }
            }
          }

          result = result.replace(/^/, elemClasses + classNth);
        } else {
          // get nodeType
          let name = element.nodeName;
          name = name.toLowerCase();
          let nodeNth = "";

          const childrens = element.parentNode?.children || [];

          if (childrens.length > 1) {
            const similarNodes = [];

            for (let i = 0; i < childrens.length; i++) {
              if (element.nodeName == childrens[i].nodeName) {
                similarNodes.push(childrens[i]);
              }
            }

            if (similarNodes.length > 1) {
              for (let j = 0; j < similarNodes.length; j++) {
                if (element === similarNodes[j]) {
                  j++;
                  nodeNth = ":nth-of-type(" + j + ")";
                  break;
                }
              }
            }
          }

          result = result.replace(/^/, " " + name + nodeNth);
        }

        if (element.parentNode) {
          loop(element.parentNode as HTMLElement);
        } else {
          result = result.replace(/\s/g, " > ");
          result = result.replace(/\s/, "");
          return result;
        }
      } else {
        result = "";
      }
    };

    loop(element);

    return result;
  },
  getOlderQueryString: (element: HTMLElement): string => {
    let result = "";

    const loop = (element: HTMLElement) => {
      if (element?.nodeType === Node.ELEMENT_NODE) {
        // stop here = element has ID
        if (element.getAttribute("id")) {
          result = result.replace(/^/, " #" + element.getAttribute("id"));
          result = result.replace(/\s/, "");
          result = result.replace(/\s/g, " > ");
          return result;
        }

        // stop here = element is body
        if (document.body === element) {
          result = result.replace(/^/, " body");
          result = result.replace(/\s/, "");
          result = result.replace(/\s/g, " > ");
          return result;
        }

        // concat all classes in "queryselector" style
        if (element.getAttribute("class")) {
          let elemClasses = ".";
          elemClasses += element.getAttribute("class");
          elemClasses = elemClasses.replace(/\s/g, ".");
          elemClasses = elemClasses.replace(/^/g, " ");
          let classNth = "";

          // check if element class is the unique child
          const childrens = element.parentNode?.children || [];

          const similarElements: Array<HTMLElement> = [];
          for (let i = 0; i < childrens.length; i++) {
            if (
              element.getAttribute("class") == childrens[i].getAttribute("class") ||
              element.tagName == childrens[i].tagName
            ) {
              similarElements.push(childrens[i] as HTMLElement);
            }
          }
          if (similarElements.length > 1) {
            for (let j = 0; j < similarElements.length; j++) {
              if (element === similarElements[j]) {
                j++;
                classNth = ":nth-of-type(" + j + ")";
                break;
              }
            }
          }

          result = result.replace(/^/, elemClasses + classNth);
        } else {
          // get nodeType
          let name = element.nodeName;
          name = name.toLowerCase();
          let nodeNth = "";

          const childrens = element.parentNode?.children || [];

          if (childrens.length > 1) {
            const similarNodes = [];

            for (let i = 0; i < childrens.length; i++) {
              if (element.nodeName == childrens[i].nodeName) {
                similarNodes.push(childrens[i]);
              }
            }

            if (similarNodes.length > 1) {
              for (let j = 0; j < similarNodes.length; j++) {
                if (element === similarNodes[j]) {
                  j++;
                  nodeNth = ":nth-of-type(" + j + ")";
                  break;
                }
              }
            }
          }

          result = result.replace(/^/, " " + name + nodeNth);
        }

        if (element.parentNode) {
          loop(element.parentNode as HTMLElement);
        } else {
          result = result.replace(/\s/g, " > ");
          result = result.replace(/\s/, "");
          return result;
        }
      } else {
        result = "";
      }
    };

    loop(element);

    return result;
  },
  getChildNodes: (element: HTMLElement): Array<string> => {
    const result = [];
    if (!element) return [];
    for (let i = 0; i < element.childNodes.length; i++) {
      const childNode = element.childNodes[i];
      const queryString = Element.getQueryString(childNode as HTMLElement);
      result.push(queryString);
    }
    return result;
  },
};

export default Element;
