var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pw---html-element",class:{ 'pw---has-settings': _vm.hasSettings },style:({
    'background-color':
      _vm.element.queryString === _vm.$store.state.element.selected.queryString
        ? _vm.hasSettings
          ? 'rgba(0, 98, 255, 0.8)'
          : '#ffffff25'
        : '',
    position: 'relative',
  }),on:{"mouseenter":function($event){return _vm.hover(true)},"mouseleave":function($event){return _vm.hover(false)},"click":function($event){return _vm.click()}}},[_vm._l((_vm.parents),function(parent){return _c('div',{key:parent,staticClass:"pw---element-nest-space"},[_c('div',{staticClass:"pw---vertical-seperator"})])}),_c('div',{staticClass:"pw---element-nest-space pw---open",class:{ 'pw---0': _vm.element.children.length === 0 }},[_c('img',{staticClass:"pw---icon-6",class:{ 'pw---closed': _vm.element.children.length > 0 && !_vm.isOpen },attrs:{"src":_vm.$config.configuratorUrl + 'images/down.svg',"loading":"lazy","alt":""},on:{"click":function($event){return _vm.$emit(_vm.isOpen ? 'close' : 'open')}}})]),_c('div',{staticClass:"pw---p-small pw---50",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.elementDisplay.identifier !== "#pw---website" ? _vm.elementDisplay.tagName : "body")+" ")]),(_vm.elementDisplay.identifier !== '#pw---website')?_c('div',{staticClass:"pw---p-small pw---left-5",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.elementDisplay.identifier)+" ")]):_vm._e(),(_vm.isHovering)?_c('Info',{staticStyle:{"white-space":"nowrap","margin-left":"-60px"},attrs:{"infoText":_vm.elementDisplay.classes,"infoHeading":"Classes: "}}):_vm._e(),(_vm.hasSettings)?_c('div',{staticClass:"pw---setting-indicator",style:({
      'background-color':
        _vm.element.queryString === _vm.$store.state.element.selected.queryString
          ? _vm.hasSettings
            ? '#ffffff'
            : ''
          : '',
    })}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }