


















import { Component, Vue, Watch } from "vue-property-decorator";
import { PageModel } from "@/models/Configuration";
import Button from "@/components/ui/Button.vue";

const Props = Vue.extend({
  props: {
    resource: {
      type: Object,
      required: true,
      validator: (value) => {
        if (value.id && value.type && value.name) return true;
        return false;
      },
    },
  },
});

@Component({})
export default class Resource extends Props {
  get type(): { name: string; icon: string } {
    if (this.resource.type === "airtable") {
      return { name: "Airtable", icon: "Airtable" };
    } else if (this.resource.type === "stripe") {
      return { name: "Stripe", icon: "Stripe" };
    } else {
      return { name: "REST API", icon: "API" };
    }
  }

  open(): void {
    if (!this.$store.state.popup.resourceSettings.isOpen)
      this.$store.commit("SET_POPUP_RESOURCESETTINGS", {
        resource: this.$props.resource,
        isOpen: true,
      });
  }
}
