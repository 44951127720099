





















import { Component, Vue, Watch } from "vue-property-decorator";
import Button from "@/components/ui/Button.vue";

const Props = Vue.extend({
  props: {
    iconName: {
      type: String,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    buttonLink: {
      type: String,
      required: true,
    },
  },
});

@Component({
  components: {
    Button,
  },
})
export default class EmptyTutorial extends Props {
  buttonClick(): void {
    const newTab = window.open(this.buttonLink, "_blank");
    if (newTab) newTab.focus();
  }
}
