





























import { Component, Vue, Watch } from "vue-property-decorator";
import ResizeObserver from "resize-observer-polyfill";
import ElementToolkit from "@/helpers/element";

@Component({
  components: {},
})
export default class SelectedOverlay extends Vue {
  get selectedElement(): HTMLElement | null {
    if (Object.keys(this.$store.state.element.selected).length > 0) {
      return document.querySelector(
        this.$store.state.element.selected.queryString
      );
    } else {
      return null;
    }
  }

  get selectedRect(): any {
    if (this.selectedElement)
      return this.selectedElement.getBoundingClientRect();
    else return {};
  }

  get selectedElementDisplay(): { tagName: string; identifier: string } {
    if (Object.keys(this.$store.state.element.selected).length > 0) {
      return ElementToolkit.formatForDisplay(
        this.$store.state.element.selected
      );
    } else {
      return {
        tagName: "-",
        identifier: "",
      };
    }
  }
}
