




























































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import Toggle from "@/components/ui/Toggle.vue";
import InputSelect from "@/components/ui/InputSelect.vue";
import InputAll from "@/components/ui/InputAll.vue";
import Button from "@/components/ui/Button.vue";
import BlockHeading from "@/components/ui/BlockHeading.vue";
import OptionTitle from "@/components/ui/OptionTitle.vue";
import ResourceModel from "@/models/Resource";
import Request from "@/helpers/request";
import LoadingSpinner from "@/components/misc/LoadingSpinner.vue";
import envConfig from "@/envConfig";
import Page from "@/helpers/page";
import { PageModel } from "@/models/Configuration";
import UpgradeNotice from "@/components/tab/UpgradeNotice.vue";

@Component({
  components: {
    Toggle,
    InputSelect,
    InputAll,
    Button,
    OptionTitle,
    BlockHeading,
    LoadingSpinner,
    UpgradeNotice,
  },
})
export default class Settings extends Vue {
  loadingBases = false;
  loadedBases = false;
  loadingBaseStructure = false;
  loadedBaseStructure = false;

  set(
    value: any,
    attributeName:
      | "isEnabled"
      | "hasBranding"
      | "resourceId"
      | "baseId"
      | "tableId"
      | "fieldId"
      | "userLocationValid"
  ): void {
    if (attributeName === "hasBranding") {
      this.$store.commit("SET_SETTINGS", {
        hasBranding: value,
        login: this.settings.login,
      });
    } else {
      const login = this.settings.login;
      login[attributeName] = value;
      if (attributeName === "isEnabled" && value === false) {
        login.resourceId = "";
        login.baseId = "";
        login.tableId = "";
        login.fieldId = "";
        login.userLocationValid = false;
      }
      this.$store.commit("SET_SETTINGS", {
        hasBranding: this.settings.hasBranding,
        login: login,
      });
    }
  }

  mounted() {
    if (this.settings.login.resourceId) {
      this.loadAirtableBases();
    }
    if (this.settings.login.baseId) {
      this.loadAirtableBaseStructure();
    }
  }

  @Watch("userLocationValid")
  updateUserLocation(): void {
    this.set(this.userLocationValid, "userLocationValid");
  }

  async loadAirtableBases(): Promise<void> {
    this.loadingBases = true;
    if (this.selectedResource.type === "airtable")
      await Request.loadAirtableBases(
        this.selectedResource.apiKey ? this.selectedResource.apiKey : ""
      );
    this.loadingBases = false;
    this.loadedBases = true;
  }

  async loadAirtableBaseStructure(): Promise<void> {
    this.loadingBaseStructure = true;
    await Request.loadAirtableBaseStructure(
      this.selectedResource.apiKey ? this.selectedResource.apiKey : "",
      this.settings.login.baseId ? this.settings.login.baseId : ""
    );
    this.loadingBaseStructure = false;
    this.loadedBaseStructure = true;
  }

  openProjectSettings(): void {
    const url = envConfig.dashboardUrl + "project/" + this.$store.state.project.id;
    if (this.$store.state.save.isSaved) window.open(url, "_blank")?.focus();
    else if (confirm("Recent changes are not saved. Are you sure you want to go to the dashboard?"))
      window.open(url, "_blank")?.focus();
    else return;
  }

  resetPage() {
    if (
      confirm(
        "All configurations (including requests and data connections to elements) on this page will be deleted. Are you sure you want to proceed?"
      )
    ) {
      const page: PageModel = {
        path: (this.page && this.page.path) || "",
        id: (this.page && this.page.id) || "",
        requests: [],
        parameters: [],
        variables: [],
        responses: [],
        settings: {
          accessControl: {
            accessToThisPageRestricted: false,
            authRequired: false,
            authFallbackPageId: "",
            customAccessRule: false,
            allowIf: [],
            fallbackPageId: "",
          },
          login: {
            onThisPage: false,
            magicLinkEnabled: false,
            magicLinkEmailQuery: "",
            magicLinkButtonQuery: "",
            googleLoginEnabled: false,
            googleLoginButtonQuery: "",
            emailPasswordLoginEnabled: false,
            emailPasswordEmailQuery: "",
            emailPasswordPasswordQuery: "",
            emailPasswordButtonQuery: "",
            redirectAfterLogin: false,
            redirectPageId: "",
            emailPasswordRequestReset: false,
            emailPasswordRequestResetEmailQuery: "",
            emailPasswordRequestResetButtonQuery: "",
            resetPasswordPageId: "",
            emailPasswordReset: false,
            emailPasswordResetPasswordQuery: "",
            emailPasswordResetPasswordValidationQuery: "",
            emailPasswordResetButtonQuery: "",
            redirectAfterPasswordReset: false,
            redirectPageIdAfterPasswordReset: "",
            logoutButton: "",
          },
        },
        elements: [],
      };
      this.$store.commit("SET_PAGE", page);
    } else {
      return;
    }
  }

  get page() {
    return Page.get();
  }

  get isOnFreePlan(): boolean {
    return (
      this.$store.state.project.plan !== "launch" && this.$store.state.project.plan !== "business"
    );
  }

  get basesOfSelectedResource(): Array<any> {
    return this.airtableBases.length > 0 ? [{ name: "Bases", values: this.airtableBases }] : [];
  }

  get tablesOfSelectedBase(): Array<any> {
    return this.airtableBaseStructure.length > 0
      ? [{ name: "Tables", values: this.airtableBaseStructure }]
      : [];
  }

  get fieldsOfSelectedTable(): Array<any> {
    const selectedTableId = this.settings.login.tableId;
    const table = this.tablesOfSelectedBase[0]
      ? this.tablesOfSelectedBase[0].values.find((table: any) => table.id === selectedTableId)
      : {};
    const fields = table && table.fields ? table.fields : [];
    const filteredFields: any = [];
    fields.forEach((field: any) => {
      // OPTIONAL: Filter for field types here
      filteredFields.push(field);
    });
    return table && table.fields ? [{ name: "Views", values: filteredFields }] : [];
  }

  get selectedResource(): ResourceModel {
    return this.$store.state.configuration.resources.find(
      (resource: ResourceModel) => resource.id === this.settings.login.resourceId
    );
  }

  get settings(): any {
    return this.$store.state.configuration.settings;
  }

  get resources(): any {
    const resources: any = [];
    this.$store.state.configuration.resources.forEach((resource: any) => {
      if (resource.type === "airtable") resources.push({ id: resource.id, name: resource.name });
    });

    return [
      {
        name: "Resources",
        values: resources,
      },
    ];
  }

  get airtableBases() {
    return this.$store.state.popup.requestSettings.airtableBases;
  }

  get airtableBaseStructure() {
    return this.$store.state.popup.requestSettings.airtableBaseStructure;
  }

  get userLocationValid(): boolean {
    if (
      this.settings.login.resourceId &&
      this.settings.login.baseId &&
      this.settings.login.tableId &&
      this.settings.login.fieldId
    )
      return true;
    else return false;
  }
}
