import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Configurator from "@/views/Configurator.vue";
import Display from "@/views/Display.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "configurator",
    component: Configurator,
    //component: () => import("@/views/Configurator.vue"),
  },
  {
    path: "/",
    name: "display",
    component: Display,
    //component: () => import("@/views/Configurator.vue"),
  },
];

const router = new VueRouter({
  mode: "abstract",
  routes,
});

export default router;
