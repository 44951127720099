





























import { Component, Vue, Watch } from "vue-property-decorator";
import LoadingSpinner from "@/components/misc/LoadingSpinner.vue";
import { ConfigurationModel } from "@/models/Configuration";

const Props = Vue.extend({
  props: {
    infoText: {
      type: String,
      default: "Resetting will revert to initial value.",
    },
  },
});

@Component({
  components: { LoadingSpinner },
})
export default class Reset extends Props {
  height = 0;
  width = 0;
  offsetLeft = 0;
  offsetTop = 0;

  mounted(): void {
    const distanceToWindowTop = window.pageYOffset + this.$el.getBoundingClientRect().top;
    if (distanceToWindowTop < 100) {
      (this.$el as HTMLElement).style.bottom = "auto";
      (this.$el as HTMLElement).style.top = "23px";
    }
    this.height = (this.$el as HTMLElement).offsetHeight;
    this.width = (this.$el as HTMLElement).offsetWidth;
    (this.$el as HTMLElement).style.left = "140px";
    this.offsetLeft = this.$el.getBoundingClientRect().left;
    this.offsetTop = this.$el.getBoundingClientRect().top;
  }

  reset() {
    this.$emit("reset");
  }
}
