




























































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import InputAll from "@/components/ui/InputAll.vue";
import InputText from "@/components/ui/InputText.vue";
import InputSelect from "@/components/ui/InputSelect.vue";
import Toggle from "@/components/ui/Toggle.vue";
import Button from "@/components/ui/Button.vue";
import OptionTitle from "@/components/ui/OptionTitle.vue";
import BlockHeading from "@/components/ui/BlockHeading.vue";
import RequestModel from "@/models/Request";

@Component({
  components: {
    InputAll,
    InputText,
    InputSelect,
    Toggle,
    OptionTitle,
    BlockHeading,
    Button,
  },
})
export default class Rest extends Vue {
  set(value: any, attributeName: "contentType" | "body"): void {
    const request = this.request;
    (request[attributeName] as any) = value;
    this.$store.commit("SET_POPUP_REQUESTSETTINGS", {
      request: request,
      isOpen: true,
    });
  }

  setArray(
    value: string,
    attributeName: "key" | "value",
    index: number,
    arrayName: "headers" | "params" | "bodyForm"
  ): void {
    const request = this.request;
    let array = request[arrayName];
    if (!array) return;
    const item = array[index];
    item[attributeName] = value;
    this.$store.commit("SET_POPUP_REQUESTSETTINGS", {
      request: request,
      isOpen: true,
    });
  }

  addArrayItem(arrayName: "headers" | "params" | "bodyForm"): void {
    const request = this.request;
    let array = request[arrayName];
    if (!array) array = [];
    const item = { key: "", value: "" };
    array.push(item);
    request[arrayName] = array;
    this.$store.commit("SET_POPUP_REQUESTSETTINGS", {
      request: request,
      isOpen: true,
    });
  }

  deleteArrayItem(index: number, arrayName: "headers" | "params" | "bodyForm"): void {
    const request = this.request;
    let array = request[arrayName];
    if (!array) return;
    array.splice(index, 1);
    request[arrayName] = array;
    this.$store.commit("SET_POPUP_REQUESTSETTINGS", {
      request: request,
      isOpen: true,
    });
  }

  get request(): RequestModel {
    return JSON.parse(JSON.stringify(this.$store.state.popup.requestSettings.request));
  }
}
