var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.selectedElement)?_c('div',{staticClass:"pw---selection-wrapper",style:({
    top: _vm.selectedRect.top + 'px',
    left: _vm.selectedRect.left + 'px',
    height: _vm.selectedRect.height + 'px',
    width: _vm.selectedRect.width + 'px',
  })},[_c('div',{staticClass:"pw---selection-text pw---blue",staticStyle:{"position":"absolute"},style:({
      marginTop: _vm.selectedRect.top <= 45 ? '5px' : '-20px',
    })},[_c('div',{staticClass:"pw---p-small",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.selectedElementDisplay.tagName)+" ")]),_c('div',{staticClass:"pw---p-small pw---left-5",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.selectedElementDisplay.identifier)+" ")])]),_c('div',{staticClass:"pw---selection"})]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }