import store from "@/store";
import Page from "@/helpers/page";
import Request from "@/helpers/request";
import variables from "./variables";

export default {
  /**
   * whenLoaded: Triggered when Wized and all initial requests are loaded
   **/
  whenLoaded: (fn: any) => {
    (window as any).Wized.functionStore.loaded.push(fn);
  },
  data: {
    /**
     * get: Returns a dump of the pages data store
     **/
    get: () => {
      if (!checkforAccess()) return;
      const page = Page.get();
      const data = store.state.data;
      data.requests.forEach((request: any) => {
        request.name = findNameById(request.id, page?.requests);
      });
      data.cookies.forEach((cookie: any) => {
        cookie.name = findNameById(cookie.id, store.state.configuration.cookies);
      });
      data.params.forEach((param: any) => {
        param.name = findNameById(param.id, page?.parameters);
      });
      data.variables.forEach((variable: any) => {
        variable.name = findNameById(variable.id, page?.variables);
      });
      return data;
    },
    /**
     * whenUpdated: Is a listner which is triggered every time the data store changes
     **/
    whenUpdated: (fn: any) => {
      (window as any).Wized.functionStore.dataUpdated.push(fn);
    },
    variables: {
      /**
       * get: Returns a variable
       **/
      get: (id: string): { name: string; id: string; value: any } => {
        if (!checkforAccess()) return { name: "", id: "", value: undefined };
        const page = Page.get();
        const pageVariable = page?.variables.find(
          (variable: any) => variable.id === id || variable.name === id
        );
        if (!pageVariable) {
          console.log("Wized JavaScript API: Variable " + id + " not found.");
          return { name: "", id: "", value: undefined };
        }
        const variable = store.state.data.variables.find(
          (variable: { id: string; value: string }) => variable.id === pageVariable.id
        );
        if (!variable) {
          console.error("Wized JavaScript API: Variable " + id + " not found.");
          return { name: "", id: "", value: undefined };
        }
        variable.name = findNameById(variable.id, page?.variables);
        return variable;
      },
      /**
       * set: Sets a page variable
       **/
      set: (id: string, value: any) => {
        if (!checkforAccess()) return;
        const page = Page.get();
        const variable = page?.variables.find(
          (variable: any) => variable.id === id || variable.name === id
        );
        if (variable)
          store.commit("SET_DATA_TYPE_VALUE", {
            type: "variables",
            value: {
              id: variable.id,
              value: value,
            },
          });
      },
    },
  },
  request: {
    /**
     * Triggers the execution of a request
     **/
    load: (id: string) => {
      const page = Page.get();
      const request = page?.requests.find((request) => request.name === id || request.id === id);
      Request.perform(request?.id || id);
    },
    /**
     * Is a listner which is triggered every time after the request has been executed
     **/
    whenLoaded: (id: string, fn: any) => {
      const page = Page.get();
      const request = page?.requests.find((request) => request.name === id || request.id === id);
      if (Array.isArray((window as any).Wized.functionStore.loadedRequest[request?.id || id]))
        (window as any).Wized.functionStore.loadedRequest[request?.id || id].push(fn);
      else (window as any).Wized.functionStore.loadedRequest[request?.id || id] = [fn];
    },
  },
  functionStore: {
    loaded: [],
    loadedRequest: {},
    dataUpdated: [],
  },
  trigger: {
    loaded: () => {
      if (
        !(window as any) ||
        !(window as any).Wized ||
        (window as any).Wized.functionStore.loaded.length === 0
      )
        return;
      if (!checkforAccess()) return;
      (window as any).Wized.functionStore.loaded.forEach((fn: any) => fn());
    },
    dataUpdated: () => {
      if (
        !(window as any) ||
        !(window as any).Wized ||
        (window as any).Wized.functionStore.dataUpdated.length === 0
      )
        return;
      if (!checkforAccess()) return;
      (window as any).Wized.functionStore.dataUpdated.forEach((fn: any) => fn());
    },
    loadedRequest: (id: string) => {
      if (
        !(window as any) ||
        !(window as any).Wized ||
        !(window as any).Wized.functionStore.loadedRequest[id] ||
        (window as any).Wized.functionStore.loadedRequest[id]?.length === 0
      )
        return;
      if (!checkforAccess()) return;
      (window as any).Wized.functionStore.loadedRequest[id]?.forEach((fn: any) => fn());
    },
  },
  alertText: {
    emailInvalid: "Email is not valid. Please try again.",
    loginEmailSent:
      "Email with login-link sent. Please check your inbox and eventually your spam folder.",
    userNotFound: "No user found with that email address.",
    serverError:
      "Server error. Login either not active or misconfigured. Please contact the site administrator.",
    loginError: "Could not login user.",
    loginErrorTryAgain: "You could not be logged in. Please go to the login and try again.",
    credentialsInvalid:
      "Email or password incorrect. If you can't remember your password, use the password reset function.",
    passwordResetEmailSent:
      "Please check your email account (and SPAM folder). We have sent you a link to reset your password.",
    passwordResetEmailError: "Could not send password reset link.",
    passwordResetSuccess: "Success! Your password was reset.",
    passwordMismatch: "Password and password confirmation do not match.",
    passwordInsecure:
      "Your password does not meet criteria for a strong password. The password must be at least 6 characters long and must consist of lowercase and uppercase letters.",
    permissionDeniedLogin: "You don't have permission to access this site. Please log in first.",
    permissionDenied: "You don't have permission to access this site.",
    accountCreationSuccess: "Account created! You can now log in.",
    fileInvalid: "File is too big! (Maximum 20 MB)",
  },
};

const findNameById = (id: string, array: any): string => {
  let result = "";
  array.forEach((item: any) => {
    if (item.id === id) {
      result = item.name;
    }
  });
  return result;
};

const checkforAccess = (): boolean => {
  if (store.state.project.plan === "development") {
    console.error(
      "Wized JavaScript API: Please upgrade this project to the launch or business plan to use the Wized JavaScript API."
    );
    return false;
  } else return true;
};
