












































import { Component, Vue, Watch } from "vue-property-decorator";
import InputAll from "@/components/ui/InputAll.vue";
import OptionTitle from "@/components/ui/OptionTitle.vue";
import BlockHeading from "@/components/ui/BlockHeading.vue";
import RequestModel from "@/models/Request";

@Component({
  components: {
    InputAll,
    OptionTitle,
    BlockHeading,
  },
})
export default class Portal extends Vue {
  set(value: string, attributeName: "customerId" | "returnUrl"): void {
    const request = this.request;
    request[attributeName] = value;
    this.$store.commit("SET_POPUP_REQUESTSETTINGS", {
      request: request,
      isOpen: true,
    });
  }

  get request(): RequestModel {
    return JSON.parse(JSON.stringify(this.$store.state.popup.requestSettings.request));
  }
}
