































import { Component, Vue, Watch } from "vue-property-decorator";
import Button from "@/components/ui/Button.vue";
import BlockHeading from "@/components/ui/BlockHeading.vue";
import Variable from "@/components/ui/Variable.vue";
import Page from "@/helpers/page.ts";
import Variables from "@/helpers/variables";
import AllData from "@/components/tab/data/AllData.vue";
import UpgradeNotice from "@/components/tab/UpgradeNotice.vue";

@Component({
  components: { Button, BlockHeading, AllData, UpgradeNotice },
})
export default class Data extends Vue {
  newVariable(): void {
    this.$store.commit("SET_POPUP_VARIABLES", {
      isOpen: true,
      name: "",
      id: "",
    });
  }

  editVariable(name: string, id: string): void {
    this.$store.commit("SET_POPUP_VARIABLES", {
      isOpen: true,
      name: name,
      id: id,
    });
  }

  newCookie(): void {
    this.$store.commit("SET_POPUP_COOKIES", {
      isOpen: true,
      name: "",
      id: "",
    });
  }

  editCookie(name: string, id: string): void {
    this.$store.commit("SET_POPUP_COOKIES", {
      isOpen: true,
      name: name,
      id: id,
    });
  }

  openAPIDocumentation(): void {
    window
      .open("https://wized.notion.site/JavaScript-API-b6c7ad62e3c44609afe58704a95a13cd", "_blank")
      ?.focus();
  }

  get isOnFreePlan(): boolean {
    return (
      this.$store.state.project.plan !== "launch" && this.$store.state.project.plan !== "business"
    );
  }

  get parameters() {
    const page = Page.get();
    if (!page || !page.parameters) return [];
    else return page.parameters;
  }

  get inputFields(): Array<{
    name: string;
    context: string;
    queryString: string;
    value: string;
  }> {
    const inputFields = Variables.getAllInputFields();
    return inputFields;
  }

  get variables() {
    const page = Page.get();
    if (!page || !page.variables) return [];
    else return page.variables;
  }

  get cookies() {
    return this.$store.state.configuration.cookies;
  }

  get requests() {
    const page = Page.get();
    if (!page || !page.requests) return [];
    else return page.requests;
  }
}
