




















































































import { Component, Vue, Watch } from "vue-property-decorator";
import Button from "@/components/ui/Button.vue";
import InputText from "@/components/ui/InputText.vue";
import OptionTitle from "@/components/ui/OptionTitle.vue";
import PopupHeading from "@/components/ui/PopupHeading.vue";
import Page from "@/helpers/page";
import RequestModel from "@/models/Request";
import InputAll from "@/components/ui/InputAll.vue";
import BlockHeading from "@/components/ui/BlockHeading.vue";
import Variable from "@/components/ui/Variable.vue";

@Component({
  components: {
    Button,
    InputText,
    OptionTitle,
    PopupHeading,
    BlockHeading,
    Variable,
    InputAll,
  },
})
export default class EditFilter extends Vue {
  createNewFilter = true;
  filter: { arrayPath: string; activeIf: string; condition: string } = {
    arrayPath: "",
    activeIf: "",
    condition: "",
  };

  mounted(): void {
    const filter = this.request?.arrayFilters?.find((filter) => filter.arrayPath === this.path);
    if (filter) {
      this.filter = { ...this.filter, ...filter };
      this.createNewFilter = false;
    }
    this.filter.arrayPath = this.path;
  }

  set(value: string, attributeName: "arrayPath" | "activeIf" | "condition"): void {
    this.filter[attributeName] = value;
  }

  save(): void {
    const filterIndex = this.request?.arrayFilters?.findIndex(
      (filter) => filter.arrayPath === this.path
    );
    const request = this.request;
    if (!request) return;
    if (filterIndex === -1) request.arrayFilters.push(this.filter);
    else if (filterIndex) request.arrayFilters[filterIndex] = this.filter;
    else if (!filterIndex) request.arrayFilters = [this.filter];
    this.$store.commit("SET_REQUEST", request);
    this.close();
  }

  deleteFilter(): void {
    const filterIndex = this.request?.arrayFilters?.findIndex(
      (filter) => filter.arrayPath === this.path
    );
    const request = this.request;
    if (!request || filterIndex === undefined) return;
    request?.arrayFilters.splice(filterIndex, 1);
    if (confirm("Are you sure you want to delete this filter?"))
      this.$store.commit("SET_REQUEST", request);
    else return;
    this.close();
  }

  close(): void {
    this.$store.commit("SET_POPUP_FILTER", {
      path: "",
      isOpen: false,
    });
  }

  get path(): string {
    return this.$store.state.popup.filterSettings.path;
  }

  get request(): RequestModel | undefined {
    const requestId = this.path.split(".")[0];
    return Page.get()?.requests.find((request) => request.id == requestId);
  }

  get isValidFilter(): boolean {
    return true;
  }
}
