
































import { Component, Vue, Watch } from "vue-property-decorator";
import LoadingSpinner from "@/components/misc/LoadingSpinner.vue";
import { ConfigurationModel } from "@/models/Configuration";
import RequestModel from "@/models/Request";
import Page from "@/helpers/page";

const Props = Vue.extend({
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => {
        return (
          [
            "auth",
            "object",
            "attribute",
            "custom",
            "input",
            "function",
            "keyword",
            "operator",
            "variable",
            "editable",
            "state",
          ].indexOf(value) !== -1
        );
      },
    },
    name: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      default: "",
    },
  },
});

@Component({
  components: { LoadingSpinner },
})
export default class SortFilter extends Props {
  mounted(): void {
    const distanceToWindowTop = window.pageYOffset + this.$el.getBoundingClientRect().top;
    if (distanceToWindowTop < 60) {
      (this.$el as HTMLElement).style.bottom = "auto";
      (this.$el as HTMLElement).style.top = "23px";
    }
  }

  get hasFilter(): boolean {
    if (this.request?.arrayFilters && this.request.arrayFilters.length > 0) return true;
    else return false;
  }

  get hasSorting(): boolean {
    if (this.request?.arraySortings && this.request.arraySortings.length > 0) return true;
    else return false;
  }

  get request(): RequestModel | undefined {
    if (this.type === "object" || this.type === "attribute") {
      const requestId = this.path.split(".")[0];
      return this.requests.find((request: RequestModel) => request.id === requestId);
    } else return undefined;
  }

  get requests(): Array<RequestModel> {
    const page = Page.get();
    if (page) return page.requests;
    else return [];
  }
}
