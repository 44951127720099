































import { Component, Vue, Watch } from "vue-property-decorator";
import { PageModel } from "@/models/Configuration";
import Button from "@/components/ui/Button.vue";
import InputText from "@/components/ui/InputText.vue";
import OptionTitle from "@/components/ui/OptionTitle.vue";
import PopupHeading from "@/components/ui/PopupHeading.vue";
import Id from "@/helpers/id";
import Page from "@/helpers/page";

@Component({
  components: {
    Button,
    InputText,
    OptionTitle,
    PopupHeading,
  },
})
export default class EditCookie extends Vue {
  createNewCookie = false;

  set(value: string, attributeName: "name" | "id") {
    const settings = this.cookieSettings;
    if (attributeName === "name") {
      value = value.replace(/\s+/g, "-").toLowerCase();
    }
    settings[attributeName] = value;
    this.$store.commit("SET_POPUP_COOKIES", settings);
  }

  saveCookie(): void {
    this.$store.commit("SET_COOKIE", this.cookieSettings);
    this.close();
  }

  deleteCookie(): void {
    if (confirm("Are you sure you want to delete this cookie?"))
      this.$store.commit("DELETE_COOKIE", this.cookieSettings.id);
    else return;
    this.close();
  }

  close(): void {
    this.$store.commit("SET_POPUP_COOKIES", {
      name: "",
      id: "",
      isOpen: false,
    });
  }

  mounted(): void {
    if (!this.cookieSettings.name || !this.cookieSettings.id) {
      this.createNewCookie = true;
      const id = Id.create(this.cookies);
      this.set(id, "id");
    }
  }

  get cookies() {
    if (!this.$store.state.configuration.cookies) return [];
    else return this.$store.state.configuration.cookies;
  }

  get cookieSettings(): { id: string; name: string; isOpen: boolean } {
    return this.$store.state.popup.cookieSettings;
  }

  get isValidCookie(): boolean {
    if (this.cookieSettings.id && this.cookieSettings.name) return true;
    else return false;
  }

  get hasDeleteOption(): boolean {
    if (this.createNewCookie) return false;
    else return true;
  }
}
