








































































































import { Component, Vue, Watch } from "vue-property-decorator";
import Button from "@/components/ui/Button.vue";
import InputText from "@/components/ui/InputText.vue";
import OptionTitle from "@/components/ui/OptionTitle.vue";
import PopupHeading from "@/components/ui/PopupHeading.vue";
import Page from "@/helpers/page";
import Value from "@/helpers/value";
import RequestModel from "@/models/Request";
import InputAll from "@/components/ui/InputAll.vue";
import BlockHeading from "@/components/ui/BlockHeading.vue";
import Variable from "@/components/ui/Variable.vue";
import InputSelect from "@/components/ui/InputSelect.vue";

@Component({
  components: {
    Button,
    InputText,
    OptionTitle,
    PopupHeading,
    BlockHeading,
    Variable,
    InputAll,
    InputSelect,
  },
})
export default class EditSorting extends Vue {
  createNewSorting = true;
  sorting: {
    arrayPath: string;
    sortings: Array<{ activeIf: string; attributeShortPath: string; direction: "desc" | "asc" }>;
  } = {
    arrayPath: "",
    sortings: [],
  };

  mounted(): void {
    const sorting = this.request?.arraySortings?.find((sorting) => sorting.arrayPath === this.path);
    if (sorting) {
      this.sorting = JSON.parse(JSON.stringify(sorting));
      this.createNewSorting = false;
    }
    this.sorting.arrayPath = this.path;
  }

  setArray(
    value: string,
    index: number,
    attributeName: "activeIf" | "attributeShortPath" | "direction"
  ): void {
    console.log(...this.sorting.sortings);
    (this.sorting.sortings[index][attributeName] as any) = value;
    console.log(...this.sorting.sortings);
  }

  newItemInArray(): void {
    this.sorting.sortings.push({ activeIf: "", attributeShortPath: "", direction: "desc" });
  }

  deleteItemInArray(index: number): void {
    this.sorting.sortings.splice(index, 1);
  }

  save(): void {
    const sortingIndex = this.request?.arraySortings?.findIndex(
      (sorting) => sorting.arrayPath === this.path
    );
    const request = this.request;
    if (!request) return;
    if (sortingIndex === -1) request.arraySortings.push(this.sorting);
    else if (sortingIndex) request.arraySortings[sortingIndex] = this.sorting;
    else if (!sortingIndex) request.arraySortings = [this.sorting];
    this.$store.commit("SET_REQUEST", request);
    this.close();
  }

  deleteSorting(): void {
    const sortingIndex = this.request?.arraySortings?.findIndex(
      (sorting) => sorting.arrayPath === this.path
    );
    const request = this.request;
    if (!request || sortingIndex === undefined) return;
    request?.arraySortings.splice(sortingIndex, 1);
    if (confirm("Are you sure you want to delete this sorting?"))
      this.$store.commit("SET_REQUEST", request);
    else return;
    this.close();
  }

  close(): void {
    this.$store.commit("SET_POPUP_SORT", {
      path: "",
      isOpen: false,
    });
  }

  get path(): string {
    return this.$store.state.popup.sortSettings.path;
  }

  get request(): RequestModel | undefined {
    const requestId = this.path.split(".")[0];
    return Page.get()?.requests.find((request) => request.id == requestId);
  }

  get isValidSorting(): boolean {
    return true;
  }

  get directions(): Array<any> {
    return [
      {
        name: "Directions",
        values: [
          { id: "desc", name: "Descending" },
          { id: "asc", name: "Ascending" },
        ],
      },
    ];
  }

  get selectableAttributes(): Array<any> {
    const result: Array<{ id: string; name: string }> = [];
    // Get array
    const array = Value.getValue(this.path);
    if (array[0] && typeof array[0] === "object") {
      // merge all attributes of array into on object and
      let object: any = {};
      array.forEach((item: any) => (object = { ...object, ...item }));
      // loop threw every key of array
      Object.keys(object).forEach((key: string) => result.push({ id: key, name: key }));
    } else {
      result.push({ id: "item", name: "item" });
    }
    return [
      {
        name: "Attributes",
        values: result,
      },
    ];
  }
}
