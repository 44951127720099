



























































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import Button from "@/components/ui/Button.vue";
import BlockHeading from "@/components/ui/BlockHeading.vue";
import Variable from "@/components/ui/Variable.vue";
import Page from "@/helpers/page.ts";
import Variables from "@/helpers/variables";
import Request from "@/components/tab/data/RequestRuntime.vue";
import RequestUser from "@/components/tab/data/RequestUserRuntime.vue";
import draggable from "vuedraggable";
import { CookieModel, PageModel } from "@/models/Configuration";

const Props = Vue.extend({
  props: {
    hasDragNDrop: {
      type: Boolean,
      default: false,
    },
  },
});

@Component({
  components: { Button, BlockHeading, Variable, Request, RequestUser, draggable },
})
export default class AllData extends Props {
  newVariable(): void {
    this.$store.commit("SET_POPUP_VARIABLES", {
      isOpen: true,
      name: "",
      id: "",
    });
  }

  editVariable(name: string, id: string): void {
    this.$store.commit("SET_POPUP_VARIABLES", {
      isOpen: true,
      name: name,
      id: id,
    });
  }

  newCookie(): void {
    this.$store.commit("SET_POPUP_COOKIES", {
      isOpen: true,
      name: "",
      id: "",
    });
  }

  editCookie(name: string, id: string): void {
    this.$store.commit("SET_POPUP_COOKIES", {
      isOpen: true,
      name: name,
      id: id,
    });
  }

  clone(attributes: {
    name: string;
    type: string;
    path: string;
    context: string;
  }): { name: string; type: string; path: string; context: string } {
    return {
      name: attributes.name,
      type: attributes.type,
      path: attributes.path,
      context: attributes.context,
    };
  }

  checkMove(event: any) {
    if (event.from === event.to) return false;
    else true;
  }

  setIsDragging(value: boolean): void {
    this.$store.commit("SET_HELPER_ISDRAGGINGVARIABLE", value);
  }

  get parameters(): { id: string; name: string; type: string; path: string }[] {
    const page = Page.get();
    const result: { id: string; name: string; type: string; path: string }[] = [];
    if (!page || !page.parameters) return [];
    else {
      page.parameters.forEach((parameter) => {
        result.push({
          id: parameter.id,
          name: parameter.name,
          type: "custom",
          path: "params" + "." + parameter.name,
        });
      });
      return result;
    }
  }

  get inputFields(): Array<{
    id: number;
    name: string;
    type: string;
    path: string;
    context: string;
  }> {
    const inputFields = Variables.getAllInputFields();
    const result: { id: number; name: string; type: string; path: string; context: string }[] = [];
    inputFields.forEach((inputField, index) => {
      result.push({
        id: index,
        name: inputField.name,
        type: "input",
        path: "fields" + "." + inputField.queryString,
        context: inputField.context,
      });
    });
    return result;
  }

  get variables(): { id: string; name: string; type: string; path: string }[] {
    const page = Page.get();
    const result: { id: string; name: string; type: string; path: string }[] = [];
    if (!page || !page.variables) return [];
    else {
      page.variables.forEach((variable) => {
        result.push({
          id: variable.id,
          name: variable.name,
          type: "custom",
          path: "variables" + "." + variable.name,
        });
      });
      return result;
    }
  }

  get cookies(): { id: string; name: string; type: string; path: string }[] {
    const result: { id: string; name: string; type: string; path: string }[] = [];
    if (!this.$store.state.configuration.cookies) return [];
    else {
      this.$store.state.configuration.cookies.forEach((cookie: CookieModel) => {
        result.push({
          id: cookie.id,
          name: cookie.name,
          type: "custom",
          path: "cookies" + "." + cookie.name,
        });
      });
      return result;
    }
  }

  get requests() {
    const page = Page.get();
    if (!page || !page.requests) return [];
    else return page.requests;
  }

  get page(): PageModel | undefined {
    return Page.get();
  }
}
