





















































import { Component, Vue, Watch } from "vue-property-decorator";
import OptionTitle from "@/components/ui/OptionTitle.vue";
import BlockHeading from "@/components/ui/BlockHeading.vue";
import InputSelect from "@/components/ui/InputSelect.vue";
import Toggle from "@/components/ui/Toggle.vue";
import InputAll from "@/components/ui/InputAll.vue";
import Button from "@/components/ui/Button.vue";
import { ElementModel, StyleConnectionModel, PageModel } from "@/models/Configuration";
import Page from "@/helpers/page";
import RequestModel from "@/models/Request";
import Configuration from "@/helpers/configuration";

@Component({
  components: {
    OptionTitle,
    BlockHeading,
    InputSelect,
    InputAll,
    Toggle,
    Button,
  },
})
export default class Style extends Vue {
  setArray(value: any, index: number, attribute: string): void {
    const connection = this.connection;
    (connection[index] as any)[attribute] = value;
    this.saveConnection(connection);
  }

  addArrayItem(): void {
    const emptyConnection: StyleConnectionModel = {
      name: "",
      value: "",
    };
    let connection = this.connection;
    connection.push(emptyConnection);
    this.saveConnection(connection);
  }

  deleteArrayItem(index: number): void {
    let connection = this.connection;
    connection.splice(index, 1);
    this.saveConnection(connection);
  }

  saveConnection(connection: Array<StyleConnectionModel>) {
    const connectionAttribute = "style";
    let element: ElementModel = {
      elementQueryString: "",
      connections: {
        onClick: [],
        onInput: [],
        visibility: { condition: "" },
        style: [],
        class: [],
        list: { value: "" },
        value: { value: "" },
        text: { value: "", type: "text" },
        attributes: [],
        parameters: [],
      },
    };
    if (this.element) {
      element = this.element;
    } else {
      element.elementQueryString = this.selectedElementQuery;
    }
    element.connections[connectionAttribute] = connection;
    if (Configuration.isElementEmpty(element)) this.$store.commit("DELETE_ELEMENT", element);
    else this.$store.commit("SET_ELEMENT", element);
  }

  get connection(): Array<StyleConnectionModel> {
    if (
      this.element &&
      this.element.connections.style &&
      this.element.connections.style.length > 0
    ) {
      return this.element.connections.style;
    } else {
      return [];
    }
  }

  get element(): ElementModel | undefined | null {
    const DOMElement = document.querySelector(this.selectedElementQuery);
    return Page.domElemementsConfig()?.find((element: any) => element.DOMElement === DOMElement)
      ?.element;
  }

  get selectedElementQuery(): string {
    const element = this.$store.state.element.selected;
    if (element && element.queryString) return element.queryString;
    else return "";
  }

  get fieldConfiguration() {
    return {
      styles: [
        {
          name: "Styles",
          values: this.allCssProperties,
        },
      ],
    };
  }

  get allCssProperties(): Array<{ id: string; name: string }> {
    const allCssProperties = [
      "align-content",
      "align-items",
      "align-self",
      "all",
      "animation",
      "animation-delay",
      "animation-direction",
      "animation-duration",
      "animation-fill-mode",
      "animation-iteration-count",
      "animation-name",
      "animation-play-state",
      "animation-timing-function",
      "backface-visibility",
      "background",
      "background-attachment",
      "background-blend-mode",
      "background-clip",
      "background-color",
      "background-image",
      "background-origin",
      "background-position",
      "background-repeat",
      "background-size",
      "border",
      "border-bottom",
      "border-bottom-color",
      "border-bottom-left-radius",
      "border-bottom-right-radius",
      "border-bottom-style",
      "border-bottom-width",
      "border-collapse",
      "border-color",
      "border-image",
      "border-image-outset",
      "border-image-repeat",
      "border-image-slice",
      "border-image-source",
      "border-image-width",
      "border-left",
      "border-left-color",
      "border-left-style",
      "border-left-width",
      "border-radius",
      "border-right",
      "border-right-color",
      "border-right-style",
      "border-right-width",
      "border-spacing",
      "border-style",
      "border-top",
      "border-top-color",
      "border-top-left-radius",
      "border-top-right-radius",
      "border-top-style",
      "border-top-width",
      "border-width",
      "bottom",
      "box-shadow",
      "box-sizing",
      "caption-side",
      "clear",
      "clip",
      "color",
      "column-count",
      "column-fill",
      "column-gap",
      "column-rule",
      "column-rule-color",
      "column-rule-style",
      "column-rule-width",
      "column-span",
      "column-width",
      "columns",
      "content",
      "counter-increment",
      "counter-reset",
      "cursor",
      "direction",
      "display",
      "empty-cells",
      "filter",
      "flex",
      "flex-basis",
      "flex-direction",
      "flex-flow",
      "flex-grow",
      "flex-shrink",
      "flex-wrap",
      "float",
      "font",
      "@font-face",
      "font-family",
      "font-size",
      "font-size-adjust",
      "font-stretch",
      "font-style",
      "font-variant",
      "font-weight",
      "hanging-punctuation",
      "height",
      "justify-content",
      "@keyframes",
      "left",
      "letter-spacing",
      "line-height",
      "list-style",
      "list-style-image",
      "list-style-position",
      "list-style-type",
      "margin",
      "margin-bottom",
      "margin-left",
      "margin-right",
      "margin-top",
      "max-height",
      "max-width",
      "@media",
      "min-height",
      "min-width",
      "nav-down",
      "nav-index",
      "nav-left",
      "nav-right",
      "nav-up",
      "opacity",
      "order",
      "outline",
      "outline-color",
      "outline-offset",
      "outline-style",
      "outline-width",
      "overflow",
      "overflow-x",
      "overflow-y",
      "padding",
      "padding-bottom",
      "padding-left",
      "padding-right",
      "padding-top",
      "page-break-after",
      "page-break-before",
      "page-break-inside",
      "perspective",
      "perspective-origin",
      "position",
      "quotes",
      "resize",
      "right",
      "tab-size",
      "table-layout",
      "text-align",
      "text-align-last",
      "text-decoration",
      "text-decoration-color",
      "text-decoration-line",
      "text-decoration-style",
      "text-indent",
      "text-justify",
      "text-overflow",
      "text-shadow",
      "text-transform",
      "top",
      "transform",
      "transform-origin",
      "transform-style",
      "transition",
      "transition-delay",
      "transition-duration",
      "transition-property",
      "transition-timing-function",
      "unicode-bidi",
      "vertical-align",
      "visibility",
      "white-space",
      "width",
      "word-break",
      "word-spacing",
      "word-wrap",
      "z-index",
    ];
    const formattedProperties: Array<{ id: string; name: string }> = [];
    allCssProperties.forEach((property) =>
      formattedProperties.push({ id: property, name: property })
    );
    return formattedProperties;
  }
}
