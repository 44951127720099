import env from "@/envConfig";
import Token from "@/helpers/token";
import store from "@/store";
import ResourceModel from "@/models/Resource";

const Validate = {
  resource: async (resource: ResourceModel): Promise<boolean> => {
    const token = await Token.getAdminToken();
    const response = await fetch(`${env.serverUrl}v1/validate/resource`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ resource }),
    }).catch((error) => {
      throw new Error(error.message);
    });
    if (response.status === 200) {
      const responseParsed = await response.json();
      return responseParsed.resourceIsValid;
    } else if (response.status === 403) {
      store.commit("SET_ADMIN_USER", {});
      return false;
    } else {
      throw new Error(`Server Error. Code: ${response.status}. Could not validate resource.`);
    }
  },
};

export default Validate;
