import store from "@/store";
import Request from "./../request";
import Cookie from "./../cookie";
import {
  AttributesConnectionModel,
  ClassConnectionModel,
  ElementModel,
  ListConnectionModel,
  OnClickConnectionModel,
  OnInputConnectionModel,
  PageModel,
  ParameterConnectionModel,
  StyleConnectionModel,
  ValueConnectionModel,
  VisibilityConnectionModel,
} from "@/models/Configuration";
import { TextConnectionModel } from "@/models/Configuration";
import Value from "./../value";
import Render from "../render";
import Element from "./../element";
import Page from "./../page";
import marked from "marked";

const Connection = {
  onClick: async (
    connections: OnClickConnectionModel[],
    element: Element,
    elementQueryString: string
  ) => {
    const elementQueryStringGenerated = Element.getQueryString(element as HTMLElement);
    $(elementQueryStringGenerated)
      .off("click.triggerAction")
      .on("click.triggerAction", async (event) => {
        for await (const connection of connections) {
          if (
            connection.conditionalAction &&
            !Value.evaluateInputAll(JSON.parse(connection.condition || "[]"), element)
          )
            return;
          if (connection.action === "request" && connection.requestId) {
            Render.disableParentWebflowForm(elementQueryString);
            await Request.perform(connection.requestId, undefined, element);
          } else if (connection.action === "navigate" && connection.url) {
            event.preventDefault();
            event.stopPropagation();
            location.href = Value.evaluateInputAll(JSON.parse(connection.url), element);
          } else if (
            connection.action === "setVariable" &&
            connection.variableId &&
            connection.value
          ) {
            store.commit("SET_DATA_TYPE_VALUE", {
              type: "variables",
              value: {
                id: connection.variableId,
                value: Value.evaluateInputAll(JSON.parse(connection.value), element),
              },
            });
          } else if (
            connection.action === "setCookie" &&
            connection.cookieId &&
            connection.value &&
            connection.cookieLifetime
          ) {
            let expirationTimeInDays = 1;
            if (connection.cookieLifetime === "1h") expirationTimeInDays = 1 / 24;
            else if (connection.cookieLifetime === "1d") expirationTimeInDays = 1;
            else if (connection.cookieLifetime === "1w") expirationTimeInDays = 7;
            else if (connection.cookieLifetime === "1m") expirationTimeInDays = 31;
            else if (connection.cookieLifetime === "3m") expirationTimeInDays = 31 * 3;
            Cookie.set(
              "w-c---" + connection.cookieId,
              Value.evaluateInputAll(JSON.parse(connection.value), element),
              expirationTimeInDays
            );
            store.commit("SET_DATA_TYPE_VALUE", {
              type: "cookies",
              value: {
                id: connection.cookieId,
                value: Value.evaluateInputAll(JSON.parse(connection.value), element),
              },
            });
          }
        }
      });
  },
  onInput: (connections: OnInputConnectionModel[], element: Element) => {
    const elementQueryStringGenerated = Element.getQueryString(element as HTMLElement);
    $(elementQueryStringGenerated)
      .off("input.triggerAction change.triggerAction")
      .on("input.triggerAction change.triggerAction", (event) => {
        for (const connection of connections) {
          if (!connection.lazy && event.type === "input") {
            // Delay to make sure variable get's saved via input handler (render.ts)
            setTimeout(() => Connection.onInputHelperFunction(connection, event, element), 5);
          } else if (connection.lazy && event.type === "change") {
            // Delay to make sure variable get's saved via input handler (render.ts)
            setTimeout(() => Connection.onInputHelperFunction(connection, event, element), 5);
          }
        }
      });
  },
  style: (connection: StyleConnectionModel, element: Element) => {
    if (connection.name && connection.value) {
      (element as HTMLElement).style[connection.name as any] = Value.evaluateInputAll(
        JSON.parse(connection.value),
        element
      );
    }
  },
  class: (connection: ClassConnectionModel, element: Element) => {
    if (store.state.user.loggedIn && !store.state.configuration.preview.isFullScreen) return;
    if (connection.method === "add" && connection.class) {
      (element as HTMLElement).classList.add(
        Value.evaluateInputAll(JSON.parse(connection.class), element)
      );
    } else if (connection.method === "remove" && connection.class) {
      (element as HTMLElement).classList.remove(
        Value.evaluateInputAll(JSON.parse(connection.class), element)
      );
    }
  },
  parameter: (connection: ParameterConnectionModel, element: Element) => {
    if (store.state.user.loggedIn && !store.state.configuration.preview.isFullScreen) return;
    if (connection.parameterId && connection.value) {
      const pages = store.state.configuration.pages;
      const parameters: { id: string; name: string }[] = [];
      pages.forEach((page: PageModel) => {
        if ((page as any).type !== "folder")
          page.parameters.forEach((param) => {
            parameters.push(param);
          });
      });
      const parameter = parameters.find((param) => param.id === connection.parameterId);
      const url = new URL((element as HTMLLinkElement).href);
      const params = url && new URLSearchParams(url.search);
      params.set(
        parameter?.name || "",
        Value.evaluateInputAll(JSON.parse(connection.value), element)
      );
      url.search = "?" + params.toString();
      (element as HTMLLinkElement).href = url.toString();
    }
  },
  list: (connection: ListConnectionModel, element: Element, elementQueryString: string) => {
    // Get list value
    const renderList: Array<any> = Value.evaluateInputAll(JSON.parse(connection.value), element);
    element.setAttribute("wized---list-index", "0");
    element.setAttribute("wized---list-connection-el", elementQueryString);
    element.setAttribute("wized---list-array-path", JSON.parse(connection.value)?.[0]?.path);
    element.setAttribute("wized---list-value", connection.value);
    let lastListElement = element;

    // If in configurator mode
    if (store.state.user.loggedIn && !store.state.configuration.preview.isFullScreen) {
      executeAllConnectionsInsideClone(element, element, elementQueryString);
      return;
    }

    // If in preview or display mode
    Array.isArray(renderList) &&
      renderList?.forEach((item, index) => {
        const clone = cloneAndInsertElement(element, index, lastListElement);
        lastListElement = clone;
        executeAllConnectionsInsideClone(clone, element, elementQueryString);
      });

    // Hide original element
    (element as HTMLElement).style.display = "none";
  },
  value: (connection: ValueConnectionModel, element: Element, isListRender: boolean) => {
    // Save connection value to data if data is not existing
    const dataValue = store.state.data.fields.find(
      (field: any) => field.queryString === Element.format(element as HTMLElement)?.queryString
    )?.value;
    if (
      element.tagName === "INPUT" ||
      element.tagName === "TEXTAREA" ||
      element.tagName === "SELECT"
    ) {
      if (dataValue && !isListRender) (element as HTMLInputElement).value = dataValue;
      else if (Value.evaluateInputAll(JSON.parse(connection.value), element))
        (element as HTMLInputElement).value = Value.evaluateInputAll(
          JSON.parse(connection.value),
          element
        );
      else (element as HTMLInputElement).value = "";
      if ((element as HTMLInputElement).value !== dataValue && !isListRender) {
        Render.inputOnChange({ target: element });
      }
    }
  },
  text: (connection: TextConnectionModel, element: Element) => {
    const value = Value.evaluateInputAll(JSON.parse(connection.value), element);
    if (connection.type === "text") element.textContent = value;
    else if (connection.type === "html") element.innerHTML = value;
    else if (connection.type === "markdown") {
      element.innerHTML = marked(value);
    }
  },
  attribute: (connection: AttributesConnectionModel, element: Element) => {
    if (connection.key && connection.value) {
      (element as HTMLElement).setAttribute(
        Value.evaluateInputAll(JSON.parse(connection.key), element),
        Value.evaluateInputAll(JSON.parse(connection.value), element)
      );
    }
  },
  visibility: (connection: VisibilityConnectionModel, element: Element) => {
    const display = window.getComputedStyle(element, null).display;
    if (Value.evaluateInputAll(JSON.parse(connection.condition), element)) {
      if (display === "none") (element as HTMLElement).style.display = "block";
      else (element as HTMLElement).style.display = display;
    } else {
      (element as HTMLElement).style.display = "none";
    }
  },
  onInputHelperFunction: (connection: OnInputConnectionModel, event: any, element: Element) => {
    if (connection.action === "request" && connection.requestId) {
      Request.perform(connection.requestId, undefined, element);
    } else if (connection.action === "setVariable" && connection.variableId && connection.value) {
      store.commit("SET_DATA_TYPE_VALUE", {
        type: "variables",
        value: {
          id: connection.variableId,
          value: Value.evaluateInputAll(JSON.parse(connection.value)),
        },
      });
    } else if (
      connection.action === "setCookie" &&
      connection.cookieId &&
      connection.value &&
      connection.cookieLifetime
    ) {
      let expirationTimeInDays = 1;
      if (connection.cookieLifetime === "1h") expirationTimeInDays = 1 / 24;
      else if (connection.cookieLifetime === "1d") expirationTimeInDays = 1;
      else if (connection.cookieLifetime === "1w") expirationTimeInDays = 7;
      else if (connection.cookieLifetime === "1m") expirationTimeInDays = 31;
      else if (connection.cookieLifetime === "3m") expirationTimeInDays = 31 * 3;
      Cookie.set("w-c---" + connection.cookieId, event?.target?.value, expirationTimeInDays);
      store.commit("SET_DATA_TYPE_VALUE", {
        type: "cookies",
        value: {
          id: connection.variableId,
          value: Value.evaluateInputAll(JSON.parse(connection.value)),
        },
      });
    }
  },
};

export default Connection;

const cloneAndInsertElement = (
  element: Element,
  index: number,
  lastListElement: Element
): Element => {
  const clone = Render.deepClone(Element.format(element as HTMLElement).queryString);
  clone.setAttribute("wized---list-index", index.toString());
  clone.setAttribute("wized---clone", "true");
  // Put element after last list element
  (element as HTMLElement).parentNode?.insertBefore(clone, lastListElement.nextSibling);
  return clone;
};

const executeAllConnectionsInsideClone = (
  cloneElement: Element,
  originalElement: Element,
  originalElementQueryString: string
): void => {
  // Get all element connection for this page
  const elementsWithConnections = Page.get()?.elements;
  // Return if no element connection has been found
  if (!elementsWithConnections || elementsWithConnections.length === 0) return;
  // Get all element connections that are inside of the clone or are the original (cloned) element
  const elementsWithConnectionsInsideOfOrTheClone: ElementModel[] = elementsWithConnections.filter(
    (elementWithConnections: ElementModel) => {
      // Get element connection element
      const domElement = document.querySelector(elementWithConnections.elementQueryString);
      // Return false if element connection element count not be found on page
      if (!domElement) return false;
      // Return true if element connection is cloned element
      if (elementWithConnections.elementQueryString === originalElementQueryString) return true;
      // Check if element connection element is inside originalElement
      return contains(originalElement, domElement);
    }
  );
  // Return if no elementsWithConnectionsInsideOfOrTheClone has been found
  if (
    !elementsWithConnectionsInsideOfOrTheClone ||
    elementsWithConnectionsInsideOfOrTheClone.length === 0
  )
    return;

  /**
   * Remove all element of elementsWithConnections that are inside of render list elements
   *
   * Why: To increase performance and decrease potential for bugs, they
   * should not be run on this render loop.
   * */
  const filteredElementsWithConnections: ElementModel[] = [
    ...elementsWithConnectionsInsideOfOrTheClone,
  ]; // tbd

  // Execute every 'first-level' connection inside clone
  filteredElementsWithConnections.forEach((elementWithConnections: ElementModel) => {
    // Remove the originialQueryPath from the queryString of elementWithConnection,
    // to make it relative to the clone element
    const elementWithConnectionsQueryStringRelativeToClone = elementQueryStringRelativeToClone(
      elementWithConnections.elementQueryString,
      originalElementQueryString
    );

    // Initialize tempConnection
    const tempConnection: ElementModel = {
      elementQueryString: elementWithConnectionsQueryStringRelativeToClone,
      connections: JSON.parse(JSON.stringify(elementWithConnections.connections)),
    };

    // If relative query string to clone is undefined or empty, it is the clone element itself,
    // else it is a children of the clone element
    if (
      !elementWithConnectionsQueryStringRelativeToClone ||
      elementWithConnectionsQueryStringRelativeToClone === " "
    ) {
      // If element is clone, get the clone query string
      const cloneQueryString = Element.format(cloneElement as HTMLElement).queryString;
      // Modify the to-be-applied element connection
      tempConnection.elementQueryString = cloneQueryString;
      tempConnection.connections.list.value = "";
    }

    // Get the domElement of the element connection
    const domElement =
      elementWithConnectionsQueryStringRelativeToClone &&
      cloneElement.querySelector(elementWithConnectionsQueryStringRelativeToClone);

    // Apply Element connection
    Render.applyElementConnection(
      tempConnection,
      originalElement?.getAttribute("wized---el-alter-id") || "",
      domElement ? (domElement as HTMLElement) : undefined
    );
  });
};

const elementQueryStringRelativeToClone = (
  queryString: string,
  originalElementQueryString: string
): string => {
  // Remove the originialQueryPath from the queryString of elementWithConnection,
  // to make it relative to the clone element
  queryString = queryString.slice(originalElementQueryString.length, queryString.length);
  let result = "";
  // Clean query path of > symbol at the beginning
  // Split up the query string by > character
  const splitQueryString = queryString.split(">");
  // Remove the first item of the array if it is empty
  if (splitQueryString[0] === "" || splitQueryString[0] === " ")
    (splitQueryString as Array<string>).shift();
  // Set result by rejoining query string parts
  result = splitQueryString.join(">");
  // Return result
  return result;
};

const contains = (parent: Element, child: Element): boolean => {
  return parent !== child && parent.contains(child);
};
