







import { Component, Vue, Watch } from "vue-property-decorator";
import Configuration from "@/helpers/configuration";
import PWBadge from "@/components/misc/PWBadge.vue";
import Upload from "@/components/misc/Upload.vue";
import Render from "@/helpers/render";

@Component({
  components: {
    PWBadge,
    Upload,
  },
})
export default class Display extends Vue {
  async mounted(): Promise<void> {
    const body = document.querySelector("body");
    body?.setAttribute("id", "pw---website");
    try {
      await Configuration.load();
      //console.log("Configuration loaded");
      //console.log(this.$store.state.configuration);
      Render.init();
    } catch (e) {
      console.log(e);
      //TODO: Handle error properly with custom handler.
    }
  }
}
