
































import { Component, Vue, Watch } from "vue-property-decorator";
import ResizeObserver from "resize-observer-polyfill";
import ElementToolkit from "@/helpers/element";

@Component({
  components: {},
})
export default class SelectedOverlay extends Vue {
  get hoveredElement(): HTMLElement | null {
    if (
      Object.keys(this.$store.state.element.hovered).length > 0 &&
      this.$store.state.element.hovered.queryString !==
        this.$store.state.element.selected.queryString
    ) {
      return document.querySelector(
        this.$store.state.element.hovered.queryString
      );
    } else {
      return null;
    }
  }

  get hoveredRect(): any {
    if (this.hoveredElement) return this.hoveredElement.getBoundingClientRect();
    else return {};
  }

  get hoveredElementDisplay(): { tagName: string; identifier: string } {
    if (Object.keys(this.$store.state.element.hovered).length > 0) {
      return ElementToolkit.formatForDisplay(this.$store.state.element.hovered);
    } else {
      return {
        tagName: "-",
        identifier: "",
      };
    }
  }
}
