






























import { Component, Vue, Watch } from "vue-property-decorator";
import { PageMenuModel, PageModel } from "@/models/Configuration";
import Button from "@/components/ui/Button.vue";

const Props = Vue.extend({
  props: {
    folder: {
      type: Object,
      required: true,
      validator: (value) => {
        if (value.type && value.path) return true;
        return false;
      },
    },
    pageMenu: {
      type: Array,
      required: true,
    },
  },
});

@Component({
  components: {
    Button,
  },
})
export default class Folder extends Props {
  get path(): string {
    return window.location.pathname;
  }

  toggleFolder(): void {
    this.$emit("toggle", this.folder);
  }
}
