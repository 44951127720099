


















































import { Component, Vue, Watch } from "vue-property-decorator";
import ElementNormal from "@/components/tab/pagetree/Element.vue";
import ElementToolkit from "@/helpers/element";
import ElementModel from "@/models/Element";
import { ElementModel as ElementConfigurationModel, PageModel } from "@/models/Configuration";
import Page from "@/helpers/page";
import Button from "@/components/ui/Button.vue";
import BlockHeading from "@/components/ui/BlockHeading.vue";
import ElementUnfound from "@/components/tab/pagetree/ElementUnfound.vue";
import Element from "@/helpers/element";
import { ElementModel as PageElementModel } from "@/models/Configuration";

@Component({
  components: {
    ElementNormal,
    Button,
    BlockHeading,
    ElementUnfound,
  },
})
export default class PageTree extends Vue {
  closedElements: Array<string> = [];

  closeParent(queryString: string) {
    this.closedElements.push(queryString);
  }

  openParent(queryString: string) {
    const index = this.closedElements.findIndex((element: string) => queryString === element);
    if (index !== -1) this.closedElements.splice(index, 1);
  }

  @Watch("selectedElement", { deep: true })
  checkIfItIsHidden(element: ElementModel): void {
    if (Object.keys(element).length === 0) return;
    element.parents.forEach((parent: string) => {
      this.closedElements.forEach((closedElement: string) => {
        if (closedElement === parent) this.openParent(parent);
      });
    });
  }

  hasSettings(element: ElementModel): boolean {
    const page = Page.get();
    const elements = (page as PageModel).elements;
    const elementInDOM = document.querySelector(element.queryString);
    return Boolean(
      Page.domElemementsConfig()?.find((element: any) => element.DOMElement === elementInDOM)
    );
  }

  get selectedElement() {
    return this.$store.state.element.selected;
  }

  get DOMElements(): Array<ElementModel> {
    const domElements: Array<ElementModel> = this.traverseDOM(
      document.getElementById("pw---website") as HTMLElement
    );
    const openDOMElements: Array<ElementModel> = [];
    domElements.forEach((domElement: ElementModel) => {
      let closed = false;
      domElement.parents.forEach((parent: string) => {
        this.closedElements.forEach((closedElement) => {
          if (closedElement === parent) closed = true;
        });
      });
      if (!closed) openDOMElements.push(domElement);
    });
    return openDOMElements;
  }

  get AllDOMElements(): Array<ElementModel> {
    return this.traverseDOM(document.getElementById("pw---website") as HTMLElement);
  }

  traverseDOM(element: HTMLElement, domElements?: Array<ElementModel>): Array<ElementModel> {
    const _domElements: Array<ElementModel> =
      domElements && domElements?.length > 0 ? domElements : [];
    if (element && element.tagName) {
      const formattedElement = ElementToolkit.format(element);
      _domElements.push(formattedElement);
      let childs = element.childNodes;
      if (element.tagName.toLowerCase() !== "svg")
        for (let i = 0; i < childs.length; i++) {
          this.traverseDOM(childs[i] as HTMLElement, _domElements);
        }
    }
    return _domElements;
  }

  deleteElement(element: ElementConfigurationModel) {
    this.$store.commit("DELETE_ELEMENT", element);
  }

  get unfoundDOMElements(): Array<ElementModel> {
    const page = Page.get();
    const elements = (page as PageModel).elements;
    const unfoundElements: Array<ElementModel> = [];
    elements?.forEach((element) => {
      const DOMElement = document.querySelector(element.elementQueryString);
      if (!DOMElement)
        unfoundElements.push({
          queryString: element.elementQueryString,
          tagName: "",
          parents: [],
          classes: "",
          children: [],
          id: element.elementQueryString,
        });
    });
    return unfoundElements;
  }
}
