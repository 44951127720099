var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%","display":"flex","flex-direction":"column","align-items":"stretch"}},[_c('BlockHeading',{attrs:{"description":"Select what data you want to save.","title":"Fields","iconName":"list"}}),(_vm.fieldsOfSelectedTable.length !== 0)?_c('div',{staticClass:"pw---10-wrapper pw---white-10 pw---top-bottom-pad-5"},[_c('div',{staticClass:"pw---option-row"},[_c('OptionTitle',{attrs:{"description":"If selected, we will automatically convert the data format of this requests data in order to fit Airtables requirements. This is option recommended.","text":"Automatic data format conversion (recommended)","isSet":Boolean(_vm.request.typecast === false)},on:{"reset":function () { return _vm.set(true, 'typecast'); }}}),_c('div',{staticClass:"pw---option-space"})],1),_c('div',{staticClass:"pw---option-row"},[_c('Toggle',{attrs:{"initialValue":_vm.request.typecast},on:{"change":function (value) { return _vm.set(value, 'typecast'); }}})],1),_c('div',{staticClass:"pw---seperator pw---option"}),_vm._l((_vm.fieldsOfSelectedTable),function(field,index){return _c('div',{key:index,staticStyle:{"width":"100%","display":"flex","flex-direction":"column","align-items":"flex-start"}},[_c('div',{staticClass:"pw---option-row",style:({ opacity: _vm.fieldIsDisabled(field) ? 0.3 : 1 })},[_c('OptionTitle',{attrs:{"description":'Select data you want to save to: ' + field.name + ' (Type: ' + field.type + ')',"text":field.name + (_vm.fieldIsDisabled(field) ? ' (' + field.type + ')' : ''),"isSet":Boolean(_vm.fieldValue(field.id))},on:{"reset":function () { return _vm.setArray('', field.id, 'fields'); }}}),_c('div',{staticClass:"pw---option-space"})],1),_c('div',{staticClass:"pw---option-row"},[_c('InputAll',{attrs:{"initialValue":_vm.fieldValue(field.id),"disabled":_vm.fieldIsDisabled(field),"width":200},on:{"change":function (value) {
              _vm.setArray(value, field.id, 'fields');
            }}})],1),_c('div',{staticClass:"pw---seperator pw---option"})])}),_c('div',{staticClass:"pw---option-row"},[_c('OptionTitle',{attrs:{"description":"Select this option if you are creating a new user. Selecting this option will check that the email of the user is unique.","text":"Is this a request for creating a new user?","isSet":_vm.request.isCreateUser},on:{"reset":function () { return _vm.set(false, 'isCreateUser'); }}}),_c('div',{staticClass:"pw---option-space"})],1),_c('div',{staticClass:"pw---option-row"},[_c('Toggle',{attrs:{"initialValue":_vm.request.isCreateUser},on:{"change":function (value) { return _vm.set(value, 'isCreateUser'); }}})],1),(_vm.request.isCreateUser && _vm.hasSetPassword)?_c('div',{staticClass:"pw---option-row"},[_c('OptionTitle',{attrs:{"description":"Select the password that should be saved for the user. Please note\n      that the password will not be stored in your Airtable database. Instead it will be hashed,\n      salted and securely stored in Wized's database.","text":"User password","isSet":Boolean(_vm.fieldValue('wized---password') && _vm.fieldValue('wized-password') !== '[]')},on:{"reset":function () { return _vm.setArray('', 'wized---password', 'fields'); }}}),_c('div',{staticClass:"pw---option-space"})],1):_vm._e(),(_vm.request.isCreateUser && _vm.hasSetPassword)?_c('div',{staticClass:"pw---option-row"},[_c('InputAll',{attrs:{"initialValue":_vm.fieldValue('wized---password'),"width":200},on:{"change":function (value) {
            _vm.setArray(value, 'wized---password', 'fields');
          }}})],1):_vm._e(),(_vm.request.isCreateUser)?_c('div',{staticClass:"pw---option-row"},[_c('OptionTitle',{attrs:{"description":"Select this option if you want to log in & (if this is a login via email & password account) and redirect the user\n      to another page after a successful creation of the user.","text":"Redirect to other page after\n      creating user?","isSet":_vm.request.redirectAfterCreateAccount},on:{"reset":function () {
            _vm.set(false, 'redirectAfterCreateAccount');
            _vm.set('', 'redirectPageIdAfterCreateAccount');
          }}})],1):_vm._e(),(_vm.request.isCreateUser)?_c('div',{staticClass:"pw---option-row"},[_c('Toggle',{attrs:{"initialValue":_vm.request.redirectAfterCreateAccount},on:{"change":function (value) { return _vm.set(value, 'redirectAfterCreateAccount'); }}}),_c('div',{staticClass:"pw---option-space"}),(_vm.request.redirectAfterCreateAccount)?_c('InputSelect',{attrs:{"selectValueGroups":_vm.pages,"initialValueId":_vm.request.redirectPageIdAfterCreateAccount,"width":100},on:{"change":function (value) { return _vm.set(value, 'redirectPageIdAfterCreateAccount'); }}}):_vm._e()],1):_vm._e()],2):_vm._e(),(_vm.fieldsOfSelectedTable.length === 0)?_c('div',{staticClass:"pw---10-wrapper pw---white-10 pw---top-bottom-pad-5"},[_c('div',{staticClass:"pw---option-row"},[_c('OptionTitle',{attrs:{"description":"Error: There a no fields to save data to. Make sure you have selected a base AND a table where you want to save data to.","text":"Please select base AND table to edit fields."}}),_c('div',{staticClass:"pw---option-space"})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }