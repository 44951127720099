var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pw---popup-wrapper pw---right"},[_c('div',{staticClass:"pw---data-selector",staticStyle:{"height":"auto"}},[_c('PopupHeading',{attrs:{"title":"Publish"}}),_vm._m(0),(_vm.$store.state.project.domain.name)?_c('div',{staticClass:"pw---seperator"}):_vm._e(),(_vm.$store.state.project.domain.name)?_c('div',{staticClass:"pw---10-wrapper pw---white-10 pw---top-bottom-pad-5",style:({
        cursor: 'pointer',
        backgroundColor: this.mainDestination ? '#ffffff40' : '#ffffff20',
      }),on:{"click":function($event){return _vm.selectDestination('main')}}},[_c('div',{staticClass:"pw---option-row"},[_c('div',{style:({
            marginRight: '12px',
            height: '12px',
            width: '12xpx',
            borderRadius: '2px',
            border: '1px solid #ffffff20',
            backgroundColor: this.mainDestination ? '#0062ff' : '#ffffff50',
            minWidth: '12px',
          })},[_c('img',{style:({
              height: '9px',
              width: '9px',
              opacity: this.mainDestination ? 1 : 0,
              marginTop: '-15px',
              marginLeft: '0.5px',
            }),attrs:{"src":_vm.$config.configuratorUrl + 'images/checkmark.svg'}})]),_c('div',[_c('div',{staticClass:"pw---p-small",staticStyle:{"margin-top":"0px","opacity":"0.5"}},[_vm._v("Main domain:")]),_c('div',{staticClass:"pw---p-small",staticStyle:{"margin-top":"0px"}},[_vm._v(" "+_vm._s(_vm.$store.state.project.domain.name)+" ")])])])]):_vm._e(),(_vm.$store.state.project.domain.nameStaging)?_c('div',{staticClass:"pw---seperator"}):_vm._e(),(_vm.$store.state.project.domain.nameStaging)?_c('div',{staticClass:"pw---10-wrapper pw---white-10 pw---top-bottom-pad-5",style:({
        cursor: 'pointer',
        backgroundColor: this.stagingDestination ? '#ffffff40' : '#ffffff20',
      }),on:{"click":function($event){return _vm.selectDestination('staging')}}},[_c('div',{staticClass:"pw---option-row"},[_c('div',{style:({
            marginRight: '12px',
            height: '12px',
            width: '12xpx',
            borderRadius: '2px',
            border: '1px solid #ffffff20',
            backgroundColor: this.stagingDestination ? '#0062ff' : '#ffffff50',
            minWidth: '12px',
          })},[_c('img',{style:({
              height: '9px',
              width: '9px',
              opacity: this.stagingDestination ? 1 : 0,
              marginTop: '-15px',
              marginLeft: '0.5px',
            }),attrs:{"src":_vm.$config.configuratorUrl + 'images/checkmark.svg'}})]),_c('div',[_c('div',{staticClass:"pw---p-small",staticStyle:{"margin-top":"0px","opacity":"0.5"}},[_vm._v("Staging domain:")]),_c('div',{staticClass:"pw---p-small",staticStyle:{"margin-top":"5px"}},[_vm._v(" "+_vm._s(_vm.$store.state.project.domain.nameStaging)+" ")])])])]):_vm._e(),_c('div',{staticClass:"pw---seperator"}),_c('div',{staticClass:"pw---10-wrapper pw---white-10 pw---top-bottom-pad-5"},[_c('div',{staticClass:"pw---option-row",staticStyle:{"flex-direction":"column","align-items":"start"}},[_c('div',{staticClass:"pw---p-small pw---60"},[_vm._v("Last published:")]),_c('div',{staticClass:"pw---p-small",staticStyle:{"margin-top":"5px"}},[_vm._v(_vm._s(_vm.lastPublished))])]),(_vm.loading)?_c('LoadingSpinner',{attrs:{"height":40}}):_vm._e()],1),_c('div',{staticClass:"pw---seperator"}),_c('div',{staticClass:"pw---10-wrapper pw---white-10 pw---top-bottom-pad-5 pw---cancel-save"},[_c('div',{staticClass:"pw---option-row"},[_c('div',{staticClass:"pw---option-space"}),_c('Button',{staticStyle:{"margin-right":"10px"},attrs:{"styles":"super-light","text":"Close"},on:{"click":_vm.close}}),(!_vm.published)?_c('Button',{staticStyle:{"width":"90px"},style:({ opacity: !_vm.loading && (_vm.stagingDestination || _vm.mainDestination) ? '1' : 0.3 }),attrs:{"styles":"light-blue","iconName":"publish","text":_vm.published ? 'Log out & open' : 'Publish',"isActive":_vm.stagingDestination || _vm.mainDestination},on:{"click":function($event){!_vm.loading && _vm.publish()}}}):_vm._e(),(_vm.published)?_c('Button',{staticStyle:{"min-width":"110px"},attrs:{"styles":"light","iconName":"preview-filled","text":"Log out & view"},on:{"click":_vm.logOut}}):_vm._e()],1)])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pw---10-wrapper pw---white-10 pw---top-bottom-pad-5"},[_c('div',{staticClass:"pw---option-row",staticStyle:{"flex-direction":"column","align-items":"start"}},[_c('div',{staticClass:"pw---p-small pw---60"},[_vm._v("Choose publish destination:")])])])}]

export { render, staticRenderFns }