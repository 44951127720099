




































import { Component, Vue, Watch } from "vue-property-decorator";
import ResizeObserver from "resize-observer-polyfill";
import LeftNavBar from "@/components/nav/LeftNavBar.vue";
import TopBarPageSelector from "@/components/nav/top/TopBarPageSelector.vue";
import TopBarCenter from "@/components/nav/top/TopBarCenter.vue";
import Requests from "@/components/tab/Requests.vue";
import Data from "@/components/tab/Data.vue";
import PageTree from "@/components/tab/PageTree.vue";
import Auth from "@/components/tab/Auth.vue";
import Pages from "@/components/tab/Pages.vue";
import Settings from "@/components/tab/Settings.vue";
import Element from "@/components/tab/Element.vue";
import BrowserToSmall from "@/components/misc/BrowserToSmall.vue";
import LoadingFullScreen from "@/components/misc/LoadingFullScreen.vue";
import AuthenticationInvalid from "@/components/misc/AuthenticationInvalid.vue";
import Authentication from "@/helpers/authentication";
import Configuration from "@/helpers/configuration";
import Token from "@/helpers/token";
import Website from "@/components/website/Website.vue";
import SelectorOverlay from "@/components/website/SelectedOverlay.vue";
import HoverOverlay from "@/components/website/HoverOverlay.vue";
import Popups from "@/components/popup/Popups.vue";
import PreviewBar from "@/components/nav/top/PreviewBar.vue";
import Page from "@/helpers/page";
import PWBadge from "@/components/misc/PWBadge.vue";
import Upload from "@/components/misc/Upload.vue";
import { PageModel } from "@/models/Configuration";
import Id from "@/helpers/id";
import { reportError } from "@/helpers/error-handler";

@Component({
  components: {
    LeftNavBar,
    TopBarPageSelector,
    TopBarCenter,
    Requests,
    Data,
    PageTree,
    Auth,
    Pages,
    Settings,
    Element,
    BrowserToSmall,
    LoadingFullScreen,
    AuthenticationInvalid,
    Website,
    SelectorOverlay,
    HoverOverlay,
    Popups,
    PreviewBar,
    PWBadge,
    Upload,
  },
})
export default class Configurator extends Vue {
  loading = true;

  mounted(): void {
    this.adjustLeftBarHeight();
    this.resizeObserver();
    this.load();
    this.changeFavicon();
    (document.querySelector("body") as any).style.overflow = "hidden";
    this.checkForDuplicateIds();
  }

  unmounted(): void {
    (document.querySelector("body") as any).style.overflow = "scroll";
  }

  adjustLeftBarHeight(): void {
    const elements = document.querySelectorAll<HTMLElement>(".pw---left-bar");
    if (elements[0]) elements[0].style.height = document.body.clientHeight - 35 + "px";
  }

  get isFullScreenPreview(): boolean {
    return this.$store.state.configuration.preview.isFullScreen;
  }

  @Watch("isFullScreenPreview")
  onPreviewChanged(value: boolean): void {
    if (!value)
      setTimeout(() => {
        this.adjustLeftBarHeight();
      }, 50);
  }

  resizeObserver(): void {
    const resizeObserver = new ResizeObserver((entries: any) => {
      this.adjustLeftBarHeight();
    });
    resizeObserver.observe(document.body);
  }

  async load(): Promise<void> {
    this.loading = true;
    try {
      await Configuration.loadAdmin();
      const token = await Token.getAdminToken();
      if (token) await Authentication.login(token);
      if (
        this.$store.state.configuration.resources.length > 0 &&
        Array.isArray(Page.get()?.requests) &&
        (Page.get()?.requests.length as number) > 0
      ) {
        this.$store.commit("SET_TAB", "data");
      }
      Page.checkAndUpdatePageList();
    } catch (e) {
      reportError("16000", "Error while loading configuration", e);
    }
    this.loading = false;
  }

  changeFavicon(): void {
    var link = document.querySelector("link[type~='image/x-icon']");
    if (!link) {
      link = document.createElement("link");
      link.setAttribute("type", "image/x-icon");
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    // @ts-ignore: this.$config not found in Component
    (link as HTMLLinkElement).href = this.$config.configuratorUrl + "/images/favicon-32-grey.png";
  }

  checkForDuplicateIds(): void {
    const nodes = document.querySelector("#pw---website")?.querySelectorAll("[id]");
    if (!nodes) return;
    let ids = {};
    const totalNodes = nodes.length;

    for (let i = 0; i < totalNodes; i++) {
      var currentId = nodes[i].id ? nodes[i].id : "undefined";
      if (isNaN((ids as any)[currentId])) {
        (ids as any)[currentId] = 0;
      }
      (ids as any)[currentId]++;
      if ((ids as any)[currentId] > 1)
        reportError(
          "18000",
          (ids as any)[currentId] +
            " instances of elements with the id: '" +
            currentId +
            "' found.",
          "Please go to Webflow and remove or rename the duplicates. Otherwise this might lead to errors with Wized."
        );
    }
    //console.log(ids);
  }
}
