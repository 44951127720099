import store from "@/store";
import Request from "./request";
import Page from "./page";
import Id from "./id";
import { CookieModel, ElementModel, PageModel } from "@/models/Configuration";
import Connection from "./render/connection";
import AlteredElementModel from "@/models/Render";
import $, { data } from "jquery";
import Cookie from "./cookie";
import Variables from "./variables";
import Parameter from "./parameter";
import Authentication from "./authentication";
import Value from "./value";
import Element from "./element";
import { reportError } from "./error-handler";
import API from "@/helpers/api";

//let counter = 0;

const Render = {
  init: async (): Promise<void> => {
    collectDataValues();
    applyBaseStateToAllRequestData();
    Request.performInitialRequests();
    applyLoginSettings();
    Render.applyPageConfiguration();
    verifyLoginOrAuthTokenIfPresent();
  },
  applyPageConfiguration: async (): Promise<void> => {
    applyPageAccessRules();
    resetPageToUnalteredState();
    collectInputValues();
    applyAllElementsWithConnections();
  },
  deepClone: (queryString: string): Element => {
    return $(queryString).clone(true, true).get(0);
  },
  applyElementConnection: (
    elementConnection: ElementModel,
    elementAlterId: string,
    element?: HTMLElement
  ) => {
    // Get element
    const htmlElement = element
      ? element
      : document.querySelector(elementConnection.elementQueryString);
    htmlElement?.setAttribute("wized---el-alter-id", elementAlterId);
    if (!htmlElement) return;

    // Apply connections to element
    if (
      elementConnection.connections.list.value &&
      elementConnection.connections.list.value !== "[]"
    ) {
      Connection.list(
        elementConnection.connections.list,
        htmlElement,
        elementConnection.elementQueryString
      );
      // Stop applying other connection, because those will be applied for every list element later
      return;
    }
    if (elementConnection.connections.onClick.length > 0) {
      Connection.onClick(
        elementConnection.connections.onClick,
        htmlElement,
        elementConnection.elementQueryString
      );
    }
    if (elementConnection.connections.onInput.length > 0) {
      Connection.onInput(elementConnection.connections.onInput, htmlElement);
    }
    if (elementConnection.connections.style.length > 0) {
      elementConnection.connections.style.forEach((styleConnection) => {
        Connection.style(styleConnection, htmlElement);
      });
    }
    if (elementConnection.connections.class.length > 0) {
      elementConnection.connections.class.forEach((classConnection) => {
        Connection.class(classConnection, htmlElement);
      });
    }
    if (
      elementConnection.connections.parameters &&
      elementConnection.connections.parameters.length > 0
    ) {
      elementConnection.connections.parameters.forEach((parameterConnection) => {
        Connection.parameter(parameterConnection, htmlElement);
      });
    }
    if (
      elementConnection.connections.value.value &&
      elementConnection.connections.value.value !== "[]"
    ) {
      Connection.value(elementConnection.connections.value, htmlElement, Boolean(element));
    }
    if (
      elementConnection.connections.text.value &&
      elementConnection.connections.text.value !== "[]"
    ) {
      Connection.text(elementConnection.connections.text, htmlElement);
    }
    if (elementConnection.connections.attributes.length > 0) {
      elementConnection.connections.attributes.forEach((attributeConnection) => {
        Connection.attribute(attributeConnection, htmlElement);
      });
    }
    if (
      elementConnection.connections.visibility.condition &&
      elementConnection.connections.visibility.condition !== "[]"
    ) {
      Connection.visibility(elementConnection.connections.visibility, htmlElement);
    }
  },
  disableParentWebflowForm: (element: string) => {
    //Prevent default webflow form action
    const parentForm = $(element).closest("form");
    // @ts-ignore: Unreachable code error
    if (parentForm && parentForm.length > 0 && Webflow) {
      // @ts-ignore: Unreachable code error
      Webflow.push(function () {
        $(parentForm).submit(() => {
          return false;
        });
      });
    }
  },
  checkPressInputEnter: (event: any) => {
    event = event || event;
    const txtArea = /textarea/i.test(((event.target as any) || event.srcElement).tagName);
    return txtArea || (event.keyCode || event.which || event.charCode || 0) !== 13;
  },
  inputOnChange: (event: any) => {
    // Normal query string
    let queryString = Element.format(event.target).queryString;

    // Query string if radio
    if (event.target.type === "radio")
      queryString = 'input[type=radio][name="' + (event.target as HTMLInputElement).name + '"]';
    let value;

    // Get value
    if (event.target.type && event.target.type === "checkbox") {
      value = event.target.checked;
    } else {
      value = event?.target.value;
    }

    // Save value to store
    store.commit("SET_DATA_TYPE_VALUE", {
      type: "fields",
      value: { queryString: queryString, value: value },
    });
  },
};

export default Render;

const collectDataValues = () => {
  const configureCookies: CookieModel[] = store.state.configuration.cookies;

  configureCookies.forEach((cookie) => {
    const storedCookie = Cookie.get("w-c---" + cookie.id);
    if (storedCookie)
      store.commit("SET_DATA_TYPE_VALUE", {
        type: "cookies",
        value: { id: cookie.id, value: storedCookie },
      });
  });

  // Collect initial field values
  const inputFields = Variables.getAllInputFields();
  inputFields.forEach((field) => {
    if (field.type === "radio") {
      const radioName = (document.querySelector(field.queryString) as HTMLInputElement)?.name;
      store.commit("SET_DATA_TYPE_VALUE", {
        type: "fields",
        value: {
          queryString: field.queryString,
          value: document.querySelector('input[name="' + radioName + '"]:checked'),
        },
      });
    } else if (field.type === "checkbox") {
      store.commit("SET_DATA_TYPE_VALUE", {
        type: "fields",
        value: {
          queryString: field.queryString,
          value: (document.querySelector(field.queryString) as HTMLInputElement)?.checked,
        },
      });
    } else {
      store.commit("SET_DATA_TYPE_VALUE", {
        type: "fields",
        value: {
          queryString: field.queryString,
          value: (document.querySelector(field.queryString) as HTMLInputElement)?.value,
        },
      });
    }
  });
  collectInputValues();

  const configuredParams = Page.get()?.parameters;
  configuredParams?.forEach((param) => {
    const paramValue = Parameter.get(param.name);
    if (paramValue)
      store.commit("SET_DATA_TYPE_VALUE", {
        type: "params",
        value: { id: param.id, value: paramValue },
      });
    else {
      reportError(
        "17000",
        "Warning: Value for url parameter: '" + param.name + "' missing",
        "Page might not work correctly. Open the Preview settings and set a value for '" +
          param.name +
          "' to resolve this issue."
      );
    }
  });

  addInputEditHandlers();
};

const collectInputValues = () => {
  //Get data from input fields
  const inputFields = Variables.getAllInputFields();
  inputFields.forEach((field) => {
    if (field.type !== "radio") {
      const fieldElement = document.querySelector(field.queryString);
      const parentForm = fieldElement?.closest("form");
      if (parentForm) parentForm.onkeypress = Render.checkPressInputEnter;
      if (!fieldElement) return;
    }
  });
  addInputEditHandlers();
};

const addInputEditHandlers = () => {
  const inputFields = Variables.getAllInputFields();

  inputFields.forEach((field) => {
    if (field.type === "radio") {
      const radios = $(field.queryString);
      radios.each(function () {
        $(this)
          .off("change.getValue")
          .on("change.getValue", (event) => {
            // Needs to be async to make sure other triggers are also fired and not overwritten
            setTimeout(() => {
              Render.inputOnChange(event);
            }, 0);
          });
      });
    } else {
      if (
        field.type !== "select-one" &&
        field.type !== "select-multiple" &&
        field.type !== "email" &&
        field.type !== "textarea" &&
        field.type !== "password" &&
        field.type !== "number" &&
        field.type !== "text" &&
        field.type !== "tel"
      ) {
        $(field.queryString)
          .off("change.getValue")
          .on("change.getValue", (event) => {
            // Needs to be async to make sure other triggers are also fired and not overwritten
            setTimeout(() => Render.inputOnChange(event), 0);
          });
      }
      if (field.type !== "checkbox") {
        $(field.queryString)
          .off("input.getValue")
          .on("input.getValue", (event) => {
            // Needs to be async to make sure other triggers are also fired and not overwritten
            setTimeout(() => Render.inputOnChange(event), 0);
          });
      }
    }
  });
};

const resetPageToUnalteredState = async () => {
  const clonedElements = document.querySelectorAll("[wized---clone]");

  const resetElementValues: Array<{ value: any; elementQuery: string }> = [];

  clonedElements.forEach((element) => {
    // Save value of specific elements that would else get overriden by rerender
    if (element.tagName === "OPTION") {
      // Check if parent alread in resetElementValues listNode
      const parent = element.parentElement;
      if (parent && parent.tagName === "SELECT") {
        const queryString = Element.getQueryString(parent);
        if (resetElementValues.findIndex((r) => r.elementQuery === queryString) === -1) {
          resetElementValues.push({
            value: (parent as HTMLSelectElement).value,
            elementQuery: queryString,
          });
        }
      }
    }

    element.remove();
  });

  store.state.alteredElements.forEach((alteredElement: AlteredElementModel) => {
    const element = document.querySelector(alteredElement.queryString);
    if (!element) return;

    if (
      (!(element as HTMLInputElement)?.value && !(alteredElement as any).element.value) ||
      element.tagName === "OPTION"
    ) {
      element.replaceWith(alteredElement.element);
    }
  });

  // Restore value of rerendered elements with delay of 100ms
  setTimeout(() => {
    resetElementValues.forEach((r) => {
      const element = document.querySelector(r.elementQuery);
      if (!element || element.tagName !== "SELECT") return;
      (element as HTMLSelectElement).value = r.value;
    });
  }, 2);

  store.commit("RESET_ALTERED_ELEMENTS");
};

const applyAllElementsWithConnections = () => {
  // Get all element connection for this page
  const elementsWithConnections = Page.get()?.elements;
  // Return if no element connection have been found
  if (!elementsWithConnections || elementsWithConnections.length === 0) return;

  /**
   * Remove all element of elementsWithConnections that are inside of render list elements
   *
   * Why: They should not be run on the first render loop,
   * to increase performance and potential for bugs)
   * */
  const filteredElementsWithConnections = elementConnectionsWithoutInsideRenderList(
    elementsWithConnections
  );

  // Execute the settings of the 'first-level' elementsWithConnections
  filteredElementsWithConnections.forEach((elementWithConnections: ElementModel) => {
    const domElement = document.querySelector(elementWithConnections.elementQueryString || "");
    // Return if the element could not be found on page
    if (!domElement) return;

    // Clone element and save it in store for page reset
    const clone = Render.deepClone(elementWithConnections.elementQueryString);
    const elementAlterId = Id.create(store.state.alteredElements);
    store.commit("SET_ALTERED_ELEMENT", {
      id: elementAlterId,
      element: clone,
      queryString: elementWithConnections.elementQueryString,
    });

    // Apply element connection
    Render.applyElementConnection(elementWithConnections, elementAlterId);
  });
};

const elementConnectionsWithoutInsideRenderList = (
  elementsWithConnections: ElementModel[]
): ElementModel[] => {
  const result: ElementModel[] = [];
  // Get all element connections with render list setting
  const elementConnectionsWithRenderListSetting = Page.get()?.elements.filter(
    (element: ElementModel) => {
      return element.connections.list.value && element.connections.list.value !== "[]";
    }
  );
  // Return all elementsWithConnections if no render list settings could be found
  if (
    !elementConnectionsWithRenderListSetting ||
    elementConnectionsWithRenderListSetting.length === 0
  )
    return elementsWithConnections;

  // Check if elementConnection is inside elementConnectionWithRenderListSetting
  // Loop through all elements with connection and determine which ones are inside render list
  const insideRenderListIndexes: number[] = [];
  elementsWithConnections.forEach((elementWithConnections: ElementModel, index: number) => {
    const elementRaw = document.querySelector(elementWithConnections.elementQueryString);
    // Return if to-be-checked element can not be found on page
    if (!elementRaw) return;
    // Check if elementwithConnections is inside on of the element with render list
    elementConnectionsWithRenderListSetting.forEach(
      (elementConnectionWithRenderListSetting: ElementModel) => {
        const renderListElementRaw = document.querySelector(
          elementConnectionWithRenderListSetting.elementQueryString
        );
        // Return if render-list element can not be found on page
        if (!renderListElementRaw) return;
        // Return if render-list element is the same as element-with-connection
        if (
          elementWithConnections.elementQueryString ===
          elementConnectionWithRenderListSetting.elementQueryString
        )
          return;
        if (contains(renderListElementRaw, elementRaw)) {
          // Only add element to the insideRenderListIndex if it is not already in the list
          if (!insideRenderListIndexes.includes(index)) insideRenderListIndexes.push(index);
        }
      }
    );
  });

  // Add elementsWithConnections to result array if its index is not on the list of elements inside render lists
  elementsWithConnections.forEach((elementWithConnection: ElementModel, index: number) => {
    if (!insideRenderListIndexes.includes(index)) result.push(elementWithConnection);
  });

  return result;
};

const applyLoginSettings = () => {
  // Apply login for email and password
  if (
    Page.get()?.settings.login.onThisPage &&
    Page.get()?.settings.login.emailPasswordLoginEnabled &&
    Page.get()?.settings.login.emailPasswordEmailQuery &&
    Page.get()?.settings.login.emailPasswordPasswordQuery &&
    Page.get()?.settings.login.emailPasswordButtonQuery
  ) {
    Render.disableParentWebflowForm(Page.get()?.settings.login.emailPasswordButtonQuery || "");
    $(Page.get()?.settings.login.emailPasswordButtonQuery || "").on("click", (event) => {
      Authentication.loginUser("emailPassword");
    });
  }

  // Apply login for magic link
  if (
    Page.get()?.settings.login.onThisPage &&
    Page.get()?.settings.login.magicLinkEnabled &&
    Page.get()?.settings.login.magicLinkEmailQuery &&
    Page.get()?.settings.login.magicLinkButtonQuery
  ) {
    Render.disableParentWebflowForm(Page.get()?.settings.login.magicLinkButtonQuery || "");
    $(Page.get()?.settings.login.magicLinkButtonQuery || "").on("click", (event) => {
      Authentication.loginUser("magicLink");
    });
  }

  // Apply request password-reset-link
  if (
    Page.get()?.settings.login.emailPasswordRequestReset &&
    Page.get()?.settings.login.emailPasswordRequestResetEmailQuery &&
    Page.get()?.settings.login.emailPasswordRequestResetButtonQuery &&
    Page.get()?.settings.login.resetPasswordPageId
  ) {
    Render.disableParentWebflowForm(
      Page.get()?.settings.login.emailPasswordRequestResetButtonQuery || ""
    );
    $(Page.get()?.settings.login.emailPasswordRequestResetButtonQuery || "").on(
      "click",
      (event) => {
        Authentication.requestPasswordReset();
      }
    );
  }

  // Apply reset password
  if (
    Page.get()?.settings.login.emailPasswordReset &&
    Page.get()?.settings.login.emailPasswordResetPasswordQuery &&
    Page.get()?.settings.login.emailPasswordResetPasswordValidationQuery &&
    Page.get()?.settings.login.emailPasswordResetButtonQuery
  ) {
    Render.disableParentWebflowForm(Page.get()?.settings.login.emailPasswordResetButtonQuery || "");
    $(Page.get()?.settings.login.emailPasswordResetButtonQuery || "").on("click", (event) => {
      Authentication.resetPassword();
    });
  }

  // Apply logout button

  if (Page.get()?.settings.login.logoutButton) {
    $(Page.get()?.settings.login.logoutButton || "").on("click", (event) => {
      Authentication.logout();
    });
  }
};

const verifyLoginOrAuthTokenIfPresent = async () => {
  const loginToken = Parameter.get("login-token");
  const authToken = Cookie.get("w---at");
  if (loginToken) {
    await Authentication.verifyLoginToken(loginToken);
  } else if (authToken && !store.state.user.loggedIn) {
    Authentication.loadUser(authToken);
  }
};

const applyBaseStateToAllRequestData = () => {
  Page.get()?.requests.forEach((request) => {
    store.commit("SET_DATA_TYPE_VALUE", {
      value: {
        id: request.id,
        value: {
          isRequesting: false,
          hasRequested: false,
          statusCode: 0,
          errorMessage: "",
          data: {},
        },
      },
      type: "requests",
    });
  });
};

const applyPageAccessRules = () => {
  if (store.state.user.loggedIn) return;
  // Apply page access rules
  const accessControl = Page.get()?.settings.accessControl;
  if (!accessControl || !accessControl.accessToThisPageRestricted) return;

  // Authentication required
  if (
    accessControl.authRequired &&
    (!Cookie.get("w---at") ||
      (!store.state.data.user.isRequesting && Object.keys(store.state.data.user).length === 0))
  ) {
    const fallBackPage: PageModel | undefined = accessControl.authFallbackPageId
      ? store.state.configuration.pages.find(
          (page: PageModel) => page.id === accessControl.authFallbackPageId
        )
      : accessControl.fallbackPageId &&
        store.state.configuration.pages.find(
          (page: PageModel) => page.id === accessControl.fallbackPageId
        );
    if (fallBackPage) location.href = fallBackPage.path;
    else alert(API.alertText.permissionDeniedLogin);
  }

  // Custom access Rules
  if (accessControl.allowIf && typeof accessControl.allowIf === "string") {
    // Old version of access Rules
    const fallBackPage: PageModel | undefined =
      accessControl.fallbackPageId &&
      store.state.configuration.pages.find(
        (page: PageModel) => page.id === accessControl.fallbackPageId
      );
    if (
      accessControl.customAccessRule &&
      !Value.evaluateInputAll(JSON.parse(accessControl.allowIf || ""))
    ) {
      if (fallBackPage) location.href = fallBackPage.path;
      else alert(API.alertText.permissionDenied);
    }
  } else if (Array.isArray(accessControl.allowIf) && accessControl.customAccessRule) {
    // New version of access Rules with support for multiple rules and a fallback page for each rule
    accessControl.allowIf.forEach((accessRule: { rule: string; fallbackPageId: string }) => {
      const fallBackPage: PageModel | undefined =
        accessRule.fallbackPageId &&
        store.state.configuration.pages.find(
          (page: PageModel) => page.id === accessRule.fallbackPageId
        );
      if (accessRule.rule && !Value.evaluateInputAll(JSON.parse(accessRule.rule || ""))) {
        if (fallBackPage) {
          // Reload if fallbackpage = current page
          if (fallBackPage.path === location.pathname) {
            location.reload();
          }
          location.href = fallBackPage.path;
        } else alert(API.alertText.permissionDenied);
      }
    });
  }
};

const contains = (parent: Element, child: Element) => {
  return parent !== child && parent.contains(child);
};
